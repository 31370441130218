import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';

// Components
import {
  Table,
  Picto,
  utils,
  Tag,
  Button,
  Message,
  UnlockerLoader,
} from 'ui-library-unlocker';
import Tooltip from '../../../components/atoms/Tooltip/Tooltip';
import TableOptions from '../../../components/atoms/TableOptions/TableOptions';
import ConfirmationModal from '../../../components/organisms/ConfirmationModal/ConfirmationModal';

// Services
import { deleteInventory, createInventory, updateInventory } from '../../../services/inventory';

// Hooks
import useRoles from '../../../hooks/useRoles';
import { useAppContext } from '../../../store/context';

// Utils
import { handleDocumentDownload } from '../../../utils/documents';
import { showModal, hideModal } from '../../../utils/modal';
import { formatAddress } from '../../../utils/properties';
import { getInventoryStatusVariant } from '../../../utils/variants';

// Styles
import styles from './LeaseInventories.module.scss';

// Constants
import { LEASE_STATUS, INVENTORY_STATUS_LIST } from '../../../utils/constants';

const DELETE_CONFIRM_MODAL_ID = 'inventory-delete-confirm';

function LeaseInventories({
  inventoriesList = [],
  refetchList = () => {},
  isFetching = false,
  lease = null,
  userHasManagementRights = false,
}) {
  const { t } = useTranslation();
  const { isUserTenant, isUserAdmin } = useRoles();
  const navigate = useNavigate();
  const { context: { user } } = useAppContext();

  const [tooltipOpen, setTooltipOpen] = useState(null);
  const [docSelected, setDocSelected] = useState(null);

  const listData = useMemo(() => {
    if (!inventoriesList?.length) return [];
    return inventoriesList.filter((inventory) => {
      if (isUserAdmin) return true;
      if (user?.username === inventory.owner) return true;
      return inventory.status !== INVENTORY_STATUS_LIST.DRAFT;
    });
  }, [inventoriesList, isUserAdmin]);

  const inventoryDeleteMutation = useMutation({
    mutationFn: () => deleteInventory(docSelected),
    onSuccess: () => {
      refetchList();
      hideModal(DELETE_CONFIRM_MODAL_ID);
      utils.toast.success(t('inventory.crud.deleteSuccess'));
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 400: {
            utils.toast.error(t('global.form.errors.generic'));
            break;
          }
          default: {
            utils.toast.error(t('global.form.errors.global'));
            break;
          }
        }
      }
    },
  });

  const inventoryUpdateMutation = useMutation({
    mutationFn: (data) => updateInventory(data?.uid, data),
    onSuccess: (_, variables) => {
      navigate(`/inventory/${variables?.uid}`);
    },
    onError: () => {
      utils.toast.error(t('global.form.errors.global'));
    },
  });

  const inventoryCreateMutation = useMutation({
    mutationFn: (data) => createInventory(lease?.propertyUid, data),
    onSuccess: ({ data }) => {
      const newSigners = [
        {
          uid: lease?.manager?.uid,
          firstName: lease?.manager?.firstName,
          lastName: lease?.manager?.lastName,
          wasPresent: true,
          role: 'manager',
          signed: false,
        },
      ];
      lease?.tenantDetails?.forEach((tenant) => newSigners.push({
        uid: tenant.uid,
        firstName: tenant.firstName,
        lastName: tenant.lastName,
        wasPresent: false,
        role: 'tenant',
        signed: false,
      }));
      inventoryUpdateMutation.mutate({
        uid: data?.uid,
        isDraft: true,
        date: new Date(),
        propertyUid: lease?.propertyUid,
        property: {
          name: lease?.propertyName,
          address: lease?.propertyAddress,
        },
        leaseUid: lease?.uid,
        leaseName: lease?.name,
        electricalMeter: null,
        gasMeter: null,
        waterMeter: null,
        entryKeys: null,
        buildingKeys: null,
        gateKeys: null,
        garageKeys: null,
        postalBoxKeys: null,
        rooms: [
          {
            type: 'undefined',
            score: null,
            comment: null,
            equipments: [],
            furniture: [],
            pictures: [],
          },
        ],
        signers: newSigners,
      });
    },
    onError: () => {
      utils.toast.error(t('global.form.errors.global'));
    },
  });

  const showDeleteConfirmModal = useCallback((uid) => {
    setDocSelected(uid);
    showModal(DELETE_CONFIRM_MODAL_ID);
  }, []);

  const handleDelete = useCallback(() => {
    inventoryDeleteMutation.mutate();
  }, []);

  const cancelDelete = useCallback(() => {
    setDocSelected(null);
    hideModal(DELETE_CONFIRM_MODAL_ID);
  }, []);

  const handleAdd = useCallback(() => {
    inventoryCreateMutation.mutate({
      date: new Date(),
    });
  }, [inventoryCreateMutation]);

  const columns = useMemo(() => ([
    {
      header: t('inventory.table.columns.creationDate'),
      accessorKey: 'creationDate',
      size: 125,
      cell: ({ row: { original: { creationDate } } }) => (
        <div>
          <span>{creationDate ? format(new Date(creationDate), 'dd MMM yyyy', { locale: fr }) : '-'}</span>
        </div>
      ),
    },
    {
      header: t('inventory.table.columns.property'),
      accessorKey: 'property',
      size: 125,
      cell: ({ row: { original: { property } } }) => (
        <div>
          {!isUserTenant ? (
            <Link to={`/property/${property?.uid}`} target="_blank" className={styles.name}>
              <p className="p-2-700">
                {property?.name}
                <Picto icon="export-link" width={9} color="var(--color-secondary)" className={styles.externalLink} />
              </p>
            </Link>
          ) : (
            <p className="p-2-700">
              {property?.name}
            </p>
          )}
          <p className="p-2-700">
            {formatAddress(property?.address)}
          </p>
        </div>
      ),
    },
    {
      header: t('inventory.table.columns.date'),
      accessorKey: 'date',
      size: 125,
      cell: ({ row: { original: { date } } }) => (
        <div>
          <span>{date ? format(new Date(date), 'dd MMM yyyy', { locale: fr }) : '-'}</span>
        </div>
      ),
    },
    {
      header: t('inventory.table.columns.tenants'),
      accessorKey: 'tenant',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { signers, draft, status } } }) => {
        const draftData = JSON.parse(draft);
        const signersData = status !== INVENTORY_STATUS_LIST.DRAFT ? signers : draftData?.signers;
        return (
          <div>
            {signersData
              ?.filter((signer) => signer.role === 'tenant')
              ?.map((tenant) => {
                const {
                  firstName,
                  lastName,
                  uid: tenantUid,
                } = tenant;
                return !isUserTenant ? (
                  <Link to={`/tenant/${tenantUid}`} target="_blank">
                    <p className="p-2-700">
                      {`${firstName} ${lastName}`}
                    </p>
                  </Link>
                ) : (
                  <p className="p-2-700">
                    {`${firstName} ${lastName}`}
                  </p>
                );
              })}
          </div>
        );
      },
    },
    {
      header: t('inventory.table.columns.status'),
      accessorKey: 'status',
      size: 100,
      cell: ({ row: { original: { status } } }) => (
        <div>
          <Tag
            variant={getInventoryStatusVariant(status)}
            size="medium"
            label={t(`inventory.status.${status}`)}
          />
        </div>
      ),
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 1,
      enableSorting: false,
      cell: ({
        row: {
          original: {
            uid, status, signers, documentUid,
          },
        },
      }) => {
        const signerUser = signers?.find((signer) => signer?.uid === user?.username);
        const shouldSign = signerUser?.status === 'pending' && signerUser?.link;
        return (
          <div className={styles.seeMore}>
            <Picto
              id={`more-option-inventory-${uid}`}
              icon="more"
              width={24}
              onClick={(e) => {
                e.stopPropagation();
                if (tooltipOpen === uid) setTooltipOpen(null);
                else setTooltipOpen(uid);
              }}
              color="var(--color-secondary)"
            />
            <Tooltip
              isOpen={tooltipOpen === uid}
              anchorId={`more-option-inventory-${uid}`}
              place="bottom"
              type="dark"
              effect="solid"
            >
              <TableOptions
                options={[
                  {
                    id: status === INVENTORY_STATUS_LIST.DRAFT ? 'edit' : 'see',
                    label: t(`global.listOptions.${status === INVENTORY_STATUS_LIST.DRAFT ? 'resume' : 'see'}`),
                    icon: (
                      <Picto
                        icon={status === INVENTORY_STATUS_LIST.DRAFT ? 'edit' : 'eye'}
                        width={24}
                        color="var(--color-primary)"
                      />
                    ),
                    onHoverIcon: (
                      <Picto
                        icon={status === INVENTORY_STATUS_LIST.DRAFT ? 'edit' : 'eye'}
                        width={24}
                        color="var(--color-white)"
                      />
                    ),
                    onClick: (e) => {
                      e.stopPropagation();
                      setTooltipOpen(null);
                      navigate(`/inventory/${uid}`);
                    },
                  },
                  documentUid ? {
                    id: 'download',
                    label: t('global.listOptions.download'),
                    icon: <Picto icon="import" width={24} color="var(--color-primary)" />,
                    onHoverIcon: <Picto icon="import" width={24} color="var(--color-white)" />,
                    onClick: () => {
                      handleDocumentDownload({ uid: documentUid }, t);
                    },
                  } : null,
                  shouldSign ? {
                    id: 'sign',
                    label: t('global.listOptions.sign'),
                    icon: <Picto icon="sign" width={24} color="var(--color-primary)" />,
                    onHoverIcon: <Picto icon="sign" width={24} color="var(--color-white)" />,
                    onClick: () => {
                      window.open(signerUser.link, '_blank');
                    },
                  } : null,
                  status === INVENTORY_STATUS_LIST.DRAFT && !isUserTenant ? {
                    id: 'delete',
                    label: t('global.listOptions.delete'),
                    icon: <Picto icon="trash" width={24} color="var(--color-primary)" />,
                    onHoverIcon: <Picto icon="trash" width={24} color="var(--color-white)" />,
                    onClick: (e) => {
                      e.stopPropagation();
                      setTooltipOpen(null);
                      showDeleteConfirmModal(uid);
                    },
                  } : null,
                ].filter(Boolean)}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ]), [
    t,
    tooltipOpen,
    navigate,
    setTooltipOpen,
    user,
    handleDocumentDownload,
    showDeleteConfirmModal,
    isUserTenant,
  ]);

  const header = useMemo(() => {
    if (!userHasManagementRights) return null;
    if (
      lease?.status === LEASE_STATUS.SIGNED
      || lease?.status === LEASE_STATUS.TERMINATION_SCHEDULED
      || lease?.status === LEASE_STATUS.TERMINATED
      || lease?.status === LEASE_STATUS.CREATED
    ) {
      return (
        <div className={styles.btnContainer}>
          <Button
            label={t('inventory.headerBtn')}
            size="medium"
            onClick={handleAdd}
            icon="plus"
            iconSide="left"
            loading={inventoryCreateMutation.isLoading || inventoryUpdateMutation.isLoading}
          />
        </div>
      );
    }
    return (
      <Message
        variant="info"
        content={t('inventory.cannotCreateInventory')}
      />
    );
  }, [
    t,
    userHasManagementRights,
    lease,
    handleAdd,
    inventoryCreateMutation.isLoading,
    inventoryUpdateMutation.isLoading,
  ]);

  if (!lease) return <UnlockerLoader size={200} align="left" />;

  return (
    <div className="m-t-35">
      <div className={styles.content}>
        {header}
        <div className="m-t-35">
          <Table
            fullWidth
            columns={columns}
            data={listData}
            isLoading={isFetching}
          />
        </div>
      </div>
      <ConfirmationModal
        id={DELETE_CONFIRM_MODAL_ID}
        onSubmit={handleDelete}
        onCancel={cancelDelete}
        loading={inventoryDeleteMutation.isLoading}
      />
    </div>
  );
}

LeaseInventories.propTypes = {
  inventoriesList: PropTypes.arrayOf(PropTypes.shape()),
  refetchList: PropTypes.func,
  isFetching: PropTypes.bool,
  lease: PropTypes.shape(),
  userHasManagementRights: PropTypes.bool,
};

export default LeaseInventories;
