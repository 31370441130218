import {
  useCallback,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import {
  utils,
} from 'ui-library-unlocker';

// Components
import DocsRequirements from '../../../molecules/DocsRequirements/DocsRequirements';
import DocStep from '../../../molecules/DocStep/DocStep';
import Modal from '../../../molecules/Modal/Modal';
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal';
import AddDocumentModal from '../../AddDocumentModal/AddDocumentModal';

// Services
import { deleteUserDocument } from '../../../../services/person';

// Utils
import { hideModal, showModal } from '../../../../utils/modal';
import { checkIfSendProfileValidationRequest } from '../../../../utils/user';

// Constants
import { RENT_DISTRIBUTION_STATUS } from '../../../../utils/constants';

import styles from './ProfileDocumentsList.module.scss';

const ADD_DOCUMENT_MODAL_ID = 'add-document-modal';
const ACCEPTED_DOCUMENTS_IDENTITY_PROOF__MODAL_ID = 'accepted-documents-identity-proof-modal';
const ACCEPTED_DOCUMENTS_ADDRESS_PROOF__MODAL_ID = 'accepted-documents-address-proof-modal';
const DELETE_CONFIRM_MODAL_ID = 'profile-document-delete-confirm';

function ProfileDocumentsList({
  documentsData,
  refetchDocuments,
  userUID,
  rentDistribution = null,
  isReadOnly = false,
}) {
  const { t } = useTranslation();

  const [fileType, setFileType] = useState(null);
  const [docSelected, setDocSelected] = useState(null);

  const documentDeleteMutation = useMutation({
    mutationFn: deleteUserDocument,
    onSuccess: () => {
      refetchDocuments();
      hideModal(DELETE_CONFIRM_MODAL_ID);
      utils.toast.success(t('global.documents.delete.success'));
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 400: {
            utils.toast.error(t('global.form.errors.generic'));
            break;
          }
          default: {
            utils.toast.error(t('global.form.errors.global'));
            break;
          }
        }
      }
    },
  });

  const handleDelete = useCallback((uid) => {
    setDocSelected(uid);
    showModal(DELETE_CONFIRM_MODAL_ID);
  }, []);

  const cancelDelete = useCallback(() => {
    setDocSelected(null);
    hideModal(DELETE_CONFIRM_MODAL_ID);
  }, []);

  const mustSendValidationRequest = checkIfSendProfileValidationRequest(rentDistribution);

  const docSteps = useMemo(() => [
    {
      docType: 'PPHIdentityProof',
      count: 1,
      infoModalId: ACCEPTED_DOCUMENTS_IDENTITY_PROOF__MODAL_ID,
    },
    {
      docType: 'PPHAddressProof',
      count: 2,
      infoModalId: ACCEPTED_DOCUMENTS_ADDRESS_PROOF__MODAL_ID,
    },
    mustSendValidationRequest ? {
      docType: 'PPHIban',
      count: 3,
    } : null,
    {
      docType: 'PPHOthers',
      count: mustSendValidationRequest ? 4 : 3,
      maxFiles: 10,
      optional: true,
    },
  ].filter(Boolean), [t, mustSendValidationRequest]);

  const getAcceptedDocumentsIdentityProofContent = useMemo(() => (
    <div className={styles.acceptedDocuments}>
      <h3 className="m-t-30 m-b-10">
        {t('profile.documents.acceptedDocuments.physical.identityIdTitle')}
      </h3>
      <ul className={styles.acceptedDocumentsList}>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.identityId1')}</li>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.identityId2')}</li>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.identityId3')}</li>
      </ul>
    </div>
  ), [t]);

  const getAcceptedDocumentsAddressProofContent = useMemo(() => (
    <div className={styles.acceptedDocuments}>
      <h3 className="m-t-30 m-b-10">
        {t('profile.documents.acceptedDocuments.unlistedPrivateCompany.addressIdTitle')}
      </h3>
      <ul className={styles.acceptedDocumentsList}>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.addressId1')}</li>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.addressId2')}</li>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.addressId3')}</li>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.addressId4')}</li>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.addressId5')}</li>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.addressId6')}</li>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.addressId7')}</li>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.addressId8')}</li>
      </ul>
    </div>
  ), [t]);

  const handleCloseUploadModal = useCallback(() => {
    setFileType(null);
  }, [setFileType]);

  return (
    <>
      <div className={styles.profileDocumentsList}>
        <DocsRequirements />

        {docSteps.map((item) => (
          <DocStep
            key={Array.isArray(item.docType) ? item.docType.join('-') : item.docType}
            documentListData={documentsData || []}
            addModalId={ADD_DOCUMENT_MODAL_ID}
            setFileType={setFileType}
            handleDelete={handleDelete}
            isReadOnly={isReadOnly}
            {...item}
          />
        ))}
      </div>

      {/* Accepted Documents Modal for Identity proof */}
      <Modal
        id={ACCEPTED_DOCUMENTS_IDENTITY_PROOF__MODAL_ID}
        title={t('profile.documents.acceptedDocumentsTitle')}
        size="large"
        onClose={() => {
          hideModal(ACCEPTED_DOCUMENTS_IDENTITY_PROOF__MODAL_ID);
        }}
      >
        {getAcceptedDocumentsIdentityProofContent}
      </Modal>

      {/* Accepted Documents Modal for Address proof */}
      <Modal
        id={ACCEPTED_DOCUMENTS_ADDRESS_PROOF__MODAL_ID}
        title={t('profile.documents.acceptedDocumentsTitle')}
        size="large"
        onClose={() => {
          hideModal(ACCEPTED_DOCUMENTS_ADDRESS_PROOF__MODAL_ID);
        }}
      >
        {getAcceptedDocumentsAddressProofContent}
      </Modal>

      {/* Add document modal */}
      <AddDocumentModal
        modalId={ADD_DOCUMENT_MODAL_ID}
        entityType="person"
        entityUID={userUID}
        refetchDocumentList={refetchDocuments}
        fileType={fileType}
        onClose={handleCloseUploadModal}
      />

      {/* Confirm delete modal */}
      <ConfirmationModal
        id={DELETE_CONFIRM_MODAL_ID}
        onSubmit={() => documentDeleteMutation.mutate(docSelected)}
        onCancel={cancelDelete}
        loading={documentDeleteMutation.isLoading}
      />
    </>
  );
}

ProfileDocumentsList.propTypes = {
  documentsData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  refetchDocuments: PropTypes.func.isRequired,
  userUID: PropTypes.string.isRequired,
  rentDistribution: PropTypes.oneOf(Object.values(RENT_DISTRIBUTION_STATUS)),
  isReadOnly: PropTypes.bool,
};

export default ProfileDocumentsList;
