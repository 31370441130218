import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { utils } from 'ui-library-unlocker';

// hooks
import useHover from '../../../hooks/useHover';

import styles from './HoverTag.module.scss';

function HoverTag({
  text,
  position = 'left',
  className = '',
  parentRef,
}) {
  const isHovering = useHover(parentRef);

  const placement = useMemo(() => {
    switch (position) {
      case 'top':
        return {
          bottom: 'calc(100% + 5px)',
          left: '50%',
          transform: 'translateX(-50%)',
        };
      case 'bottom':
        return {
          top: 'calc(100% + 5px)',
          left: '50%',
          transform: 'translateX(-50%)',
        };
      case 'left':
        return {
          right: 'calc(100% + 5px)',
          top: '50%',
          transform: 'translateY(-50%)',
        };
      case 'right':
        return {
          left: 'calc(100% + 5px)',
          top: '50%',
          transform: 'translateY(-50%)',
        };
      default:
        return {
          right: 'calc(100% + 5px)',
          top: '50%',
          transform: 'translateY(-50%)',
        };
    }
  }, [position]);

  const animation = useMemo(() => {
    switch (position) {
      case 'top':
        return styles.appearTop;
      case 'bottom':
        return styles.appearBottom;
      case 'left':
        return styles.appearLeft;
      case 'right':
        return styles.appearRight;
      default:
        return styles.appearLeft;
    }
  }, [position]);

  return isHovering
    ? (
      <div
        className={utils.cn([
          styles.hoverTag,
          className,
          animation,
        ])}
        style={{ ...placement }}
      >
        {text}
      </div>
    )
    : null;
}

HoverTag.propTypes = {
  text: PropTypes.string.isRequired,
  position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  className: PropTypes.string,
  parentRef: PropTypes.instanceOf(Object).isRequired,
};

export default HoverTag;
