import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

// Components
import {
  Picto,
} from 'ui-library-unlocker';
import DoughnutGraph from '../../../../components/atoms/KPI/DoughnutGraph/DoughnutGraph';

// Services
import { getPropertiesDistribution } from '../../../../services/stats';

// Hooks
import useResponsive from '../../../../hooks/useResponsive';

// Constants
import { PROPERTY_STATUS } from '../../../../utils/constants';

// Styles
import styles from './DoughnutKPI.module.scss';

function CustomizedLabel({
  value = 0,
  viewBox = {},
}) {
  const { t } = useTranslation();
  const { cx, cy } = viewBox;

  return (
    <text
      fill="#000"
      textAnchor="middle"
      dominantBaseline="middle"
      x={cx}
      y={cy - 7}
    >
      <tspan alignmentBaseline="middle" fontSize={26} fontWeight="bold">
        {value}
        %
      </tspan>
      <tspan x={cx} dy={20} fontSize={14} fill="var(--color-primary-500)">
        {t('dashboard.kpi.propertiesDistribution.vacancy')}
      </tspan>
    </text>
  );
}

CustomizedLabel.propTypes = {
  value: PropTypes.number,
  viewBox: PropTypes.shape(),
};

function DoughnutKPI() {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  const {
    data: propertiesDistribution,
    isLoading,
  } = useQuery({
    queryKey: ['properties-distribution'],
    queryFn: getPropertiesDistribution,
    keepPreviousData: false,
  });

  const propertiesDistributionData = useMemo(() => propertiesDistribution?.data || {}, [propertiesDistribution]);

  const chartData = useMemo(() => [
    {
      name: PROPERTY_STATUS.AVAILABLE,
      value: propertiesDistributionData.available || 0,
      color: 'var(--color-accent-green)',
    },
    {
      name: PROPERTY_STATUS.LEASED,
      value: propertiesDistributionData.leased || 0,
      color: 'var(--color-secondary)',
    },
    {
      name: PROPERTY_STATUS.DISABLED,
      value: propertiesDistributionData.disabled || 0,
      color: 'var(--color-tertiary)',
    },
  ], [propertiesDistributionData]);

  const vacancyPercent = useMemo(() => {
    // taux de available + disabled
    const total = chartData.reduce((acc, item) => acc + item.value, 0);
    if (!total) return 0;
    const availableProperties = chartData.find((item) => item.name === PROPERTY_STATUS.AVAILABLE)?.value || 0;
    const disabledProperties = chartData.find((item) => item.name === PROPERTY_STATUS.DISABLED)?.value || 0;
    return Math.floor(((availableProperties + disabledProperties) / total) * 100);
  }, [chartData]);

  const size = useMemo(() => (isMobile ? 125 : 200), [isMobile]);

  if (isLoading) {
    return (
      <div className="m-t-30">
        <h3 className="m-b-20 m-l-20">{t('dashboard.kpi.propertiesDistribution.title')}</h3>
        <div className={styles.container}>
          <Skeleton circle height={200} width={200} />
          <div className={styles.legendSkeleton}>
            <Skeleton height={20} />
            <Skeleton height={20} className="m-t-20" />
            <Skeleton height={20} className="m-t-20" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="m-t-30">
      <h3 className="m-b-20 m-l-20">{t('dashboard.kpi.propertiesDistribution.title')}</h3>
      <div className={styles.container}>
        <div style={{ width: size, height: size }}>
          <DoughnutGraph
            data={chartData}
            size={size}
            label={<CustomizedLabel value={vacancyPercent} />}
          />
        </div>
        <div className={styles.legend}>
          {chartData.map?.((item) => (
            <div key={item.name} className={styles.legendItem}>
              <div className={styles.legendColor} style={{ backgroundColor: item.color }} />
              <div className={styles.legendLabel}>
                <span>{t(`dashboard.kpi.propertiesDistribution.legend.${item.name}`)}</span>
                <Link
                  to={`/property?status=${item.name}`}
                  className={styles.legendLink}
                  target="_blank"
                >
                  {t('dashboard.kpi.propertiesDistribution.link', { count: item.value })}
                  <Picto className="m-l-5" color="var(--color-primary-400)" width={11} icon="export-link" />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DoughnutKPI;
