import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import ToggleInput from '../../atoms/ToggleInput/ToggleInput';

import cn from '../../../utils/cn';

import styles from './ToggleGroup.module.scss';

const ToggleGroup = ({
  name,
  options,
  display = 'vertical',
  className = '',
  value = [],
  itemsPerRow = 4,
  error = null,
  onChange,
  disabled = false,
}) => {
  const handleCheckboxChecked = useCallback((n) => {
    const newValue = value?.slice(0) || [];
    const checked = value?.find((v) => v === n) !== undefined;

    if (checked) {
      const index = newValue.indexOf(n);
      newValue.splice(index, 1);
    } else {
      newValue.push(n);
    }
    onChange(newValue);
  }, [value, onChange]);

  return (
    <>
      <div
        className={cn([
          styles.toggleGroup,
          display === 'horizontal'
            ? styles.toggleGroupHorizontal
            : styles.toggleGroupVertical,
          className,
        ])}
        style={{
          gridTemplateColumns: itemsPerRow ? `repeat(${itemsPerRow}, 1fr)` : null,
        }}
      >
        {options.map((option) => (
          <ToggleInput
            key={option.value}
            id={`${name}-${option.value}`}
            name={name}
            label={option.label}
            checked={value?.find((v) => v === option.value)}
            onChange={() => handleCheckboxChecked(option.value)}
            disabled={disabled}
          />
        ))}
      </div>
      {error && <div className={styles.error}>{error}</div>}
    </>
  );
};

ToggleGroup.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  display: PropTypes.oneOf(['horizontal', 'vertical']),
  className: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.shape()),
  itemsPerRow: PropTypes.number,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default ToggleGroup;
