import {
  cloneElement,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import {
  utils,
  SelectInput,
} from 'ui-library-unlocker';

// Components
import CityInput from '../../../components/molecules/CityInput/CityInput';
import AddressInput from '../../../components/molecules/AddressInput/AddressInput';
import ValidationChangeModal from '../../../components/molecules/ValidationChangeModal/ValidationChangeModal';
import IbanInput from '../../../components/molecules/IbanInput/IbanInput';

// Services
import { patchUserProfile } from '../../../services/profile';

// Hooks
import { useOnboardingContext } from '../../../store/onboardingContext';

// Utils
import { displayError, errorFocusSubmit, isFieldValid } from '../../../utils/forms/form';
import {
  onboardingProfile3InitialValues,
  onboardingProfile3Schema,
} from '../../../utils/forms/onboardingSchema';
import { showModal } from '../../../utils/modal';
import { checkIfSendProfileValidationRequest } from '../../../utils/user';

// Constants
import { NATIONALITIES, DEPARTMENTS } from '../../../utils/countries';
import { ENROLMENT_STATUS } from '../../../utils/constants';

import styles from './OnboardingTunnelProfile3.module.scss';

const PROFILE_CHANGE_MODAL_ID = 'profile-change-modal';

function OnboardingTunnelProfile3({
  children: footer = null,
  handleStep,
}) {
  const { t } = useTranslation();
  const {
    contextOnboarding: {
      inputtedData,
      error,
      companyData,
      profileData,
    },
    refetchProfile,
    refetchMe,
    // refetchProgress,
    enrolmentStatus,
  } = useOnboardingContext();
  const ibanRef = useRef(null);

  const initialValues = useMemo(() => {
    if (error) {
      return onboardingProfile3InitialValues;
    }
    if (inputtedData.profile) {
      return inputtedData.profile;
    }
    return onboardingProfile3InitialValues;
  }, [inputtedData.profile, error, companyData]);

  const mustSendValidationRequest = checkIfSendProfileValidationRequest(inputtedData?.profile?.rentDistribution);

  const formik = useFormik({
    initialValues,
    validationSchema: onboardingProfile3Schema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      // If no changes, go to next step
      if (JSON.stringify(values) === JSON.stringify({
        ...profileData?.data,
        hasAtLeastOneCompany: values.hasAtLeastOneCompany,
        idNumber: values.idNumber,
      })) {
        return handleStep(1);
      }
      // check if already in validation process
      // If so, open modal warning that the validation process must be sent once more
      if (
        ![
          ENROLMENT_STATUS.PENDING,
          ENROLMENT_STATUS.PROFILE_DONE,
        ].includes(enrolmentStatus)
      ) {
        showModal(PROFILE_CHANGE_MODAL_ID);
        return formik.setSubmitting(false);
      }
      ibanRef.current?.resetEditMode();
      return profileMutation.mutate({
        ...values,
        taxCountry: 'FR',
      });
    },
  });

  const profileMutation = useMutation({
    mutationFn: patchUserProfile,
    onSuccess: async () => {
      await refetchMe();
      await refetchProfile();
      // refetchProgress();
      return handleStep(1);
    },
    onError: (err) => {
      if (err?.response?.status === 400) {
        return utils.toast.error(t('global.form.errors.generic'));
      }
      return utils.toast.error(t('global.form.errors.global'));
    },
  });

  const handleSubmit = useCallback(() => {
    formik.handleSubmit();
  }, [formik]);

  const genderOptions = useMemo(() => [{
    value: 'male',
    label: t('profile.personalInfo.form.gender.male'),
  }, {
    value: 'female',
    label: t('profile.personalInfo.form.gender.female'),
  }, {
    value: 'none',
    label: t('profile.personalInfo.form.gender.other'),
  }], [t]);

  const titleOptions = useMemo(() => [{
    value: 'mr',
    label: t('profile.personalInfo.form.title.mr'),
  }, {
    value: 'ms',
    label: t('profile.personalInfo.form.title.ms'),
  }, {
    value: 'undefined',
    label: t('profile.personalInfo.form.title.undefined'),
  }], [t]);

  const nationalityOptions = useMemo(() => Object.keys(NATIONALITIES).map((countryCode) => ({
    value: countryCode,
    label: NATIONALITIES[countryCode],
  })), [t]);

  const birthDepartmentOptions = useMemo(() => DEPARTMENTS.map((dpt) => ({
    value: dpt.code,
    label: dpt.nom,
  })), [t]);

  const handleBirthCitySelect = useCallback((value) => {
    formik.setFieldValue('birthCity', value?.city);
    formik.setFieldValue('birthCountry', value?.countryCode);
    // As of now, set the birthDepartment to 991 (Europe) if the birthCountry is not France
    if (value?.countryCode !== 'FR') {
      return formik.setFieldValue('birthDepartment', '991');
    }
    if (value?.zipCode) {
      return formik.setFieldValue('birthDepartment', value.zipCode.slice(0, 2));
    }
    return formik.setFieldValue('birthDepartment', '');
  }, [formik]);

  return (
    <>
      <div className={styles.profile3}>
        <h2 className="m-b-10">
          {t('onboarding.steps.profile3.title')}
        </h2>
        <form className={styles.form} onSubmit={errorFocusSubmit(handleSubmit)}>
          <SelectInput
            id="gender"
            name="gender"
            className="m-t-20"
            label={t('profile.personalInfo.form.gender.label')}
            options={genderOptions}
            error={displayError(t, formik, 'gender')}
            valid={isFieldValid(formik, 'gender', null, initialValues?.gender)}
            onChange={(value) => {
              formik.setFieldValue('gender', value ? value.value : null);
              if (value?.value === 'male') formik.setFieldValue('title', 'mr');
              if (value?.value === 'female') formik.setFieldValue('title', 'ms');
            }}
            onBlur={formik.handleBlur}
            value={genderOptions.find((gender) => gender.value === formik.values.gender)}
          />
          <SelectInput
            id="title"
            name="title"
            className="m-t-25"
            label={t('profile.personalInfo.form.title.label')}
            options={titleOptions}
            error={displayError(t, formik, 'title')}
            valid={isFieldValid(formik, 'title', null, initialValues?.title)}
            onChange={(value) => {
              formik.setFieldValue('title', value ? value.value : null);
            }}
            onBlur={formik.handleBlur}
            value={titleOptions.find((title) => title.value === formik.values.title)}
          />
          <CityInput
            id="birthCity"
            name="birthCity"
            className="m-t-25"
            label={t('profile.personalInfo.form.birthCity')}
            error={displayError(t, formik, 'birthCity', 'text', { errorIfUntouched: true })}
            valid={isFieldValid(formik, 'birthCity', null, initialValues?.birthCity)}
            onAddressSelect={handleBirthCitySelect}
            value={formik.values.birthCity}
          />
          {formik.values.birthCountry === 'FR' && (
            <SelectInput
              id="birthDepartment"
              name="birthDepartment"
              className="m-t-10"
              label={t('profile.personalInfo.form.birthDepartment')}
              options={birthDepartmentOptions}
              error={displayError(t, formik, 'birthDepartment')}
              valid={isFieldValid(formik, 'birthDepartment', null, initialValues?.birthDepartment)}
              onChange={(value) => {
                formik.setFieldValue('birthDepartment', value.value);
              }}
              onBlur={formik.handleBlur}
              value={birthDepartmentOptions.find((option) => option.value === formik.values.birthDepartment)}
            />
          )}
          <SelectInput
            id="nationality"
            name="nationality"
            className="m-t-25"
            label={t('profile.personalInfo.form.nationality')}
            options={nationalityOptions}
            error={displayError(t, formik, 'nationality')}
            valid={isFieldValid(formik, 'nationality', null, initialValues?.nationality)}
            onChange={(value) => {
              formik.setFieldValue('nationality', value?.value || null);
            }}
            onBlur={formik.handleBlur}
            value={nationalityOptions.find((nationality) => nationality.value === formik.values.nationality)}
          />
          <AddressInput
            id="address"
            name="address"
            className={!formik.values.address ? 'm-t-25' : null}
            label={t('profile.personalInfo.form.address')}
            error={formik.submitCount > 0 ? t(formik.errors.address) : null}
            valid={isFieldValid(formik, 'address', null, initialValues?.address)}
            onAddressSelect={(value) => {
              formik.setFieldValue('address', value);
            }}
            value={formik.values.address || null}
          />
          {mustSendValidationRequest && (
            <IbanInput
              id="iban"
              name="iban"
              className="m-t-25"
              label={t('profile.personalInfo.form.iban')}
              error={displayError(t, formik, 'iban')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.iban}
              editMode={!!initialValues.iban}
              ref={ibanRef}
            />
          )}
        </form>
      </div>
      {footer && cloneElement(footer, {
        onSubmit: handleSubmit,
        isLoading: profileMutation.isLoading,
      })}
      <ValidationChangeModal
        id={PROFILE_CHANGE_MODAL_ID}
        onAccept={() => profileMutation.mutate(formik.values)}
        submitLoading={profileMutation.isLoading}
        entity="profile"
      />
    </>
  );
}

OnboardingTunnelProfile3.propTypes = {
  children: PropTypes.node,
  handleStep: PropTypes.func.isRequired,
};

export default OnboardingTunnelProfile3;
