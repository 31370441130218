import PropTypes from 'prop-types';
import { useMemo } from 'react';

// Components
import {
  PieChart, Pie, Cell, Label,
} from 'recharts';

function DoughnutGraph({
  size = 200,
  data = [],
  label = null,
}) {
  const center = useMemo(() => size / 2, [size]);

  const radius = useMemo(() => ({
    inner: center * 0.70,
    outer: center,
  }), [center]);

  const isAllZero = useMemo(() => {
    if (!data) return false;
    return data.every((item) => item.value === 0);
  }, [data]);

  const replacementData = useMemo(() => [
    { name: 'empty', value: 1 },
  ], []);

  if (isAllZero) {
    return (
      <PieChart width={size} height={size}>
        <Pie
          data={replacementData}
          dataKey="value"
          cx="50%"
          cy="50%"
          startAngle={90}
          endAngle={-270}
          innerRadius={radius.inner}
          outerRadius={radius.outer}
        >
          {replacementData.map?.((entry) => (
            <Cell
              key={`cell-${entry.name}`}
              fill="var(--color-primary-300"
              strokeWidth={0}
            />
          ))}
          <Label
            position="center"
            content={label}
          />
        </Pie>
      </PieChart>
    );
  }

  return (
    <PieChart width={size} height={size}>
      <Pie
        data={data}
        dataKey="value"
        cx="50%"
        cy="50%"
        startAngle={90}
        endAngle={-270}
        innerRadius={radius.inner}
        outerRadius={radius.outer}
      >
        {data.map?.((entry) => (
          <Cell
            key={`cell-${entry.name}`}
            fill={entry.color}
            strokeWidth={0}
          />
        ))}
        <Label
          position="center"
          content={label}
        />
      </Pie>
    </PieChart>
  );
}

DoughnutGraph.propTypes = {
  size: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.shape()),
  label: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]),
};

export default DoughnutGraph;
