/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  $getRoot, $createTextNode, KEY_ENTER_COMMAND,
} from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';

import cn from '../../../utils/cn';

import Theme from './theme/theme';

function Placeholder({ placeholder }) {
  return <div className="editor-placeholder-textarea">{placeholder}</div>;
}

const editorConfig = {
  theme: Theme,
  onError(error) {
    throw error;
  },
  nodes: [],
};

function ResetPlugin({ value, onEnterKeyDown }) {
  const [editor] = useLexicalComposerContext();
  const mounted = useRef(false);

  useEffect(() => editor.registerCommand(
    KEY_ENTER_COMMAND,
    (e) => {
      if (onEnterKeyDown) onEnterKeyDown();
      e.preventDefault();
      return true;
    },
    2,
  ), [editor, onEnterKeyDown]);

  useEffect(() => {
    if (!mounted.current) {
      editor.update(() => {
        const root = $getRoot();
        const [p] = root.getChildren();
        const t = $createTextNode(value);
        p.append(t);
      });
    }
    if (mounted.current && (value === '' || value === null)) {
      editor.update(() => {
        const root = $getRoot();
        const [p] = root.getChildren();
        p.clear();
      });
    }
    mounted.current = true;
  }, [value]);
}

export default function MessageInput({
  label,
  onChange = null,
  className = null,
  value,
  onEnterKeyDown = () => {},
}) {
  const onEditorChange = useCallback((editorState) => {
    editorState.read(() => {
      const root = $getRoot();
      onChange(root.getTextContent());
    });
  }, []);

  return (
    <div className={cn([className || null])}>
      <LexicalComposer initialConfig={editorConfig}>
        <div className={cn([
          'editor-container-textarea',
        ])}
        >
          <ResetPlugin value={value} onEnterKeyDown={onEnterKeyDown} />
          <OnChangePlugin onChange={onEditorChange} ignoreSelectionChange />
          <div className="editor-inner">
            <PlainTextPlugin
              contentEditable={<ContentEditable className="editor-textarea" />}
              placeholder={<Placeholder placeholder={label} />}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <HistoryPlugin />
          </div>
        </div>
      </LexicalComposer>
    </div>
  );
}

MessageInput.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  onEnterKeyDown: PropTypes.func,
};
