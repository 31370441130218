/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
import React, {
  useState, useCallback, useMemo, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import Webcam from 'react-webcam';

// Components
import Picto from '../../atoms/Picto/Picto';
import Button from '../../atoms/Button/Button';
import CameraGallery from './CameraGallery';

// Utils
import cn from '../../../utils/cn';

// Styles
import styles from './Camera.module.scss';

const ScreenshotButton = ({
  getScreenshot,
  onScreenshotTaken,
  loading,
}) => {
  const handleScreenshot = useCallback(() => {
    const imageSrc = getScreenshot();
    onScreenshotTaken(imageSrc);
  }, [getScreenshot, onScreenshotTaken]);

  if (loading) return null;

  return (
    <button
      className={styles.screenshotButton}
      onClick={handleScreenshot}
      type="button"
    >
      <Picto
        icon="camera"
        width={40}
        color="white"
      />
    </button>
  );
};

ScreenshotButton.propTypes = {
  getScreenshot: PropTypes.func.isRequired,
  onScreenshotTaken: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const Camera = ({
  className = '',
  label,
  info = '',
  constraints = {},
  cameraErrorMessage = 'Une erreur est survenue lors de l\'activation de la caméra',
  onClose = null,
  btnLabel = 'Ajouter',
  btnIcon = null,
  onSubmit,
  cameraClassName = '',
}) => {
  const [isCameraActive, setIsCameraActive] = useState(false);
  const [isCameraLoading, setIsCameraLoading] = useState(true);
  const [isCameraError, setIsCameraError] = useState(false);
  const [cameraSize, setCameraSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [images, setImages] = useState([]);

  const handleResize = useCallback(() => {
    setCameraSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const videoConstraints = useMemo(() => ({
    width: 1280,
    height: 720,
    ...constraints,
  }), []);

  const openCamera = useCallback(() => {
    setIsCameraActive(true);
  }, [setIsCameraActive]);

  const closeCamera = useCallback(() => {
    setIsCameraActive(false);
    setIsCameraLoading(true);
    setIsCameraError(false);
    setImages([]);
  }, [setIsCameraActive]);

  const handleBack = useCallback(() => {
    if (onClose) {
      onClose(closeCamera);
    } else {
      closeCamera();
    }
  }, [closeCamera, onClose]);

  const handleCameraLoaded = useCallback(() => {
    setIsCameraLoading(false);
  }, [setIsCameraLoading]);

  const handleCameraError = useCallback(() => {
    setIsCameraLoading(false);
    setIsCameraError(true);
  }, [setIsCameraLoading, setIsCameraError]);

  const handleScreenshotTaken = useCallback((imageSrc) => {
    setImages((prev) => [...prev, imageSrc]);
  }, [setImages]);

  const handleSubmit = useCallback(() => {
    onSubmit(images);
    closeCamera();
  }, [images, onSubmit, closeCamera]);

  return (
    <>
      <div
        className={cn([styles.container, className])}
        onClick={openCamera}
        role="presentation"
      >
        <Picto
          icon="camera"
          width={50}
        />
        <p className={styles.label}>
          {label}
        </p>
        <small>{info}</small>
      </div>
      {isCameraActive && (
        <div className={cn([styles.webcamContainer, cameraClassName])}>
          <div className={styles.webcamWrapper}>
            <div
              key={images.length} // To force re-render (so animation) on image added
              className={styles.flash}
            />
            <div className={styles.footerBg} />
            {isCameraLoading && (
              <Picto
                icon="loading"
                width={50}
                color="white"
                className={styles.cameraLoadingIcon}
              />
            )}
            {isCameraError && (
              <p className={styles.cameraErrorMessage}>
                <Picto
                  icon="alert-circle"
                  width={30}
                  color="white"
                  className="m-r-10"
                />
                <span>{cameraErrorMessage}</span>
              </p>
            )}
            <Webcam
              audio={false}
              width={cameraSize.width}
              height={cameraSize.height}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
              onUserMedia={handleCameraLoaded}
              onUserMediaError={handleCameraError}
            >
              {({ getScreenshot }) => (
                <ScreenshotButton
                  getScreenshot={getScreenshot}
                  onScreenshotTaken={handleScreenshotTaken}
                  loading={isCameraLoading || isCameraError}
                />
              )}
            </Webcam>
            <CameraGallery images={images} updateImages={setImages} />
            <div
              className={styles.iconBackContainer}
              onClick={handleBack}
              onKeyDown={handleBack}
              role="button"
              tabIndex={0}
            >
              <Picto
                icon="arrow-left"
                width={30}
                color="white"
              />
            </div>
            {(!isCameraLoading && !isCameraError) && (
              <Button
                className={styles.submitButton}
                onClick={handleSubmit}
                label={btnLabel}
                icon={btnIcon}
                iconSide="left"
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

Camera.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  info: PropTypes.string,
  constraints: PropTypes.shape({}),
  cameraErrorMessage: PropTypes.string,
  onClose: PropTypes.func,
  btnLabel: PropTypes.string,
  btnIcon: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  cameraClassName: PropTypes.string,
};

export default Camera;
