import PropTypes from 'prop-types';
import { Button } from 'ui-library-unlocker';

import { t } from 'i18next';
import Modal from '../Modal/Modal';

import { hideModal } from '../../../utils/modal';

import styles from './DeleteDocumentModal.module.scss';

function DeleteDocumentModal({
  id,
  document = null,
  onDocumentDelete,
}) {
  return (
    <Modal
      className={styles.modal}
      id={id}
      title={t('global.documents.delete.title', { fileName: document?.name || '' })}
      onClose={() => {
        hideModal(id);
      }}
    >
      <div className={styles.modalContent}>
        <Button
          variant="primary"
          size="large"
          className={styles.deleteButton}
          onClick={() => hideModal(id)}
        >
          {t('global.documents.delete.no')}
        </Button>
        <Button
          variant="secondary"
          size="large"
          className={styles.deleteButton}
          onClick={() => {
            onDocumentDelete(document);
            hideModal(id);
          }}
        >
          {t('global.documents.delete.yes')}
        </Button>
      </div>
    </Modal>
  );
}

DeleteDocumentModal.propTypes = {
  id: PropTypes.string.isRequired,
  document: PropTypes.shape(),
  onDocumentDelete: PropTypes.func.isRequired,
};

export default DeleteDocumentModal;
