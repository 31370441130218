import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';

// Components
import {
  Picto, Tag, Table,
} from 'ui-library-unlocker';
import Tooltip from '../../../components/atoms/Tooltip/Tooltip';
import TableOptions from '../../../components/atoms/TableOptions/TableOptions';
import DataTable from '../../../components/organisms/DataTable/DataTable';

// Services
import { getPaymentAccounts } from '../../../services/paymentAccount';

// Hooks
import useFilters from '../../../hooks/useFilters';

// Utils
import { getAccountEnrolmentVariant } from '../../../utils/variants';

// Styles
import styles from './PaymentAccount.module.scss';

// Constants
import { ACCOUNT_STATUS, ACCOUNT_ENROLMENT, SEARCH_SCOPE_LIST } from '../../../utils/constants';

const SEARCH_SCOPE = {
  [SEARCH_SCOPE_LIST.PROPERTY_OWNERS]: 'userUid',
  [SEARCH_SCOPE_LIST.MANAGERS]: 'userUid',
};

const getStatusVariant = (s) => {
  switch (s) {
    case ACCOUNT_STATUS.PENDING:
      return 'tertiary';
    case ACCOUNT_STATUS.OPENED:
      return 'secondary';
    case ACCOUNT_STATUS.CLOSED:
      return 'secondary-outline';
    case ACCOUNT_STATUS.BLOCKED:
    case ACCOUNT_STATUS.CREDIT_BLOCKED:
    case ACCOUNT_STATUS.DEBIT_BLOCKED:
      return 'error';
    default:
      return 'primary';
  }
};

function AccountCompaniesList({
  data = {
    companies: [],
  },
  tooltipOpen,
  setTooltipOpen,
}) {
  const { companies } = data;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = useMemo(() => ([
    {
      header: t('paymentAccount.table.columns.createdAt'),
      accessorKey: 'createdAt',
      size: 100,
      cell: ({ row: { original: { createdAt } } }) => (
        <div>
          <span>{createdAt ? format(new Date(createdAt), 'dd MMM yyyy', { locale: fr }) : '-'}</span>
        </div>
      ),
    },
    {
      header: t('paymentAccount.table.columns.id'),
      accessorKey: 'unlockerID',
      size: 150,
    },
    {
      header: t('paymentAccount.table.columns.name'),
      accessorKey: 'name',
      minSize: 150,
      cell: ({ row: { original: { companyName } } }) => (
        <span>
          {companyName}
        </span>
      ),
    },
    {
      header: t('paymentAccount.table.columns.ezyNessNb'),
      accessorKey: 'internalAccount',
      size: 150,
    },
    {
      header: t('paymentAccount.table.columns.enrolmentStatus'),
      accessorKey: 'enrolmentStatus',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { enrolmentStatus } } }) => (
        <Tag
          variant={getAccountEnrolmentVariant(enrolmentStatus)}
          label={t(`paymentAccount.table.enrolmentStatus.${enrolmentStatus}`)}
        />
      ),
    },
    {
      header: t('paymentAccount.table.columns.submissionDate'),
      accessorKey: 'submissionDate',
      size: 100,
      cell: ({ row: { original: { submissionDate } } }) => (
        <div>
          <span>{submissionDate ? format(new Date(submissionDate), 'dd MMM yyyy', { locale: fr }) : '-'}</span>
        </div>
      ),
    },
    {
      header: t('paymentAccount.table.columns.accountStatus'),
      accessorKey: 'accountStatus',
      size: 150,
      enableSorting: false,
      cell: ({ row: { original: { accountStatus } } }) => (
        <Tag
          variant={getStatusVariant(accountStatus)}
          label={t(`paymentAccount.accountStatus.${accountStatus}`)}
        />
      ),
    },
    {
      header: t('paymentAccount.table.columns.validationDate'),
      accessorKey: 'validationDate',
      size: 150,
      cell: ({ row: { original: { validationDate } } }) => (
        <div>
          <span>{validationDate ? format(new Date(validationDate), 'dd MMM yyyy', { locale: fr }) : '-'}</span>
        </div>
      ),
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 10,
      enableSorting: false,
      cell: ({ row }) => (
        <div className={styles.seeMore}>
          <Picto
            id={`more-option-company-payment-account-${row?.original?.uid}`}
            icon="more"
            width={24}
            onClick={(e) => {
              e.stopPropagation();
              setTooltipOpen(row?.original?.uid);
            }}
            color="var(--color-secondary)"
          />
          <Tooltip
            isOpen={tooltipOpen === row?.original?.uid}
            anchorId={`more-option-company-payment-account-${row?.original?.uid}`}
            place="bottom"
            type="dark"
            effect="solid"
          >
            <TableOptions
              options={[
                {
                  id: 'edit',
                  label: t('paymentAccount.options.goToDetails'),
                  icon: <Picto icon="edit" width={24} color="var(--color-primary)" />,
                  onHoverIcon: <Picto icon="edit" width={24} color="var(--color-white)" />,
                  onClick: (e) => {
                    e.stopPropagation();
                    setTooltipOpen(null);
                    navigate(`/company/${row?.original?.userUid}#payment-account`);
                  },
                },
              ]}
            />
          </Tooltip>
        </div>
      ),
    },
  ]), [t, tooltipOpen, navigate]);

  return (
    <div className={styles.companiesList}>
      {companies?.length ? (
        <Table
          fullWidth
          columns={columns}
          data={companies}
          disableHover
        />
      ) : (
        <span className={styles.noCompanies}>{t('owner.noCompanies')}</span>
      )}
    </div>
  );
}

AccountCompaniesList.propTypes = {
  data: PropTypes.shape({
    companies: PropTypes.arrayOf(PropTypes.shape()),
    uid: PropTypes.string.isRequired,
  }),
  tooltipOpen: PropTypes.string.isRequired,
  setTooltipOpen: PropTypes.func.isRequired,
};

function PaymentAccountList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { filters } = useFilters();

  const [tooltipOpen, setTooltipOpen] = useState(null);
  const [tooltipCompanyOpen, setTooltipCompanyOpen] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    document.title = t('paymentAccount.browserTitle');
  }, [t]);

  const columns = useMemo(() => ([
    {
      header: t('paymentAccount.table.columns.createdAt'),
      accessorKey: 'createdAt',
      size: 120,
      cell: ({ row: { original: { createdAt } } }) => (
        <div>
          <span>{createdAt ? format(new Date(createdAt), 'dd MMM yyyy', { locale: fr }) : '-'}</span>
        </div>
      ),
    },
    {
      header: t('paymentAccount.table.columns.id'),
      accessorKey: 'unlockerID',
      size: 150,
    },
    {
      header: t('paymentAccount.table.columns.name'),
      accessorKey: 'name',
      minSize: 150,
      cell: ({ row: { original: { firstName, lastName } } }) => (
        <span>
          {`${firstName} ${lastName}`}
        </span>
      ),
    },
    {
      header: t('paymentAccount.table.columns.ezyNessNb'),
      accessorKey: 'internalAccount',
      size: 150,
    },
    {
      header: t('paymentAccount.table.columns.enrolmentStatus'),
      accessorKey: 'enrolmentStatus',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { enrolmentStatus } } }) => (
        <Tag
          variant={getAccountEnrolmentVariant(enrolmentStatus)}
          label={t(`paymentAccount.table.enrolmentStatus.${enrolmentStatus}`)}
        />
      ),
    },
    {
      header: t('paymentAccount.table.columns.submissionDate'),
      accessorKey: 'submissionDate',
      size: 120,
      cell: ({ row: { original: { submissionDate } } }) => (
        <div>
          <span>{submissionDate ? format(new Date(submissionDate), 'dd MMM yyyy', { locale: fr }) : '-'}</span>
        </div>
      ),
    },
    {
      header: t('paymentAccount.table.columns.accountStatus'),
      accessorKey: 'accountStatus',
      size: 150,
      enableSorting: false,
      cell: ({ row: { original: { accountStatus } } }) => (
        <Tag
          variant={getStatusVariant(accountStatus)}
          label={t(`paymentAccount.accountStatus.${accountStatus}`)}
        />
      ),
    },
    {
      header: t('paymentAccount.table.columns.validationDate'),
      accessorKey: 'validationDate',
      size: 150,
      cell: ({ row: { original: { validationDate } } }) => (
        <div>
          <span>{validationDate ? format(new Date(validationDate), 'dd MMM yyyy', { locale: fr }) : '-'}</span>
        </div>
      ),
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 10,
      enableSorting: false,
      cell: ({ row }) => (
        <div className={styles.seeMore}>
          <Picto
            id={`more-option-payment-account-${row?.original?.uid}`}
            icon="more"
            width={24}
            onClick={(e) => {
              e.stopPropagation();
              setTooltipOpen(row?.original?.uid);
            }}
            color="var(--color-secondary)"
          />
          <Tooltip
            isOpen={tooltipOpen === row?.original?.uid}
            anchorId={`more-option-payment-account-${row?.original?.uid}`}
            place="bottom"
            type="dark"
            effect="solid"
          >
            <TableOptions
              options={[
                {
                  id: 'edit',
                  label: t('paymentAccount.options.goToDetails'),
                  icon: <Picto icon="edit" width={24} color="var(--color-primary)" />,
                  onHoverIcon: <Picto icon="edit" width={24} color="var(--color-white)" />,
                  onClick: (e) => {
                    e.stopPropagation();
                    setTooltipOpen(null);
                    navigate(`/user/${row?.original?.userUid}#payment-account`);
                  },
                },
              ]}
            />
          </Tooltip>
        </div>
      ),
    },
  ]), [t, tooltipOpen, navigate]);

  const allFiltersOptions = useMemo(() => [
    {
      name: 'enrolmentStatus',
      type: 'checkbox',
      title: t('paymentAccount.table.columns.enrolmentStatus'),
      options: Object.values(ACCOUNT_ENROLMENT).map((status) => ({
        value: status,
        label: t(`paymentAccount.table.enrolmentStatus.${status}`),
      })),
    },
    {
      name: 'accountStatus',
      type: 'checkbox',
      title: t('paymentAccount.table.columns.accountStatus'),
      options: Object.values(ACCOUNT_STATUS).map((status) => ({
        value: status,
        label: t(`paymentAccount.accountStatus.${status}`),
      })),
    },
    {
      name: 'createdAt',
      multiName: ['createdAtDate', 'createdAtStartDate', 'createdAtEndDate'],
      type: 'date',
      title: t('paymentAccount.table.columns.createdAt'),
      options: [
        {
          value: filters?.createdAtDate?.[0],
          label: `${t('global.filterDate.exactSelectedFilterLabel')} ${filters?.createdAtDate?.[0]
            ? format(new Date(filters?.createdAtDate?.[0]), 'dd/MM/yyyy', { locale: fr })
            : ''
          }`,
        },
        {
          value: filters?.createdAtStartDate?.[0],
          label: `${t('global.filterDate.after')} ${filters?.createdAtStartDate?.[0]
            ? format(new Date(filters?.createdAtStartDate?.[0]), 'dd/MM/yyyy', { locale: fr })
            : ''
          }`,
        },
        {
          value: filters?.createdAtEndDate?.[0],
          label: `${t('global.filterDate.before')} ${filters?.createdAtEndDate?.[0]
            ? format(new Date(filters?.createdAtEndDate?.[0]), 'dd/MM/yyyy', { locale: fr })
            : ''
          }`,
        },
      ],
    },
  ], [filters, t]);

  const openedRows = useMemo(
    () => data
      .filter((account) => account.companies?.length > 0)
      .map((account) => account.uid),
    [data],
  );

  const handleTooltips = useCallback(() => {
    setTooltipOpen(null);
    setTooltipCompanyOpen(null);
  }, [setTooltipOpen, setTooltipCompanyOpen]);

  return (
    <div
      onClick={handleTooltips}
      role="presentation"
      onKeyDown={null}
    >
      <div className={styles.heading}>
        <h1>{t('paymentAccount.title')}</h1>
      </div>
      <DataTable
        columns={columns}
        searchLabel={t('paymentAccount.search')}
        omniSearch
        omniSearchScope={SEARCH_SCOPE}
        getData={getPaymentAccounts}
        setData={setData}
        queryKey="payment-account-list"
        allFiltersOptions={allFiltersOptions}
        openable
        alreadyOpened={openedRows}
      >
        <AccountCompaniesList
          tooltipOpen={tooltipCompanyOpen}
          setTooltipOpen={setTooltipCompanyOpen}
        />
      </DataTable>
    </div>
  );
}

export default PaymentAccountList;
