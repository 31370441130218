/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

// Components
import { useFormik } from 'formik';
import {
  Button,
  TextInput,
  utils,
  PhoneInput,
  TextAreaInput,
} from 'ui-library-unlocker';
import Modal from '../../molecules/Modal/Modal';

// Utils
import { hideModal } from '../../../utils/modal';
import { contactAdInitialValues, contactAdSchema } from '../../../utils/forms/contactAdSchema';
import { displayError, isFieldValid, errorFocusSubmit } from '../../../utils/forms/form';

// Services
import { contactPostAd } from '../../../services/property';

import styles from './ContactAdModal.module.scss';

function ContactAdModal({
  idModal,
  onClose = () => {},
  propertyUID,
}) {
  const { t } = useTranslation();

  const contactMutation = useMutation({
    mutationFn: (data) => contactPostAd(propertyUID, data),
    onSuccess: () => {
      handleClose();
      utils.toast.info(t('contactAd.success'));
    },
    onError: () => {
      utils.toast.error(t('global.form.errors.global'));
    },
  });

  const formik = useFormik({
    initialValues: contactAdInitialValues,
    validationSchema: contactAdSchema,
    onSubmit: (values) => {
      contactMutation.mutate(values);
    },
  });

  const handleClose = useCallback(() => {
    hideModal(idModal);
    setTimeout(() => formik.resetForm(), 1000);
    onClose();
  }, [formik, idModal]);

  return (
    <Modal
      className={styles.modal}
      id={idModal}
      title={t('contactAd.title')}
      onClose={handleClose}
    >
      <div className={styles.modalContent}>
        <form className={styles.form} onSubmit={errorFocusSubmit(formik.handleSubmit)}>
          <TextInput
            type="text"
            id="firstName"
            name="firstName"
            className={styles.mediumInput}
            label={t('postAd.contactInfo.form.firstName')}
            error={displayError(t, formik, 'firstName')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
          />
          <TextInput
            type="text"
            id="lastName"
            name="lastName"
            className={utils.cn(['m-t-20', styles.mediumInput])}
            label={t('postAd.contactInfo.form.lastName')}
            error={displayError(t, formik, 'lastName')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastName}
          />
          <TextInput
            type="text"
            id="email"
            name="email"
            className={utils.cn(['m-t-20', styles.mediumInput])}
            label={t('postAd.contactInfo.form.email')}
            error={displayError(t, formik, 'email')}
            valid={isFieldValid(formik, 'email')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          <PhoneInput
            id="phoneNumber"
            name="phoneNumber"
            label={t('postAd.contactInfo.form.phone')}
            className={utils.cn(['m-t-20', styles.mediumInput])}
            error={displayError(t, formik, 'phoneNumber')}
            valid={isFieldValid(formik, 'phoneNumber')}
            value={{
              countryCode: formik?.values?.phoneCountryCode,
              phone: formik?.values?.phoneNumber,
            }}
            onBlur={formik.handleBlur}
            onChange={(value) => {
              const { countryCode, phone } = value;
              formik.setFieldValue('phoneCountryCode', countryCode);
              formik.setFieldValue('phoneNumber', phone);
            }}
          />
          <TextAreaInput
            id="contact-ad-message"
            name="message"
            className={utils.cn(['m-t-20', styles.mediumInput])}
            label={t('contactAd.message')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.message}
            error={displayError(t, formik, 'message')}
            valid={isFieldValid(formik, 'message')}
          />
          <div className={utils.cn(['m-t-40', styles.modalActions])}>
            <Button
              variant="secondary"
              size="large"
              className={styles.submitBtn}
              onClick={handleClose}
            >
              {t('global.cancel')}
            </Button>
            <Button
              type="submit"
              variant="primary"
              size="large"
              className={styles.submitBtn}
              loading={formik.isSubmitting}
            >
              {t('global.confirm')}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

ContactAdModal.propTypes = {
  idModal: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  propertyUID: PropTypes.string.isRequired,
};

export default ContactAdModal;
