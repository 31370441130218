/* eslint-disable no-new */
import {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
  Children,
  isValidElement,
  cloneElement,
} from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';

// Components
import { MarkerClusterer, defaultOnClusterClickHandler } from '@googlemaps/markerclusterer';
import {
  UnlockerLoader,
  Picto,
  utils,
} from 'ui-library-unlocker';

// Utils
import { formatPrice } from '../../../utils/properties';

// Hooks
import { useAppContext } from '../../../store/context';

// Constants
import { PROPERTY_STATUS } from '../../../utils/constants';

// Styles
import styles from './SearchMap.module.scss';

const IS_PUBLIC = !localStorage.getItem('accessToken');
const MAX_ZOOM = 20;

// --- MAP ---
function MapComponent({
  center = null,
  zoom = null,
  children = null,
  displaySameLocation,
}) {
  const ref = useRef(null);
  const [clusterer, setClusterer] = useState();
  const { context: { googleMaps, searchMap: map }, dispatch } = useAppContext();

  useEffect(() => {
    if (ref.current && !map) {
      dispatch({
        type: 'SET_SEARCH_MAP',
        payload: new googleMaps.maps.Map(ref.current, {
          center,
          zoom,
          mapId: process.env.REACT_APP_MAP_ID,
          disableDefaultUI: true,
          maxZoom: MAX_ZOOM,
        }),
      });
    }

    return () => {
      if (map) {
        map.unbindAll(); // Assurez-vous de nettoyer les événements liés à la carte
        dispatch({
          type: 'SET_SEARCH_MAP',
          payload: null,
        });
      }
    };
  }, [ref, map]);

  useEffect(() => {
    if (map) {
      clusterer?.clearMarkers();
      setClusterer(new MarkerClusterer({
        map,
        algorithmOptions: {
          maxZoom: MAX_ZOOM,
        },
        renderer: {
          render: ({ count, position }, stats) => {
            // change color if this cluster has more markers than the mean cluster
            const color = count > Math.max(10, stats.clusters.markers.mean)
              ? '#c71620'
              : '#173cc1';

            // create svg url with fill color
            const svg = window.btoa(`
            <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
              <circle cx="120" cy="120" opacity="1" r="70" />
              <circle cx="120" cy="120" opacity=".3" r="90" />
              <circle cx="120" cy="120" opacity=".2" r="110" />
            </svg>`);

            // create marker using svg icon
            // eslint-disable-next-line no-undef
            return new googleMaps.maps.Marker({
              position,
              icon: {
                url: `data:image/svg+xml;base64,${svg}`,
                // eslint-disable-next-line no-undef
                scaledSize: new googleMaps.maps.Size(45, 45),
              },
              label: {
                text: String(count),
                color: 'rgba(255,255,255,0.9)',
                fontSize: '13px',
              },
              // adjust zIndex to be above other markers
              zIndex: 1000 + count,
            });
          },
        },
        onClusterClick: (event, cluster, mapArg) => {
          const firstCoord = {
            lat: cluster.markers[0].position.lat,
            lng: cluster.markers[0].position.lng,
          };
          const sameLocation = cluster.markers.every(
            (marker) => marker.position.lat === firstCoord.lat && marker.position.lng === firstCoord.lng,
          );
          if (sameLocation) {
            map.panTo(firstCoord);
            displaySameLocation(firstCoord);
            dispatch({
              type: 'SET_SEARCH_MAP_PROPERTY',
              payload: null,
            });
          } else {
            defaultOnClusterClickHandler(event, cluster, mapArg);
          }
        },
      }));
    }
  }, [map, displaySameLocation, dispatch]);

  return (
    <>
      <div
        ref={ref}
        id="searchMap"
        className={utils.cn([
          styles.searchMap,
          IS_PUBLIC ? styles.isPublic : null,
        ])}
      />
      {// eslint-disable-next-line consistent-return
      Children.map(children, (child) => {
        if (isValidElement(child)) {
          return cloneElement(child, { map, clusterer });
        }
      })
      }
    </>
  );
}
MapComponent.propTypes = {
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  zoom: PropTypes.number,
  children: PropTypes.node,
  displaySameLocation: PropTypes.func.isRequired,
};
// ------------------------------

// --- MARKER ---
function Marker({
  position = null,
  children = null,
  onClick = () => {},
  clusterer = null,
}) {
  const rootRef = useRef();
  const markerRef = useRef();
  const { context: { googleMaps } } = useAppContext();

  useEffect(() => {
    if (!rootRef.current) {
      const container = document.createElement('div');
      rootRef.current = createRoot(container);

      // eslint-disable-next-line no-undef
      markerRef.current = new googleMaps.maps.marker.AdvancedMarkerView({
        position,
        content: container,
      });
    }

    return () => {
      clusterer?.removeMarker(markerRef.current);
    };
  }, []);

  useEffect(() => {
    clusterer?.addMarker(markerRef.current);
  }, [clusterer]);

  useEffect(() => {
    markerRef.current.position = position;
  }, [position]);

  useEffect(() => {
    rootRef.current.render(children);
  }, [children]);

  useEffect(() => {
    const listener = markerRef.current.addListener('gmp-click', onClick);
    return () => {
      listener.remove();
    };
  }, [onClick]);

  return null;
}
Marker.propTypes = {
  position: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  children: PropTypes.node,
  onClick: PropTypes.func,
  clusterer: PropTypes.shape({
    addMarker: PropTypes.func,
    removeMarker: PropTypes.func,
  }),
};
// ------------------------------

const CENTER = { lat: 47.750708933237014, lng: 7.3304143488598505 };
const ZOOM = 11;

function SearchMap({
  markers = [],
  displaySameLocation,
  hideSameLocation,
}) {
  const { context: { googleMaps, searchMapProperty }, dispatch } = useAppContext();

  const handleClick = useCallback((markerId) => {
    const container = document.getElementById('mapResultsList');
    const propertyCard = document.getElementById(markerId);

    if (propertyCard && container) {
      hideSameLocation();
      dispatch({
        type: 'SET_SEARCH_MAP_PROPERTY',
        payload: {
          id: markerId,
          type: 'hover',
        },
      });
      const rect = propertyCard.getBoundingClientRect();
      const top = propertyCard.offsetTop;
      container.scrollTo({
        top: top - rect.height,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [dispatch]);

  const mapMarkers = useMemo(() => markers.map((marker) => (
    <Marker
      key={marker.objectID}
      position={marker.position}
      onClick={() => handleClick(marker.objectID)}
    >
      <span
        className={utils.cn([
          styles.marker,
          marker.objectID === searchMapProperty?.id ? styles.markerSelected : null,
        ])}
      >
        {marker.propertyStatus === PROPERTY_STATUS.AVAILABLE ? (
          formatPrice(utils.centsToEuro((marker.rentExcludingCharges || 0) + (marker.charges || 0)))
        ) : (
          <Picto icon="house-bulk" width={13} />
        )}
      </span>
    </Marker>
  )), [markers, searchMapProperty]);

  if (!googleMaps) {
    return (
      <div
        className={utils.cn([
          styles.searchMap,
          IS_PUBLIC ? styles.isPublic : null,
        ])}
      >
        <UnlockerLoader size={300} />
      </div>
    );
  }

  return (
    <MapComponent center={CENTER} zoom={ZOOM} displaySameLocation={displaySameLocation}>
      {mapMarkers}
    </MapComponent>
  );
}

SearchMap.propTypes = {
  markers: PropTypes.arrayOf(PropTypes.shape({
    position: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
    objectID: PropTypes.string,
    propertyStatus: PropTypes.oneOf(Object.values(PROPERTY_STATUS)),
    rentExcludingCharges: PropTypes.number,
    charges: PropTypes.number,
  })),
  displaySameLocation: PropTypes.func.isRequired,
  hideSameLocation: PropTypes.func.isRequired,
};

export default SearchMap;
