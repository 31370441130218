import {
  cloneElement,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Components
import {
  // Button,
  utils,
} from 'ui-library-unlocker';
import ImageUpload from '../../../components/organisms/ImageUpload/ImageUpload';

// Hooks
import { useOnboardingContext } from '../../../store/onboardingContext';

import styles from './OnboardingTunnelCompany3.module.scss';

function OnboardingTunnelCompany3({
  children: footer = null,
  handleStep,
}) {
  const { t } = useTranslation();
  const {
    contextOnboarding: {
      companyData,
      picturesCompanyData,
      // inputtedData,
    },
    refetchPicturesCompany,
  } = useOnboardingContext();

  const companyLogo = useMemo(() => (
    picturesCompanyData?.data?.collection?.[0]
  ), [picturesCompanyData]);

  const handleSubmit = useCallback(() => {
    handleStep(1);
  }, [handleStep]);

  return (
    <>
      <div className={styles.company1}>
        {/* <h2 className="m-b-15">
          {t('onboarding.steps.company3.bankingIdentity')}
          &nbsp;
          {inputtedData.company?.legalName || ''}
        </h2>
        <Button
          variant="primary"
          label={t('global.listOptions.add')}
          size="medium"
          icon="plus"
          onClick={handleSubmit}
        /> */}

        <div className={utils.cn([styles.title, 'm-t-35'])}>
          <h2>
            {t('onboarding.steps.company3.logo')}
          </h2>
          <small>{t('global.form.optional')}</small>
        </div>
        <ImageUpload
          entityUID={companyData?.data?.uid}
          entityType="company"
          picture={companyLogo}
          refetchPicture={refetchPicturesCompany}
          fileType="logo"
        />
      </div>
      {footer && cloneElement(footer, {
        onSubmit: handleSubmit,
      })}
    </>
  );
}

OnboardingTunnelCompany3.propTypes = {
  children: PropTypes.node,
  handleStep: PropTypes.func.isRequired,
};

export default OnboardingTunnelCompany3;
