import {
  useState, useMemo, useCallback, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TextInput } from 'ui-library-unlocker';

import styles from './IbanInput.module.scss';

const IbanInput = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const {
    id = 'iban',
    name = 'iban',
    className = '',
    label = '',
    error = false,
    onChange = () => {},
    onBlur = () => {},
    value = null,
    disabled = false,
    editMode = true,
    ...inputProps
  } = props;

  const [editing, setEditing] = useState(!editMode);
  const [isMasked, setIsMasked] = useState(true);

  useImperativeHandle(ref, () => ({
    resetEditMode: () => {
      setEditing(false);
    },
  }));

  useEffect(() => {
    setEditing(!editMode);
  }, [editMode]);

  const handleEditBtn = useCallback(() => {
    if (disabled) return setIsMasked((prev) => !prev);
    return setEditing(true);
  }, [disabled]);

  const displayedValue = useMemo(() => {
    const maskedValue = value ? value.replace(/.(?=.{3})/g, '•') : '';
    return isMasked ? maskedValue : value;
  }, [value, isMasked]);

  const editBtnLabel = useMemo(() => {
    if (disabled && isMasked) return t('global.listOptions.see');
    if (disabled && !isMasked) return t('global.listOptions.hide');
    return t('global.listOptions.edit');
  }, [t, disabled, isMasked]);

  const displayedInput = useMemo(() => {
    if (editing) {
      return (
        <TextInput
          {...inputProps}
          type="text"
          id={id}
          name={name}
          className={className}
          label={label}
          error={error}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          disabled={disabled}
        />
      );
    }
    return (
      <div className={styles.ibanGroup}>
        <TextInput
          type="text"
          id={id}
          name={name}
          className={className}
          label={label}
          error={error}
          value={displayedValue.replace(/(.{4})/g, '$1 ')}
          disabled
        />
        <div className={styles.editIban}>
          <div
            role="button"
            tabIndex={0}
            onClick={handleEditBtn}
            onKeyDown={handleEditBtn}
            className={styles.editIbanBtn}
          >
            {editBtnLabel}
          </div>
        </div>
      </div>
    );
  }, [t, props, handleEditBtn, editing, displayedValue, editBtnLabel]);

  return displayedInput;
});

IbanInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  editMode: PropTypes.bool,
};

export default IbanInput;
