import React from 'react';
import PropTypes from 'prop-types';

import Picto from '../Picto/Picto';

import styles from './UnlockerLoader.module.scss';

function UnlockerLoader({
  size = 600,
  variant = 'loaderBlack',
  align = 'center',
}) {
  return (
    <div
      className={styles.unlockerLoader}
      style={{
        justifyContent: align,
      }}
    >
      <Picto
        icon={variant}
        width={size}
        height={size}
      />
    </div>
  );
}

UnlockerLoader.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.oneOf(['loaderBlack', 'loaderWhite']),
  align: PropTypes.oneOf(['center', 'left', 'right']),
};

export default UnlockerLoader;
