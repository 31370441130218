import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components
import {
  TextInput,
  PhoneInput,
  Button,
} from 'ui-library-unlocker';

// Utils
import { displayError, isFieldValid } from '../../utils/forms/form';

function ContactInfo({
  formik,
  className = '',
  checkEmailMutation,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (formik.values.email?.length < 4) return;
      try {
        checkEmailMutation.mutate({ email: formik.values.email });
        formik.setFieldTouched('email', true);
      } catch (err) {
        if (err?.response) {
          //
        }
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [formik.values.email]);

  const handleSignUp = useCallback(() => {
    navigate('/register');
  }, [navigate]);

  return (
    <>
      <p>{t('postAd.description')}</p>
      <h2 className="m-t-30">{t('postAd.delete.subtitle')}</h2>
      <p className="m-t-20">
        <Trans i18nKey="postAd.delete.description" />
      </p>
      <Button
        type="button"
        className="m-t-30"
        size="large"
        label={t('postAd.delete.signUp')}
        onClick={handleSignUp}
      />
      <h2 className="m-b-25 m-t-30">{t('postAd.contactInfo.title')}</h2>
      <form className={className} onSubmit={formik.handleSubmit}>
        <TextInput
          type="text"
          id="firstName"
          name="firstName"
          label={t('postAd.contactInfo.form.firstName')}
          error={displayError(t, formik, 'firstName')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.firstName}
        />
        <TextInput
          type="text"
          id="lastName"
          name="lastName"
          className="m-t-20"
          label={t('postAd.contactInfo.form.lastName')}
          error={displayError(t, formik, 'lastName')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.lastName}
        />
        <TextInput
          type="text"
          id="companyName"
          name="companyName"
          className="m-t-20"
          label={`${t('postAd.contactInfo.form.companyName')} ${t('global.form.optional')}`}
          error={displayError(t, formik, 'companyName')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.companyName}
        />
        <TextInput
          type="text"
          id="email"
          name="email"
          className="m-t-20"
          label={t('postAd.contactInfo.form.email')}
          error={displayError(t, formik, 'email')}
          valid={checkEmailMutation.isError ? null : isFieldValid(formik, 'email')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          loading={checkEmailMutation.isLoading}
        />
        <PhoneInput
          id="phoneNumber"
          name="phoneNumber"
          label={t('postAd.contactInfo.form.phone')}
          className="m-t-20"
          error={displayError(t, formik, 'phoneNumber')}
          valid={isFieldValid(formik, 'phoneNumber')}
          value={{
            countryCode: formik?.values?.countryCode,
            phone: formik?.values?.phoneNumber,
          }}
          onBlur={formik.handleBlur}
          onChange={(value) => {
            const { countryCode, phone } = value;
            formik.setFieldValue('countryCode', countryCode);
            formik.setFieldValue('phoneNumber', phone);
          }}
        />
      </form>
    </>
  );
}

ContactInfo.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      companyName: PropTypes.string,
      email: PropTypes.string,
      countryCode: PropTypes.string,
      phoneNumber: PropTypes.string,
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
  }).isRequired,
  className: PropTypes.string,
  checkEmailMutation: PropTypes.shape({
    mutate: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
  }).isRequired,
};

export default ContactInfo;
