import PropTypes from 'prop-types';

// Components
import {
  utils,
} from 'ui-library-unlocker';
import StepperMUI from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

// Styles
import styles from './Stepper.module.scss';

function Stepper({
  className = '',
  steps,
  activeStep = 0,
}) {
  return (
    <StepperMUI
      className={utils.cn([className, styles.stepper])}
      activeStep={activeStep}
      alternativeLabel
    >
      {steps?.map?.((step) => (
        <Step key={step.label}>
          <StepLabel error={step.error}>{step.label}</StepLabel>
        </Step>
      ))}
    </StepperMUI>
  );
}

Stepper.propTypes = {
  className: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    error: PropTypes.bool,
  })).isRequired,
  activeStep: PropTypes.number,
};

export default Stepper;
