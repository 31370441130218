import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Picto } from 'ui-library-unlocker';

function Tooltip({
  text,
}) {
  return (
    <div style={{
      position: 'absolute',
      top: '100%',
      left: '50%',
      transform: 'translate(-50%, 5px)',
      padding: '5px 10px',
      backgroundColor: 'var(--color-accent-green)',
      color: '#fff',
      borderRadius: 5,
      fontSize: '0.8rem',
      whiteSpace: 'nowrap',
      zIndex: 1,
    }}
    >
      <span>{text}</span>
    </div>
  );
}

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
};

function CopyBtn({
  className = '',
  textToCopy,
  tooltipText = 'Copié !',
  htmlTitle = 'Copier le lien',
  size = 32,
}) {
  const [copied, setCopied] = useState(false);

  const handleClick = useCallback(() => {
    navigator.clipboard.writeText(textToCopy);
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  }, [textToCopy]);

  const color = useMemo(
    () => (copied
      ? 'var(--color-accent-green)'
      : 'var(--color-secondary)'),
    [copied],
  );

  const icon = useMemo(() => (copied ? 'etat-lieux-ok' : 'etat-lieux'), [copied]);

  return (
    <button
      type="button"
      className={className}
      onClick={handleClick}
      title={htmlTitle}
      style={{
        backgroundColor: 'transparent',
        border: 'none',
        padding: 0,
        font: 'inherit',
        color: 'inherit',
        cursor: 'pointer',
      }}
    >
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      }}
      >
        {copied && <Tooltip text={tooltipText} />}
        <Picto
          icon={icon}
          width={size}
          color={color}
        />
      </div>
    </button>
  );
}

CopyBtn.propTypes = {
  className: PropTypes.string,
  textToCopy: PropTypes.string.isRequired,
  htmlTitle: PropTypes.string,
  tooltipText: PropTypes.string,
  size: PropTypes.number,
};

export default CopyBtn;
