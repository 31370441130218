import { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

// Components
import { Button, Picto } from 'ui-library-unlocker';
import WithLogin from '../../components/templates/WithLogin/WithLogin';
import RegisterForm from '../../components/organisms/forms/RegisterForm/RegisterForm';
import DiscoverLink from '../../components/atoms/DiscoverLink/DiscoverLink';

// Hooks
import useDocumentTitle from '../../hooks/useDocumentTitle';

// Styles
import styles from './Register.module.scss';

// Constants
const STEPS = {
  LANDING: 'LANDING',
  TENANT_FORM: 'TENANT_FORM',
  OWNER_FORM: 'OWNER_FORM',
  PRO_FORM: 'PRO_FORM',
};
const SELECTABLES = [STEPS.TENANT_FORM, STEPS.OWNER_FORM, STEPS.PRO_FORM];

function RoleSelection({ setRegisterStep }) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const getButtonVariant = useCallback((step) => {
    switch (step) {
      case STEPS.TENANT_FORM:
        return 'secondary';
      case STEPS.OWNER_FORM:
        return 'primary';
      case STEPS.PRO_FORM:
        return 'primary';
      default:
        return 'primary';
    }
  }, []);

  return (
    <div className={styles.userTypes}>
      {SELECTABLES.map((step) => (
        <div key={step} className={styles.userSection}>
          <p className={styles.userTitle}>
            {t(`register.${step}.title`)}
          </p>
          <div className={styles.userCtas}>
            <Button
              onClick={() => setRegisterStep(step)}
              size="large"
              variant={getButtonVariant(step)}
              blockLevel={isMobile}
              label={t(`register.${step}.signUp`)}
            />
          </div>
        </div>
      ))}
      <p className={styles.haveAccount}>
        {t('register.alreadyHaveAccount')}
        <Link to="/login">
          {t('register.signIn')}
        </Link>
      </p>
    </div>
  );
}

RoleSelection.propTypes = {
  setRegisterStep: PropTypes.func.isRequired,
};

function RegisterView() {
  const { t } = useTranslation();
  const { hash } = useLocation();

  useDocumentTitle(t('register.browserTitle'));

  const [registerStep, setRegisterStep] = useState(STEPS.LANDING);

  const renderContentHeading = useCallback(() => (
    <>
      <h1 className={styles.title}>
        {t('register.title')}
      </h1>
      <Picto
        width={196}
        color="var(--color-secondary)"
        icon="trait-login-title"
      />
      <p className={styles.subTitle}>
        {t('register.subTitle')}
      </p>
    </>
  ), []);

  const renderContentLeft = useCallback(() => (
    <div>
      <div className={styles.contentLeft}>
        The key to simplicity
        <Picto
          className={styles.circle}
          width={204}
          icon="rond-1"
        />
        <Picto
          className={styles.line1}
          width={383}
          color="var(--color-secondary)"
          icon="trait-login-1"
        />
        <Picto
          className={styles.line2}
          width={296}
          color="var(--color-secondary)"
          icon="trait-login-2"
        />
      </div>
    </div>
  ), []);

  const formType = useMemo(() => (hash === '#resend-code' ? 'resend-code' : 'register'), [hash]);

  const viewToDisplay = useMemo(() => {
    if (formType === 'resend-code') {
      return (
        <RegisterForm
          label={t('register.OWNER_FORM_FORM.title')}
          action={t('register.OWNER_FORM_FORM.action')}
          registrationType="OWNER_FORM"
        />
      );
    }
    if (registerStep === STEPS.LANDING) {
      return (
        <RoleSelection setRegisterStep={setRegisterStep} />
      );
    }
    return (
      <RegisterForm
        label={t(`register.${registerStep}.title`)}
        action={t(`register.${registerStep}.action`)}
        registrationType={registerStep}
      />
    );
  }, [registerStep, formType, t, setRegisterStep]);

  return (
    <WithLogin
      contentLeft={renderContentLeft()}
      contentHeading={renderContentHeading()}
    >
      {viewToDisplay}
      <div className="m-t-30 m-b-30 text-center">
        <DiscoverLink />
      </div>
    </WithLogin>
  );
}

export default RegisterView;
