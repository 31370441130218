import React from 'react';
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

// Components
import { utils, TextInput, Button } from 'ui-library-unlocker';

// Services
import { forgotUserPassword } from '../../../../services/identity';

// Utils
import forgotPasswordSchema, { forgotPasswordInitialValues } from '../../../../utils/forms/forgotPasswordSchema';
import { displayError, isFieldValid } from '../../../../utils/forms/form';

// Styles
import styles from './RequestPwdChangeForm.module.scss';

function RequestPwdChangeForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const forgotPasswordMutation = useMutation({
    mutationFn: forgotUserPassword,
    onSuccess: () => {
      utils.toast.success(t('forgotPassword.form.success'));
    },
    onError: (error) => {
      switch (error?.response?.status) {
        case 400: {
          if (error.response.data?.message === 'IDENTITY_ACCESS_FORGOT_PASSWORD_USER_NOT_FOUND') {
            utils.toast.error(t('login.form.errorResponses.ERR_IDENTITY_ACCESS_FORM_ERROR_001'));
          } else {
            utils.toast.error(t('global.form.errors.generic'));
          }
          break;
        }
        case 417: {
          utils.toast.error(t('forgotPassword.form.accountUnconfirmed'));
          navigate('/register#resend-code');
          break;
        }
        default:
          utils.toast.error(t('global.form.errors.generic'));
          break;
      }
    },
  });

  const forgotPasswordFormik = useFormik({
    initialValues: forgotPasswordInitialValues,
    validationSchema: forgotPasswordSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: forgotPasswordMutation.mutate,
  });

  return (
    <form onSubmit={forgotPasswordFormik.handleSubmit}>
      <p className={styles.forgotPasswordTitle}>
        {t('forgotPassword.title')}
      </p>
      <TextInput
        type="text"
        id="username"
        name="username"
        label={t('login.form.username')}
        error={displayError(t, forgotPasswordFormik, 'username')}
        valid={isFieldValid(forgotPasswordFormik, 'username')}
        onChange={forgotPasswordFormik.handleChange}
        onBlur={forgotPasswordFormik.handleBlur}
        value={forgotPasswordFormik.values.username}
        info={t('forgotPassword.form.usernameHelp')}
      />
      <p className={styles.forgotPassword}>
        <Link to="/login">
          {t('forgotPassword.backToLogin')}
        </Link>
      </p>
      <Button
        type="submit"
        className="m-t-30 center-block"
        size="large"
        label={t('global.form.submit')}
        loading={forgotPasswordMutation.isLoading}
      />
    </form>
  );
}

export default RequestPwdChangeForm;
