import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Picto, utils } from 'ui-library-unlocker';

import styles from './FileInput.module.scss';

function FileInput({
  className = null,
  label,
  onChange = () => {},
  help = null,
  value = null,
  accept = '*',
  actionElement = null,
  ...props
}) {
  const { t } = useTranslation();

  const renderInput = useCallback(() => (
    <input
      {...props}
      accept={accept}
      onChange={(e) => {
        const fileSelected = e.target.files[0];
        const acceptedTypes = accept.split(',');
        const { type: fileType } = fileSelected;

        if (!acceptedTypes.includes(fileType)) {
          utils.toast.error(t('global.form.errors.file.invalidType'));
          return;
        }
        if (fileSelected.size === 0) {
          utils.toast.error(t('global.form.errors.file.invalid'));
          return;
        }
        onChange(e);
      }}
      className={styles.fileInput}
      type="file"
    />
  ), [accept, onChange, props, t]);

  return (
    <div className={utils.cn([className, styles.inputWrapper])}>
      {actionElement
        ? (
          <div>
            <label htmlFor={props.id || props.name}>
              {actionElement}
            </label>
            {renderInput()}
          </div>
        )
        : (
          <>
            {!value ? (
              <>
                <label className={styles.fileInputLabel} htmlFor={props.id || props.name}>
                  <Button className={styles.fileInputBtn} icon="plus" variant="primary" />
                  {label}
                </label>
                {renderInput()}
              </>
            ) : (
              <div className={styles.fileInputSelected}>
                <div className={styles.fileInputSelectedName}>{value?.name}</div>
                <Picto
                  onClick={() => {
                    onChange(null);
                  }}
                  className={styles.fileInputSelectedIcon}
                  icon="close"
                  width={13}
                />
              </div>
            )}
            {help && <div className={styles.fileInputHelp}>{help}</div>}
          </>
        )}
    </div>
  );
}

FileInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  help: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  accept: PropTypes.string,
  actionElement: PropTypes.node,
};

export default FileInput;
