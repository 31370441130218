/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@tanstack/react-query';
import { useMatch, useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';

// Components
import {
  Button,
  Tag,
  utils,
  UnlockerLoader,
  Table,
  Picto,
} from 'ui-library-unlocker';
import Modal from '../../molecules/Modal/Modal';
import Tooltip from '../../atoms/Tooltip/Tooltip';
import TableOptions from '../../atoms/TableOptions/TableOptions';

// Services
import { submitPaymentAccountEnrolment } from '../../../services/paymentAccount';

// Utils
import { showModal, hideModal } from '../../../utils/modal';
import { getAccountEnrolmentVariant } from '../../../utils/variants';

// Constants
import { ENROLMENT_STATUS, ACCOUNT_ENROLMENT } from '../../../utils/constants';

import styles from './PersonPaymentAccount.module.scss';

const confirmEzynessModalId = 'confirm-ezyness-submit';

function AccountCompaniesList({
  companies,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const columns = useMemo(() => ([
    {
      header: t('paymentAccount.table.columns.name'),
      accessorKey: 'companyName',
      minSize: 150,
    },
    {
      header: t('paymentAccount.table.columns.enrolmentStatus'),
      accessorKey: 'enrolmentStatus',
      size: 125,
      enableSorting: false,
      cell: ({ row: { original: { enrolmentStatus } } }) => (
        <Tag
          variant={getAccountEnrolmentVariant(enrolmentStatus)}
          label={t(`paymentAccount.table.enrolmentStatus.${enrolmentStatus}`)}
        />
      ),
    },
    {
      header: t('paymentAccount.table.columns.submissionDate'),
      accessorKey: 'submissionDate',
      size: 125,
      cell: ({ row: { original: { submissionDate } } }) => (
        <div>
          <span>{submissionDate ? format(new Date(submissionDate), 'dd MMM yyyy', { locale: fr }) : '-'}</span>
        </div>
      ),
    },
    {
      header: t('paymentAccount.table.columns.validationDate'),
      accessorKey: 'validationDate',
      size: 150,
      cell: ({ row: { original: { validationDate } } }) => (
        <div>
          <span>{validationDate ? format(new Date(validationDate), 'dd MMM yyyy', { locale: fr }) : '-'}</span>
        </div>
      ),
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 10,
      enableSorting: false,
      cell: ({ row }) => (
        <div className={styles.seeMore}>
          <Picto
            id={`more-option-company-payment-account-${row?.original?.uid}`}
            icon="more"
            width={24}
            onClick={(e) => {
              e.stopPropagation();
              setTooltipOpen(row?.original?.uid);
            }}
            color="var(--color-secondary)"
          />
          <Tooltip
            isOpen={tooltipOpen === row?.original?.uid}
            anchorId={`more-option-company-payment-account-${row?.original?.uid}`}
            place="bottom"
            type="dark"
            effect="solid"
          >
            <TableOptions
              options={[
                {
                  id: 'edit',
                  label: t('paymentAccount.options.goToDetails'),
                  icon: <Picto icon="edit" width={24} color="var(--color-primary)" />,
                  onHoverIcon: <Picto icon="edit" width={24} color="var(--color-white)" />,
                  onClick: (e) => {
                    e.stopPropagation();
                    setTooltipOpen(null);
                    navigate(`/company/${row?.original?.userUid}#payment-account`);
                  },
                },
              ]}
            />
          </Tooltip>
        </div>
      ),
    },
  ]), [t, tooltipOpen, navigate]);

  return (
    <div
      className="m-t-30"
      onClick={() => setTooltipOpen(null)}
      onKeyDown={() => setTooltipOpen(null)}
      role="presentation"
    >
      <Table
        fullWidth
        columns={columns}
        data={companies}
      />
    </div>
  );
}

AccountCompaniesList.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

function PersonPaymentAccount({
  entity,
  dataQuery,
  entityOnboardingStatus = null,
}) {
  const { t } = useTranslation();

  const match = useMatch(`/${entity}/:id`);

  const {
    submissionDate = '',
    validationDate = '',
    enrolmentStatus: accountEnrolmentStatus,
    companies = [],
  } = dataQuery?.data?.data || {};

  const submitToEzynessMutation = useMutation({
    mutationFn: submitPaymentAccountEnrolment,
    onSuccess: ({ response, status }) => {
      const s = status || response?.status;
      switch (s) {
        case 204:
          utils.toast.success(t('profile.paymentAccount.success'));
          hideModal(confirmEzynessModalId);
          break;
        default:
          break;
      }
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 401: {
            utils.toast.error(t('global.form.errors.forbidden'));
            break;
          }
          case 424: {
            switch (err?.response?.data?.message) {
              case 'PAYMENT_TAGPAY_IS_IN_PLS_EXCEPTION': {
                utils.toast.error(t('profile.paymentAccount.errorTagPay'));
                break;
              }
              case 'PAYMENT_TAGPAY_SERVER_EXCEPTION': {
                const { errors } = err?.response?.data || {};
                utils.toast.error(
                  t('profile.paymentAccount.errorTagPay')
                  + Array.isArray(errors) ? errors.map((msg) => `\n${msg}`) : '',
                );
                break;
              }
              case 'PAYMENT_TAGPAY_BAD_REQUEST_EXCEPTION': {
                const { errors } = err?.response?.data || {};
                utils.toast.error(t('profile.paymentAccount.badRequestEzyness', {
                  fields: errors ? errors.entries(([key, value]) => `\n${key} => ${value}`) : 'unknown',
                }));
                break;
              }
              case 'PAYMENT_TAGPAY_RECIPIENT_EXCEPTION': {
                utils.toast.error(t('profile.paymentAccount.errorTagPayRecipient'));
                break;
              }
              default:
                utils.toast.error(t('global.form.errors.global'));
                break;
            }
            break;
          }
          default:
            utils.toast.error(t('global.form.errors.global'));
            break;
        }
      }
    },
  });

  const onboardingStatus = useMemo(
    () => {
      if (submitToEzynessMutation?.isSuccess) return ENROLMENT_STATUS.ENROLMENT_PENDING;
      if (!entityOnboardingStatus) return null;
      return entityOnboardingStatus;
    },
    [entityOnboardingStatus, submitToEzynessMutation?.isSuccess],
  );

  const enrolmentStatus = useMemo(() => {
    if (!onboardingStatus) return null;
    switch (onboardingStatus) {
      case ENROLMENT_STATUS.PENDING:
        return {
          variant: 'tertiary',
          state: '1',
        };
      case ENROLMENT_STATUS.PROFILE_DONE:
        return {
          variant: 'primary',
          state: '1',
        };
      case ENROLMENT_STATUS.ENROLMENT_REQUESTED:
        return {
          variant: 'tertiary',
          state: '2',
        };
      case ENROLMENT_STATUS.ENROLMENT_PENDING:
        return {
          variant: 'tertiary',
          state: '3',
        };
      case ENROLMENT_STATUS.ENROLMENT_CHALLENGED:
        return {
          variant: 'tertiary',
          state: '4',
        };
      case ENROLMENT_STATUS.ENROLMENT_DENIED:
        return {
          variant: 'error',
          state: '4',
        };
      case ENROLMENT_STATUS.COMPLETED:
      case ENROLMENT_STATUS.ENROLMENT_BYPASSED:
        return {
          variant: 'success',
          state: '5',
        };
      case ENROLMENT_STATUS.BLOCKED:
        return {
          variant: 'error',
          state: '6',
        };
      default:
        return {
          variant: 'error',
        };
    }
  }, [onboardingStatus]);

  const optimisticAccountEnrolmentStatus = useMemo(() => {
    if (submitToEzynessMutation?.isSuccess) return ACCOUNT_ENROLMENT.SUBMITTED;
    return accountEnrolmentStatus;
  }, [accountEnrolmentStatus, submitToEzynessMutation?.isSuccess]);

  const optimisticSubmissionDate = useMemo(() => {
    if (submitToEzynessMutation?.isSuccess) return new Date();
    return submissionDate;
  }, [submissionDate, submitToEzynessMutation?.isSuccess]);

  const handleSubmit = useCallback(() => {
    if (!match?.params?.id) return utils.toast.error(t('global.form.errors.global'));
    return submitToEzynessMutation.mutate(match?.params?.id);
  }, [match?.params?.id, submitToEzynessMutation]);

  if (!enrolmentStatus || dataQuery?.isLoading) return <UnlockerLoader size={200} align="left" />;

  return (
    <div className="m-t-20">
      <Tag
        className="m-r-5"
        label={t(`user.tabs.paymentAccount.labelStatus.${onboardingStatus}`)}
        variant={enrolmentStatus?.variant}
        size="large"
      />
      <div className="m-t-40">
        <Button
          size="large"
          disabled={['1', '3', '5', '6'].includes(enrolmentStatus?.state)}
          onClick={() => showModal(confirmEzynessModalId)}
        >
          {t('user.tabs.paymentAccount.submitBtn')}
        </Button>
        <div className="m-t-40">
          <div>
            <span>{t('user.tabs.paymentAccount.accountEnrolmentStatus')}</span>
            <Tag
              className="m-l-10"
              variant={getAccountEnrolmentVariant(optimisticAccountEnrolmentStatus)}
              label={t(`paymentAccount.table.enrolmentStatus.${optimisticAccountEnrolmentStatus}`)}
            />
          </div>
          {optimisticSubmissionDate && (
          <div className="m-t-20">
            <span>
              <Trans
                i18nKey="user.tabs.paymentAccount.enrolmentSent"
                values={{ date: format(new Date(optimisticSubmissionDate), 'dd/MM/yyyy') }}
              />
            </span>
          </div>
          )}
          {validationDate && (
          <div className="m-t-20">
            <span>
              <Trans
                i18nKey="user.tabs.paymentAccount.enrolmentComplete"
                values={{ date: format(new Date(validationDate), 'dd/MM/yyyy') }}
              />
            </span>
          </div>
          )}
        </div>
      </div>
      {Array.isArray(companies) && companies.length > 0 && (
      <>
        <h2 className="m-t-40">{t('company.title')}</h2>
        <AccountCompaniesList companies={companies} />
      </>
      )}
      <Modal
        id={confirmEzynessModalId}
        title={t('profile.paymentAccount.confirmMessage')}
        size="large"
        onClose={() => {
          hideModal(confirmEzynessModalId);
        }}
      >
        <Button
          className="m-t-40 center-block"
          size="large"
          loading={submitToEzynessMutation?.isLoading}
          onClick={handleSubmit}
        >
          {t('global.validate')}
        </Button>
      </Modal>
    </div>
  );
}

PersonPaymentAccount.propTypes = {
  entity: PropTypes.string.isRequired,
  dataQuery: PropTypes.shape({
    data: PropTypes.shape({
      data: PropTypes.shape({
        submissionDate: PropTypes.string,
        validationDate: PropTypes.string,
      }),
    }),
  }).isRequired,
  entityOnboardingStatus: PropTypes.string,
};

export default PersonPaymentAccount;
