/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import { useMemo } from 'react';

// Components
import {
  PieChart, Pie, Cell, Label,
} from 'recharts';

function CustomizedLabel({
  value = 0,
  viewBox = {},
  fontSize = 26,
  subtext = '',
}) {
  const { cx, cy } = viewBox;

  return (
    <text
      fill="#000"
      textAnchor="middle"
      dominantBaseline="middle"
      x={cx}
      y={cy - (subtext ? 3 : 0)}
    >
      <tspan alignmentBaseline="middle" fontSize={fontSize} fontWeight="bold">
        {value}
        %
      </tspan>
      {subtext && (
        <tspan x={cx} dy={14} fontSize={10} fill="var(--color-primary-500)">
          {subtext}
        </tspan>
      )}
    </text>
  );
}

CustomizedLabel.propTypes = {
  value: PropTypes.number,
  viewBox: PropTypes.shape(),
  fontSize: PropTypes.number,
  subtext: PropTypes.string,
};

function PercentCircle({
  total = 100,
  value = 0,
  size = 200,
  color = 'var(--color-secondary)',
  restColor = '',
  showRestPercent = false,
  subtext = '',
}) {
  const data = useMemo(() => {
    const rest = total - value;
    return [
      { name: 'current', value, color: 'rgb(54, 162, 235)' },
      { name: 'rest', value: rest, color: 'transparent' },
    ];
  }, [total, value]);

  const center = useMemo(() => size / 2, [size]);

  const radius = useMemo(() => ({
    thin: {
      inner: center * 0.90,
      outer: center * 0.96,
    },
    bold: {
      inner: center * 0.86,
      outer: center,
    },
  }), [center]);

  const fontSize = useMemo(() => size * 0.225, [size]);

  const isAllZero = useMemo(() => total === 0 && value === 0, [data]);

  const percent = useMemo(() => {
    if (isAllZero) return 0;
    if (showRestPercent) {
      const rest = total - value;
      return Math.floor((rest / total) * 100);
    }
    return Math.floor((value / total) * 100);
  }, [value, total, showRestPercent, isAllZero]);

  const replacementData = useMemo(() => [
    { name: 'replacement', value: 100 },
  ], []);

  if (isAllZero) {
    return (
      <PieChart width={size} height={size}>
        <Pie
          data={replacementData}
          cx="50%"
          cy="50%"
          startAngle={90}
          endAngle={-270}
          innerRadius={radius.thin.inner}
          outerRadius={radius.thin.outer}
          fill="var(--color-primary-300)"
          isAnimationActive={false}
          strokeWidth={0}
        >
          <Label
            position="center"
            fontSize={fontSize}
            content={<CustomizedLabel value={percent} subtext={subtext} />}
          />
        </Pie>
      </PieChart>
    );
  }

  return (
    <PieChart width={size} height={size}>
      {!restColor && (
        <Pie
          data={[data[1]]}
          cx="50%"
          cy="50%"
          startAngle={90}
          endAngle={-270}
          innerRadius={radius.thin.inner}
          outerRadius={radius.thin.outer}
          fill="var(--color-primary-300)"
          isAnimationActive={false}
          strokeWidth={0}
        />
      )}
      <Pie
        data={data}
        dataKey="value"
        cx="50%"
        cy="50%"
        startAngle={90}
        endAngle={-270}
        innerRadius={radius.bold.inner}
        outerRadius={radius.bold.outer}
        cornerRadius={restColor ? 0 : 20}
      >
        {data.map((_, index) => (
          <Cell
            key={`cell-${index}`}
            fill={index === 0 ? color : restColor || 'transparent'}
            strokeWidth={0}
          />
        ))}
        <Label
          position="center"
          fontSize={fontSize}
          content={<CustomizedLabel value={percent} subtext={subtext} />}
        />
      </Pie>
    </PieChart>
  );
}

PercentCircle.propTypes = {
  total: PropTypes.number,
  value: PropTypes.number,
  size: PropTypes.number,
  color: PropTypes.string,
  restColor: PropTypes.string,
  showRestPercent: PropTypes.bool,
  subtext: PropTypes.string,
};

export default PercentCircle;
