/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Components
import {
  TextInput,
  utils,
  ToggleInput,
  DateInput,
  Message,
  Button,
  Picto,
} from 'ui-library-unlocker';

// Utils
import { displayError } from '../../../utils/forms/form';

import styles from './PropertyInvoices.module.scss';

// TODO: handle charge, amount
function PropertyInvoiceSplit({
  totalRent = 0,
  formik,
}) {
  const { t } = useTranslation();

  const {
    withRentDistribution,
    amount,
    splits,
  } = formik.values || {};

  const initialSplits = useMemo(() => {
    const nextMonth = new Date();
    nextMonth.setDate(1);
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    const monthAmount = Math.min(utils.euroToCents(amount), totalRent);
    return [
      {
        amount: utils.centsToEuro(monthAmount),
        date: nextMonth,
      },
    ];
  }, [totalRent, amount]);

  useEffect(() => {
    if (withRentDistribution) {
      const thisMonth = new Date();
      thisMonth.setDate(1);
      formik.setFieldValue('splits', initialSplits);
    } else {
      formik.setFieldValue('splits', []);
    }
  }, [withRentDistribution, initialSplits]);

  const getMinDate = useCallback((index) => {
    if (index === 0) {
      const nextMonth = new Date();
      nextMonth.setDate(1);
      nextMonth.setMonth(nextMonth.getMonth() + 1);
      return nextMonth;
    }
    const previousDate = new Date(splits[index - 1].date);
    previousDate.setMonth(previousDate.getMonth() + 1);
    return previousDate;
  }, [splits]);

  const handleAddSplitLine = useCallback(() => {
    const lastDate = new Date(splits[splits.length - 1].date);
    lastDate.setMonth(lastDate.getMonth() + 1);
    formik.setFieldValue('splits', [
      ...splits,
      {
        amount: null,
        date: lastDate,
      },
    ]);
  }, [splits, formik]);

  const handleRemoveSplitLine = useCallback((index) => {
    const newSplits = [...splits];
    newSplits.splice(index, 1);
    formik.setFieldValue('splits', newSplits);
  }, [splits, formik]);

  return (
    <>
      <ToggleInput
        id="withRentDistribution"
        name="withRentDistribution"
        className="m-t-25 m-l-10"
        label={t('property.tabs.invoices.fields.withRentDistribution')}
        checked={!!withRentDistribution}
        onChange={(check) => formik.setFieldValue('withRentDistribution', check)}
      />
      {withRentDistribution && (
        <div className={styles.splitLinesContainer}>
          {splits.map((split, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className={styles.splitLine}>
              <DateInput
                id={`splits[${index}].date`}
                name={`splits[${index}].date`}
                label={t('property.tabs.invoices.fields.splitDate')}
                value={split.date}
                onChange={(date) => formik.setFieldValue(`splits[${index}].date`, date)}
                error={displayError(t, formik, `splits[${index}].date`)}
                full
                portalId={styles.datepickerMenu}
                monthYearPicker
                minDate={getMinDate(index)}
              />
              <TextInput
                type="number"
                id={`splits[${index}].amount`}
                name={`splits[${index}].amount`}
                className={styles.splitAmount}
                min="0"
                step="0.01"
                label={t('property.tabs.invoices.fields.splitAmount')}
                value={split.amount}
                onChange={(e) => formik.setFieldValue(`splits[${index}].amount`, e.target.value)}
                icon="euro"
                iconSize={20}
                iconColor="var(--color-primary-300)"
                error={displayError(t, formik, `splits[${index}].amount`, null, {
                  errorIfUntouched: true,
                  errorConcatValues: {
                    maxValue: utils.centsToEuro(totalRent),
                  },
                })}
              />
              <div className={styles.splitRemoveContainer}>
                {index > 0 && (
                  <Picto
                    className={styles.splitRemove}
                    icon="trash"
                    width={25}
                    onClick={() => handleRemoveSplitLine(index)}
                  />
                )}
              </div>
            </div>
          ))}
          <div className={styles.splitBtnContainer}>
            <Button
              className="m-t-10"
              icon="plus"
              onClick={handleAddSplitLine}
              size="small"
            />
          </div>
        </div>
      )}
      {displayError(t, formik, 'splitTotal') && (
        <Message
          variant="error"
          className="m-t-20"
          content={t(formik.errors.splitTotal)}
        />
      )}
    </>
  );
}

PropertyInvoiceSplit.propTypes = {
  totalRent: PropTypes.number,
  formik: PropTypes.shape({
    values: PropTypes.shape({
      amount: PropTypes.number,
      withRentDistribution: PropTypes.bool,
    }).isRequired,
    setFieldValue: PropTypes.func.isRequired,
    errors: PropTypes.shape({
      splitTotal: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default PropertyInvoiceSplit;
