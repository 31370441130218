import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import CheckboxInput from '../../atoms/CheckboxInput/CheckboxInput';

import cn from '../../../utils/cn';

import styles from './CheckboxGroup.module.scss';

const CheckboxGroup = ({
  name,
  options,
  display = 'vertical',
  className = '',
  value = null,
  itemsPerRow = 4,
  onChange,
  error = null,
  disabled = false,
}) => {
  const handleCheckboxChecked = useCallback((n) => {
    const newValue = value?.slice(0) || [];
    const checked = value?.find((v) => v === n) !== undefined;

    if (checked) {
      const index = newValue.indexOf(n);
      newValue.splice(index, 1);
    } else {
      newValue.push(n);
    }
    onChange(newValue);
  }, [value, onChange]);

  return (
    <>
      <div
        className={cn([
          styles.checkboxGroup,
          display === 'horizontal'
            ? styles.checkboxGroupHorizontal
            : styles.checkboxGroupVertical,
          className,
        ])}
        style={{
          gridTemplateColumns: itemsPerRow ? `repeat(${itemsPerRow}, 1fr)` : null,
        }}
      >
        {options.map((option) => (
          <CheckboxInput
            key={option.value}
            id={`${name}-${option.value}`}
            name={name}
            label={option.label}
            checked={value?.find((v) => v === option.value)}
            onChange={() => handleCheckboxChecked(option.value)}
            disabled={disabled}
          />
        ))}
      </div>
      {error && <div className={styles.error}>{error}</div>}
    </>
  );
};

CheckboxGroup.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  display: PropTypes.oneOf(['horizontal', 'vertical']),
  className: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.shape()),
  itemsPerRow: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.bool,
};

export default CheckboxGroup;
