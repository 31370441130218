/* eslint-disable no-restricted-syntax */
import {
  cloneElement,
  useCallback,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import TagManager from 'react-gtm-module';

// Components
import {
  Button,
  utils,
  Message,
} from 'ui-library-unlocker';
import ProfileDocumentsList from '../../../components/organisms/DocumentList/ProfileDocumentsList/ProfileDocumentsList';
import Modal from '../../../components/molecules/Modal/Modal';

// Services
import { requestBankingEnrolment } from '../../../services/profile';

// Hooks
import { useOnboardingContext } from '../../../store/onboardingContext';
import { useAppContext } from '../../../store/context';

// Utils
import { hideModal, showModal } from '../../../utils/modal';
import { checkIfSendProfileValidationRequest } from '../../../utils/user';

// Constants
import { ENROLMENT_STATUS, DOC_TYPES } from '../../../utils/constants';

import styles from './OnboardingTunnelProfileDocs.module.scss';

const SEND_VALIDATION_REQUEST_CONFIRM_MODAL_ID = 'send-validation-request-confirm-modal';

function OnboardingTunnelProfileDocs({
  children: footer = null,
  handleStep,
  endOnboardingMutation,
}) {
  const { t } = useTranslation();
  const {
    contextOnboarding: { documentsProfileData },
    refetchDocumentsProfile,
    refetchMe,
    onboardingRole: {
      isOwner, isTenant,
    },
    enrolmentStatus,
  } = useOnboardingContext();
  const { context: { user, uiBuilders } } = useAppContext();

  const [missingDocuments, setMissingDocuments] = useState(null);

  const enrolmentMutation = useMutation({
    mutationFn: () => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'sent_validation_request',
          entityType: 'profile',
        },
      });
      return requestBankingEnrolment();
    },
    onSuccess: () => {
      hideModal(SEND_VALIDATION_REQUEST_CONFIRM_MODAL_ID);
      refetchMe();
      if (isOwner) return endOnboardingMutation.mutate();
      return handleStep(1);
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 400: {
            utils.toast.error(t('profile.paymentAccount.errorMissingDocuments'));

            if (err?.response?.data?.message === 'PERSON_ENROLMENT_DOCUMENT_MISSING') {
              const { errors } = err?.response?.data || {};
              TagManager.dataLayer({
                dataLayer: {
                  event: 'incomplete_validation_request',
                  entityType: 'profile',
                },
              });
              if (errors) {
                const missing = Object.keys(errors).map((key) => errors[key]);
                setMissingDocuments(missing || null);
              }
            }
            break;
          }
          case 409: {
            utils.toast.error(t('profile.paymentAccount.errorInProgress'));
            break;
          }
          default:
            break;
        }
        hideModal(SEND_VALIDATION_REQUEST_CONFIRM_MODAL_ID);
      }
    },
  });

  const getDocumentType = useCallback(() => {
    if (uiBuilders == null) return {};
    return uiBuilders['/person/ui']?.documentTypePPH;
  }, [uiBuilders]);

  const documentsList = useMemo(() => documentsProfileData?.data?.collection || [], [documentsProfileData]);

  const canSendValidationRequest = useMemo(() => {
    if (documentsList.length < 1) return false;
    if (!enrolmentStatus) return false;
    return [
      ENROLMENT_STATUS.PROFILE_DONE,
      ENROLMENT_STATUS.ENROLMENT_DENIED,
      ENROLMENT_STATUS.ENROLMENT_CHALLENGED,
    ].includes(enrolmentStatus);
  }, [enrolmentStatus, documentsList]);

  const mustSendValidationRequest = checkIfSendProfileValidationRequest();

  const docStepsForValidation = useMemo(() => [
    {
      docType: 'PPHIdentityProof',
    },
    {
      docType: 'PPHAddressProof',
    },
    mustSendValidationRequest ? {
      docType: 'PPHIban',
    } : null,
  ].filter(Boolean), [t, mustSendValidationRequest]);

  const handleSubmit = useCallback(() => {
    if (!isTenant && canSendValidationRequest && mustSendValidationRequest) {
      return showModal(SEND_VALIDATION_REQUEST_CONFIRM_MODAL_ID);
    }
    const missingDocs = [];
    for (const docStep of docStepsForValidation) {
      if (!docStep.optional && !documentsList.find((doc) => doc.type === DOC_TYPES[docStep.docType])) {
        missingDocs.push(DOC_TYPES[docStep.docType]);
      }
    }
    if (missingDocs.length > 0) {
      return setMissingDocuments(missingDocs);
    }
    return handleStep(1);
  }, [
    handleStep,
    isTenant,
    canSendValidationRequest,
    mustSendValidationRequest,
    documentsList,
    docStepsForValidation,
  ]);

  return (
    <>
      <div className={styles.profile3}>
        <h2 className="m-b-10">
          {t('onboarding.steps.profile3.title')}
        </h2>
        <ProfileDocumentsList
          documentsData={documentsList}
          refetchDocuments={refetchDocumentsProfile}
          userUID={user?.username || ''}
        />
        {missingDocuments?.length > 0 && (
          <Message
            className={utils.cn(['m-t-30', styles.missingBlock])}
            icon={null}
            variant="alert"
            content={(
              <>
                <p className="p-2-700 m-b-10">
                  {t('profile.documents.missingDocuments')}
                </p>
                <ul className={styles.missingList}>
                  {missingDocuments.map((item) => (
                    <li key={item}>
                      {getDocumentType()[item.toLowerCase()]}
                    </li>
                  ))}
                </ul>
              </>
            )}
          />
        )}
      </div>
      {footer && cloneElement(footer, {
        onSubmit: handleSubmit,
        isLoading: enrolmentMutation.isLoading || endOnboardingMutation.isLoading,
        isDisabled: mustSendValidationRequest && documentsList.length < 1,
      })}

      {/* Send validation request confirmation modal */}
      <Modal
        id={SEND_VALIDATION_REQUEST_CONFIRM_MODAL_ID}
        title={t('profile.documents.confirmSendValidation')}
        size="large"
        onClose={() => hideModal(SEND_VALIDATION_REQUEST_CONFIRM_MODAL_ID)}
      >
        <Button
          className="m-t-40 center-block"
          size="large"
          loading={enrolmentMutation.isLoading}
          onClick={enrolmentMutation.mutate}
        >
          {t('global.validate')}
        </Button>
      </Modal>
    </>
  );
}

OnboardingTunnelProfileDocs.propTypes = {
  children: PropTypes.node,
  handleStep: PropTypes.func.isRequired,
  endOnboardingMutation: PropTypes.shape({
    mutate: PropTypes.func,
    isLoading: PropTypes.bool,
  }).isRequired,
};

export default OnboardingTunnelProfileDocs;
