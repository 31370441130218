/* eslint-disable react/no-array-index-key */
import {
  useCallback, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useOutletContext } from 'react-router-dom';

// Components
import {
  ToggleInput,
} from 'ui-library-unlocker';
import QualityPicker from '../../../components/molecules/QualityPicker/QualityPicker';

// Constants
import { INVENTORY_ROOMS } from '../../../utils/constants';

// Styles
import styles from './InventoryRooms.module.scss';

function Equipment({
  label,
  id,
  value = null,
  onChange,
  disabled = false,
}) {
  const [isChecked, setIsChecked] = useState(!!value?.isChecked || !!value?.score);

  const handleCheck = useCallback((check) => {
    setIsChecked(check);
    if (!check) {
      onChange(null);
    }
  }, [onChange]);

  return (
    <div className="m-t-25">
      <div className={styles.equipmentLabel}>
        <ToggleInput
          id={`${id.replace('.', '-')}-toggle`}
          checked={isChecked}
          onChange={handleCheck}
          label={label}
          disabled={disabled}
        />
      </div>
      {isChecked && (
        <QualityPicker
          className="m-t-10"
          value={value?.score}
          onChange={onChange}
          disabled={disabled}
        />
      )}
    </div>
  );
}

Equipment.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

function EquipmentList({
  room,
  formikKey,
  roomIndex,
}) {
  const { formik, updateInventoryMutation, isReadOnly } = useOutletContext();

  const equipments = useMemo(() => (
    INVENTORY_ROOMS.find((roomItem) => roomItem.type === room.type)?.equipments
  ), [room.type]);

  const handleChange = useCallback((type, score) => {
    const index = room.equipments.findIndex((equipment) => equipment.type === type);
    if (index !== -1) {
      formik.setFieldValue(`${formikKey}.${index}`, {
        type, score,
      });
      updateInventoryMutation.mutate({
        ...formik.values,
        rooms: formik.values.rooms.map((roomItem, roomItemIndex) => {
          if (roomItemIndex === roomIndex) {
            return {
              ...roomItem,
              equipments: roomItem.equipments.map((equipment) => {
                if (equipment.type === type) {
                  return {
                    type,
                    score,
                  };
                }
                return equipment;
              }),
            };
          }
          return roomItem;
        }),
      });
    }
  }, [formik, formikKey, roomIndex, updateInventoryMutation]);

  if (equipments) {
    return Object.entries(equipments).map(([key, label]) => (
      <Equipment
        key={key}
        id={`${formikKey}-${key}`}
        label={label}
        value={room.equipments?.find((equipment) => equipment.type === key)}
        onChange={(value) => handleChange(key, value)}
        disabled={isReadOnly}
      />
    ));
  }

  return null;
}

EquipmentList.propTypes = {
  room: PropTypes.shape({
    type: PropTypes.string,
    equipments: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      score: PropTypes.number,
    })),
  }).isRequired,
  formikKey: PropTypes.string.isRequired,
  roomIndex: PropTypes.number.isRequired,
};

export default EquipmentList;
