/* eslint-disable consistent-return */
/* eslint-disable no-case-declarations */
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

// Components
import {
  Button,
  utils,
} from 'ui-library-unlocker';
import Modal from '../../../components/molecules/Modal/Modal';

// Hooks
import useResponsive from '../../../hooks/useResponsive';

// Utils
import { hideModal } from '../../../utils/modal';

// Services
import { updateInventory } from '../../../services/inventory';

import styles from './InventorySaveModal.module.scss';

function InventorySaveModal({
  idModal,
  onClose = () => {},
  formik,
  updateSavedTime,
  isSending,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { isMobile } = useResponsive();

  const draftMutation = useMutation({
    mutationFn: (data) => updateInventory(id, data),
    onSuccess: () => {
      handleClose();
      utils.toast.success(t('inventory.save.draft.success'));
      updateSavedTime(new Date());
    },
    onError: () => {
      utils.toast.error(t('global.form.errors.global'));
    },
  });

  const handleDraft = useCallback(() => {
    draftMutation.mutate(formik.values);
  }, [formik.values]);

  const handleClose = useCallback(() => {
    hideModal(idModal);
    onClose();
  }, [idModal]);

  const redirectToError = useCallback(() => {
    switch (Object.keys(formik.errors)[0]) {
      case 'date':
      case 'propertyUid':
      case 'leaseUid':
        navigate(`/inventory/${id}`);
        return handleClose();
      case 'electricalMeter':
      case 'gasMeter':
      case 'waterMeter':
        navigate(`/inventory/${id}/meters`);
        return handleClose();
      case 'entryKeys':
      case 'buildingKeys':
      case 'gateKeys':
      case 'garageKeys':
      case 'postalBoxKeys':
        navigate(`/inventory/${id}/keys`);
        return handleClose();
      case 'rooms':
        navigate(`/inventory/${id}/rooms`);
        return handleClose();
      case 'signers':
        navigate(`/inventory/${id}/signers`);
        return handleClose();
      default:
    }
  }, [formik.errors, navigate, id, handleClose]);

  const handleSendToSign = useCallback(() => {
    if (!formik.isValid) {
      if (!isMobile) {
        redirectToError();
      } else {
        handleClose();
      }
      setTimeout(() => {
        const errorElement = (
          document.querySelector('input[aria-invalid=true]')
          || document.querySelector('.input[aria-invalid=true]')
        );
        if (errorElement) {
          errorElement.scrollIntoView({ behavior: 'instant' });
        } else if (isMobile && Object.keys(formik.errors)[0] === 'signers') {
          document.getElementById('inventory-signers')?.scrollIntoView({ behavior: 'instant' });
          navigate('#inventory-signers');
        }
      }, 50);
    }
    formik.handleSubmit();
  }, [formik, redirectToError, isMobile, handleClose, navigate]);

  return (
    <Modal
      className={styles.modal}
      id={idModal}
      onClose={handleClose}
    >
      <div className={styles.modalContent}>
        <div className={styles.draft}>
          <h3 className={styles.title}>
            <span>{t('inventory.save.draft.title')}</span>
          </h3>
          <p>{t('inventory.save.draft.message')}</p>
          <Button
            onClick={handleDraft}
            loading={draftMutation.isLoading}
            variant="secondary"
            icon="save"
            iconSide="left"
            size="large"
          >
            {t('inventory.save.draft.button')}
          </Button>
        </div>
        <div className={styles.sign}>
          <h3 className={styles.title}>
            <span>{t('inventory.save.sign.title')}</span>
          </h3>
          <p>{t('inventory.save.sign.message')}</p>
          <Button
            onClick={handleSendToSign}
            loading={isSending}
            icon="sign"
            iconSide="left"
            size="large"
          >
            {t('inventory.save.sign.button')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

InventorySaveModal.propTypes = {
  idModal: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  formik: PropTypes.shape({
    values: PropTypes.shape({
      uid: PropTypes.string,
    }).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    errors: PropTypes.shape({}),
    isValid: PropTypes.bool,
  }).isRequired,
  updateSavedTime: PropTypes.func.isRequired,
  isSending: PropTypes.bool.isRequired,
};

export default InventorySaveModal;
