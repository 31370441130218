import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Picto } from 'ui-library-unlocker';

import styles from './WithLogin.module.scss';

function WithAuth({
  contentLeft = null,
  contentHeading = null,
  children,
}) {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const renderDesktop = useCallback(() => (
    <div className={styles.login}>
      <div className={styles.header}>
        <Link to="/register">
          <Picto width={160} fill="var(--color-white)" icon="logo-horizontal" />
        </Link>
      </div>
      <div className={styles.contentLeft}>
        {contentLeft}
      </div>
      <div className={styles.contentRight}>
        {contentHeading
          ? (
            <div className={styles.contentHeading}>
              {contentHeading}
            </div>
          )
          : null}
        {children}
      </div>
    </div>
  ), [contentLeft, children]);

  const renderMobile = useCallback(() => (
    <div className={styles.login}>
      <div className={styles.mobileContent}>
        <div className={styles.header}>
          <Link to="/register">
            <Picto width={160} fill="var(--color-white)" icon="logo-horizontal" />
          </Link>
        </div>
        {contentHeading
          ? (
            <div className={styles.contentHeading}>
              {contentHeading}
            </div>
          )
          : null}
        <div className={styles.content}>
          {children}
        </div>
      </div>
    </div>
  ), [contentLeft, children]);

  if (isMobile) {
    return renderMobile();
  } return renderDesktop();
}

WithAuth.propTypes = {
  contentLeft: PropTypes.node,
  contentHeading: PropTypes.node,
  children: PropTypes.node.isRequired,
};

export default WithAuth;
