import { useCallback, useMemo } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import {
  WithHeader,
  Button,
  utils,
} from 'ui-library-unlocker';

// Hooks
import useDocumentTitle from '../../hooks/useDocumentTitle';

function IbanStream() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  useDocumentTitle(t('ibanStream.browserTitle'));

  // eslint-disable-next-line max-len
  const pdfUrl = useMemo(() => `https://${process.env.REACT_APP_API_URL}/lease/public/leases/iban/stream/${token}`, [token]);

  const handleDownload = useCallback(async () => {
    try {
      const response = await fetch(pdfUrl);

      // Récupérer les headers
      const contentDisposition = response.headers.get('Content-Disposition');

      // Extraire le filename du header
      let fileName = 'rib_unlocker.pdf';
      if (contentDisposition) {
        const match = contentDisposition.match(/filename="(.+)"/);
        if (match && match[1]) {
          // eslint-disable-next-line prefer-destructuring
          fileName = match[1];
        }
      }

      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Nettoyer l'URL Blob après le téléchargement
      URL.revokeObjectURL(blobUrl);
    } catch {
      utils.toast.error(t('global.form.errors.global'));
    }
  }, [pdfUrl]);

  const renderNav = useCallback(() => (
    <Button
      onClick={handleDownload}
      label={t('global.listOptions.download')}
      size="medium"
      icon="import"
      iconSide="left"
    />
  ), [t, navigate, handleDownload]);

  return (
    <WithHeader
      logoLink="/register"
      navContent={renderNav()}
      isContained={false}
      className="p-l-20 p-r-20"
      displayBurger={false}
    >
      <iframe
        title="IBAN"
        src={pdfUrl}
        width={window ? window.innerWidth : '100%'}
        height={window ? window.innerHeight - 76 : '100%'} // 76 is the header height
        frameBorder="0"
      />
    </WithHeader>
  );
}

export default IbanStream;
