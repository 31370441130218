import {
  cloneElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';

// Components
import {
  TextInput,
  DateInput,
  utils,
} from 'ui-library-unlocker';
import CityInput from '../../../components/molecules/CityInput/CityInput';
import IbanInput from '../../../components/molecules/IbanInput/IbanInput';
import ValidationChangeModal from '../../../components/molecules/ValidationChangeModal/ValidationChangeModal';

// Services
import { createCompany, updateCompany } from '../../../services/company';

// Hooks
import { useOnboardingContext } from '../../../store/onboardingContext';

// Utils
import { displayError, errorFocusSubmit, isFieldValid } from '../../../utils/forms/form';
import {
  onboardingCompany2InitialValues,
  onboardingCompany2Schema,
} from '../../../utils/forms/onboardingSchema';
import { showModal } from '../../../utils/modal';

// Constants
import { ENROLMENT_STATUS } from '../../../utils/constants';

import styles from './OnboardingTunnelCompany2.module.scss';

const COMPANY_CHANGE_MODAL_ID = 'company-change-modal';

function OnboardingTunnelCompany2({
  children: footer = null,
  handleStep,
}) {
  const { t } = useTranslation();
  const {
    contextOnboarding: {
      inputtedData,
      error,
      companyData,
    },
    refetchMe,
    refetchCompany,
  } = useOnboardingContext();
  const ibanRef = useRef(null);

  const [displayTaxZipCode, setDisplayTaxZipCode] = useState(!inputtedData?.company?.taxZipCode);

  const companyMutation = useMutation({
    mutationFn: (data) => (companyData ? updateCompany(companyData.data?.uid, data) : createCompany(data)),
    onSuccess: ({ response, status }) => {
      const s = status || response?.status;
      switch (s) {
        case 201:
          // refetch me to get new aclMarix and company data
          refetchMe();
          break;
        case 204:
          refetchCompany();
          break;
        default:
          break;
      }
      ibanRef.current?.resetEditMode();
      return handleStep(1);
    },
    onError: (err) => {
      if (err?.response?.status === 400) {
        return utils.toast.error(t('global.form.errors.generic'));
      }
      return utils.toast.error(t('global.form.errors.global'));
    },
  });

  const companyIsRealEstateAgency = useMemo(() => (
    companyData?.data?.isRealEstateAgency
    || inputtedData?.company?.isRealEstateAgency
  ), [companyData, inputtedData]);

  const initialValues = useMemo(() => {
    if (error) {
      return onboardingCompany2InitialValues;
    }
    if (inputtedData.company) {
      return inputtedData.company;
    }
    return onboardingCompany2InitialValues;
  }, [inputtedData.company, error]);

  const formik = useFormik({
    initialValues,
    validate: (values) => {
      try {
        onboardingCompany2Schema.validateSync(values, {
          context: {
            isRealEstateAgency: companyIsRealEstateAgency,
          },
          abortEarly: false,
        });
      } catch (err) {
        const errorList = err.inner.reduce((errors, er) => {
          errors[er.path] = er.message;
          return errors;
        }, {});

        return errorList;
      }
      return {};
    },
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      // If no changes, go to next step
      if (JSON.stringify(values) === JSON.stringify(companyData?.data)) return handleStep(1);
      // check if already in validation process
      // If so, open modal warning that the validation process must be sent once more
      if (
        ![
          ENROLMENT_STATUS.PENDING,
          ENROLMENT_STATUS.PROFILE_DONE,
        ].includes(companyData?.data?.onboardingStatus)
      ) {
        showModal(COMPANY_CHANGE_MODAL_ID);
        return formik.setSubmitting(false);
      }
      ibanRef.current?.resetEditMode();
      return companyMutation.mutate(values);
    },
  });

  const handleSubmit = useCallback(() => {
    formik.handleSubmit();
  }, [formik]);

  const registrationDateValue = useMemo(() => {
    try {
      if (!formik.values.registrationDate) return null;
      const date = new Date(formik.values.registrationDate);
      return date;
    } catch {
      return null;
    }
  }, [formik.values.registrationDate]);

  const handleRegistrationPlaceSelect = useCallback((value) => {
    formik.setFieldValue('registrationPlace', value?.city);
    formik.setFieldValue('registrationCountry', value?.countryCode);
  }, [formik]);

  const handleTaxCitySelect = useCallback((value) => {
    formik.setFieldValue('taxCity', value?.city);
    formik.setFieldValue('taxCountry', value?.countryCode);
    // As of now, set the taxZipCode to 991 (Europe) if the birthCountry is not France
    if (value?.countryCode !== 'FR') {
      return formik.setFieldValue('taxZipCode', '991');
    }
    if (value?.zipCode) {
      return formik.setFieldValue('taxZipCode', value.zipCode.slice(0, 2));
    }
    setDisplayTaxZipCode(true);
    return formik.setFieldValue('taxZipCode', '');
  }, [formik]);

  return (
    <>
      <div className={styles.company1}>
        <h2 className="m-b-10">
          {t('onboarding.steps.company2.title')}
          &nbsp;
          {inputtedData.company?.legalName || ''}
        </h2>
        <form className={styles.form} onSubmit={errorFocusSubmit(handleSubmit)}>
          <DateInput
            id="registrationDate"
            name="registrationDate"
            className="m-t-25"
            label={t('company.crud.form.registrationDate')}
            error={displayError(t, formik, 'registrationDate', 'date', { errorIfUntouched: true })}
            valid={isFieldValid(formik, 'registrationDate', null, initialValues?.registrationDate)}
            onChange={(date) => formik.setFieldValue('registrationDate', date)}
            info={t('lease.add.form.general.fields.startDateInfo')}
            onBlur={formik.handleBlur}
            value={registrationDateValue}
          />
          <CityInput
            id="registrationPlace"
            name="registrationPlace"
            className="m-t-25"
            label={t('company.crud.form.registrationPlace')}
            error={displayError(t, formik, 'registrationPlace', 'text', { errorIfUntouched: true })}
            valid={isFieldValid(formik, 'registrationPlace', null, initialValues?.registrationPlace)}
            onAddressSelect={handleRegistrationPlaceSelect}
            value={formik.values.registrationPlace}
          />
          {companyIsRealEstateAgency && (
            <TextInput
              type="text"
              id="rcsRegisterLocation"
              name="rcsRegisterLocation"
              className="m-t-25"
              label={t('company.crud.form.rcsRegisterLocation')}
              error={displayError(t, formik, 'rcsRegisterLocation')}
              valid={isFieldValid(formik, 'rcsRegisterLocation', null, initialValues?.rcsRegisterLocation)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.rcsRegisterLocation}
            />
          )}
          <CityInput
            id="taxCity"
            name="taxCity"
            className="m-t-25"
            label={t('company.crud.form.taxCity')}
            error={displayError(t, formik, 'taxCity', 'text', { errorIfUntouched: true })}
            valid={isFieldValid(formik, 'taxCity', null, initialValues?.taxCity)}
            onAddressSelect={handleTaxCitySelect}
            value={formik.values.taxCity}
          />
          {formik.values.taxCity && displayTaxZipCode && (
            <TextInput
              type="text"
              id="taxZipCode"
              name="taxZipCode"
              className="m-t-25"
              label={t('company.crud.form.taxZipCode')}
              error={displayError(t, formik, 'taxZipCode')}
              valid={isFieldValid(formik, 'taxZipCode', null, initialValues?.taxZipCode)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.taxZipCode}
            />
          )}
          <TextInput
            type="text"
            id="annualTurnover"
            name="annualTurnover"
            className="m-t-25"
            label={t('company.crud.form.annualTurnover')}
            error={displayError(t, formik, 'annualTurnover')}
            valid={isFieldValid(formik, 'annualTurnover', null, initialValues?.annualTurnover)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.annualTurnover}
          />
          <TextInput
            type="text"
            id="vatNumber"
            name="vatNumber"
            className="m-t-25"
            label={t('company.crud.form.vatNumber')}
            error={displayError(t, formik, 'vatNumber')}
            valid={isFieldValid(formik, 'vatNumber', null, initialValues?.vatNumber)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.vatNumber}
          />
          {companyIsRealEstateAgency && (
          <>
            <TextInput
              type="text"
              id="proCivilLiability"
              name="proCivilLiability"
              className="m-t-25"
              label={t('company.crud.form.proCivilLiability')}
              error={displayError(t, formik, 'proCivilLiability')}
              valid={isFieldValid(formik, 'proCivilLiability', null, initialValues?.proCivilLiability)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.proCivilLiability}
            />
            <TextInput
              type="text"
              id="warrantyFund"
              name="warrantyFund"
              className="m-t-25"
              label={t('company.crud.form.warrantyFund')}
              error={displayError(t, formik, 'warrantyFund')}
              valid={isFieldValid(formik, 'warrantyFund', null, initialValues?.warrantyFund)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.warrantyFund}
            />
            <TextInput
              type="text"
              id="proCardNumber"
              name="proCardNumber"
              className="m-t-25"
              label={t('company.crud.form.proCardNumber')}
              error={displayError(t, formik, 'proCardNumber')}
              valid={isFieldValid(formik, 'proCardNumber', null, initialValues?.proCardNumber)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.proCardNumber}
            />
          </>
          )}
          <IbanInput
            id="iban"
            name="iban"
            className="m-t-25"
            label={t('company.crud.form.iban')}
            error={displayError(t, formik, 'iban', null, { errorIfUntouched: true })}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.iban}
            editMode={!!initialValues.iban}
            ref={ibanRef}
          />
        </form>
      </div>
      {footer && cloneElement(footer, {
        onSubmit: handleSubmit,
      })}
      <ValidationChangeModal
        id={COMPANY_CHANGE_MODAL_ID}
        onAccept={() => companyMutation.mutate(formik.values)}
        submitLoading={companyMutation.isLoading}
        entity="company"
      />
    </>
  );
}

OnboardingTunnelCompany2.propTypes = {
  children: PropTypes.node,
  handleStep: PropTypes.func.isRequired,
};

export default OnboardingTunnelCompany2;
