import { useMemo } from 'react';
import { useFormik } from 'formik';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

// Components
import {
  utils,
  TextInput,
  Button,
  UnlockerLoader,
} from 'ui-library-unlocker';

// Services
import { changeUserEmail, changeUserPassword } from '../../../../services/identity';

// Context
import { useAppContext } from '../../../../store/context';

// Utils
import {
  emailLoginInfoInitialValues,
  passwordLoginInfoInitialValues,
  emailLoginInfoSchema,
  passwordLoginInfoSchema,
} from '../../../../utils/forms/loginInfoSchema';
import { displayError, isFieldValid } from '../../../../utils/forms/form';

// Styles
import styles from './LoginInfoForm.module.scss';

function EmailForm() {
  const { t } = useTranslation();
  const { context: { me } } = useAppContext();

  const initialEmailValues = useMemo(() => {
    if (me) return me;
    return emailLoginInfoInitialValues;
  }, [me]);

  const emailMutation = useMutation({
    mutationFn: changeUserEmail,
    onSuccess: ({ response, status }) => {
      const s = status || response?.status;
      switch (s) {
        case 204:
          utils.toast.success(t('profile.loginInfo.form.emailSuccess'));
          break;
        default:
          break;
      }
    },
    onError: (err) => {
      switch (err?.response?.status) {
        case 400: {
          utils.toast.error(t('global.form.errors.generic'));
          break;
        }
        default:
          utils.toast.error(t('global.form.errors.global'));
          break;
      }
    },
  });

  const emailFormik = useFormik({
    initialValues: initialEmailValues,
    validationSchema: emailLoginInfoSchema,
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => emailMutation.mutate(values),
  });

  return (
    <form className={styles.form} onSubmit={emailFormik.handleSubmit}>
      <h4 className="m-t-50 m-b-20">{t('profile.loginInfo.subtitle1')}</h4>
      <TextInput
        type="text"
        id="email"
        name="email"
        label={t('profile.loginInfo.form.email')}
        error={displayError(t, emailFormik, 'email')}
        valid={isFieldValid(emailFormik, 'email')}
        onChange={emailFormik.handleChange}
        onBlur={emailFormik.handleBlur}
        value={emailFormik.values.email}
        autoComplete="off"
      />
      <div className={styles.submit}>
        <Button
          type="submit"
          size="large"
          label={t('profile.loginInfo.form.submit')}
        />
      </div>
    </form>
  );
}

function PasswordForm() {
  const { t } = useTranslation();

  const passwordMutation = useMutation({
    mutationFn: changeUserPassword,
    onSuccess: ({ response, status }) => {
      const s = status || response?.status;
      switch (s) {
        case 204:
          utils.toast.success(t('profile.loginInfo.form.passwordSuccess'));
          break;
        default:
          break;
      }
    },
    onError: (err) => {
      switch (err?.response?.status) {
        case 400: {
          utils.toast.error(t('global.form.errors.generic'));
          break;
        }
        default:
          utils.toast.error(t('global.form.errors.global'));
          break;
      }
    },
  });

  const passwordFormik = useFormik({
    initialValues: passwordLoginInfoInitialValues,
    validationSchema: passwordLoginInfoSchema,
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => passwordMutation.mutate(values),
  });

  return (
    <form className={styles.form} onSubmit={passwordFormik.handleSubmit}>
      <h4 className="m-t-50 m-b-20">{t('profile.loginInfo.subtitle2')}</h4>
      <TextInput
        type="password"
        id="previousPassword"
        name="previousPassword"
        className="m-t-25"
        label={t('profile.loginInfo.form.password')}
        error={displayError(t, passwordFormik, 'previousPassword')}
        valid={isFieldValid(passwordFormik, 'previousPassword')}
        onChange={passwordFormik.handleChange}
        onBlur={passwordFormik.handleBlur}
        value={passwordFormik.values.previousPassword}
        autoComplete="new-password"
      />
      <TextInput
        type="password"
        id="proposedPassword"
        name="proposedPassword"
        className="m-t-25"
        label={t('profile.loginInfo.form.newPassword')}
        info={t('global.form.info.password')}
        error={displayError(t, passwordFormik, 'proposedPassword')}
        valid={isFieldValid(passwordFormik, 'proposedPassword')}
        onChange={passwordFormik.handleChange}
        onBlur={passwordFormik.handleBlur}
        value={passwordFormik.values.proposedPassword}
        autoComplete="new-password"
      />
      <div className={styles.submit}>
        <Button
          type="submit"
          size="large"
          label={t('profile.loginInfo.form.submit')}
        />
      </div>
    </form>
  );
}

function LoginInfoForm() {
  const { t } = useTranslation();
  const { context: { me } } = useAppContext();

  if (!me) return <UnlockerLoader size={200} align="left" />;

  return (
    <div className={styles.wrapper}>
      <div className={styles.form}>
        <h2>{t('profile.loginInfo.title')}</h2>
        <EmailForm />
        <PasswordForm />
      </div>
    </div>
  );
}

export default LoginInfoForm;
