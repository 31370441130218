import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';
import { utils } from 'ui-library-unlocker';

import styles from './NotificationCenter.module.scss';

function NotifSkeleton({
  className = '',
}) {
  return (
    <div
      className={utils.cn([
        styles.notifSkeleton,
        className,
      ])}
    >
      <Skeleton width="50%" />
      <Skeleton width="95%" />
      <Skeleton width="80%" />
    </div>
  );
}

NotifSkeleton.propTypes = {
  className: PropTypes.string,
};

export default NotifSkeleton;
