/* eslint-disable no-restricted-syntax */
import {
  cloneElement,
  useCallback,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import TagManager from 'react-gtm-module';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

// Components
import {
  Message,
  Button,
  utils,
} from 'ui-library-unlocker';
import CompanyDocumentsList from '../../../components/organisms/DocumentList/CompanyDocumentsList/CompanyDocumentsList';
import Modal from '../../../components/molecules/Modal/Modal';

// Services
import { requestCompanyEnrolment } from '../../../services/company';

// Hooks
import { useOnboardingContext } from '../../../store/onboardingContext';

// Utils
import { showModal, hideModal } from '../../../utils/modal';

// Constants
import { ENROLMENT_STATUS, DOC_TYPES } from '../../../utils/constants';

import styles from './OnboardingTunnelCompany5.module.scss';

const ACCEPTED_DOCUMENTS_ADDRESS_PROOF__MODAL_ID = 'accepted-documents-address-proof-modal';
const VALIDATION_REQUEST_MODAL_ID = 'send-validation-request-confirm-modal';

function OnboardingTunnelCompany5({
  children: footer = null,
  handleStep,
  endOnboardingMutation,
}) {
  const { t } = useTranslation();
  const {
    contextOnboarding: {
      documentsCompanyData,
      companyData,
      inputtedData,
    },
    refetchDocumentsCompany,
    refetchMe,
  } = useOnboardingContext();

  const [missingDocuments, setMissingDocuments] = useState(null);

  const documentsList = useMemo(() => documentsCompanyData?.data?.collection || [], [documentsCompanyData]);

  const displayMissingDocuments = useMemo(() => {
    if (!missingDocuments) return null;
    const docTypeEntries = Object.entries(DOC_TYPES);
    return (
      <>
        <p className="p-2-700 m-b-10">
          {t('profile.documents.missingDocuments')}
        </p>
        <ul className={styles.missingList}>
          {missingDocuments.map((doc) => {
            const docKey = docTypeEntries.find(([, value]) => value === doc?.toLowerCase())?.[0];
            return (
              <li key={doc}>{t(`global.docStep.${docKey}.title`)}</li>
            );
          })}
        </ul>
      </>
    );
  }, [missingDocuments, t]);

  // Justificatifs d'identite
  const identityProofColumns = useMemo(() => ([
    {
      header: t('global.docStep.identityProof.columns.expireDate'),
      accessorKey: 'date',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { date } } }) => (
        <div>
          {date ? format(new Date(date), 'dd MM yyyy', { locale: fr }) : null}
        </div>
      ),
    },
  ]), [t]);

  const isAssociation = useMemo(() => companyData?.legalCategory === '92', [companyData]);

  const docSteps = useMemo(() => [
    // Justificatif de pouvoir
    {
      docType: 'powerProof',
      count: 1,
      optional: true,
      optionalTitle: `global.docStep.powerProof.optional.${isAssociation ? 'assocLabel' : 'label'}`,
    },
    // Justificatif d'identite
    {
      docType: 'identityProof',
      count: 2,
      additionalColumns: identityProofColumns,
    },
    // Justificatif d'adresse
    {
      docType: 'addressProof',
      count: 2,
      infoModalId: ACCEPTED_DOCUMENTS_ADDRESS_PROOF__MODAL_ID,
    },
    // IBAN
    {
      docType: 'iban',
      count: 4,
    },
    // Sirene
    isAssociation ? {
      docType: 'sirene',
      count: 5,
    } : null,
  ].filter(Boolean), [isAssociation]);

  const canSendValidationRequest = useMemo(() => {
    if (documentsList.length < 1) return false;
    const { onboardingStatus } = companyData?.data ?? {};
    if (!onboardingStatus) return false;
    return [
      ENROLMENT_STATUS.PROFILE_DONE,
      ENROLMENT_STATUS.ENROLMENT_DENIED,
      ENROLMENT_STATUS.ENROLMENT_CHALLENGED,
    ].includes(onboardingStatus);
  }, [companyData]);

  const enrolmentMutation = useMutation({
    mutationFn: () => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'sent_validation_request',
          entityType: 'company',
        },
      });
      return requestCompanyEnrolment(companyData?.data?.uid);
    },
    onSuccess: () => {
      utils.toast.success(t('profile.documents.validationSentSuccess'));
      // refetchMe will also trigger refetch of the company data
      refetchMe();
      setMissingDocuments(null);
      hideModal(VALIDATION_REQUEST_MODAL_ID);
      endOnboardingMutation.mutate();
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 400: {
            switch (err?.response?.data?.message) {
              case 'COMPANY_ENROLMENT_NATURAL_PERSON_DOCUMENTS_REQUIREMENT_EXCEPTION': {
                utils.toast.error(t('profile.paymentAccount.errorPersonMissingDocuments'));
                break;
              }
              case 'COMPANY_ENROLMENT_NATURAL_PERSON_PROFILE_REQUIREMENT_EXCEPTION': {
                utils.toast.error(t('profile.paymentAccount.needProfileDone'));
                break;
              }
              case 'ENROLMENT_COMPANY_DOCUMENTS_REQUIREMENT_EXCEPTION': {
                utils.toast.error(t('profile.paymentAccount.errorMissingDocuments'));
                const { errors } = err?.response?.data || {};
                TagManager.dataLayer({
                  dataLayer: {
                    event: 'incomplete_validation_request',
                    entityType: 'company',
                  },
                });
                if (errors) {
                  const missing = Object.values(errors);
                  setMissingDocuments(missing || null);
                }
                break;
              }
              default:
                utils.toast.error(t('global.form.errors.global'));
                break;
            }
            break;
          }
          case 409: {
            utils.toast.error(t('profile.paymentAccount.errorInProgress'));
            break;
          }
          default:
            break;
        }
        hideModal(VALIDATION_REQUEST_MODAL_ID);
      }
    },
  });

  const handleSubmit = useCallback(() => {
    if (canSendValidationRequest) {
      return showModal(VALIDATION_REQUEST_MODAL_ID);
    }
    const missingDocs = [];
    for (const docStep of docSteps) {
      if (!docStep.optional && !documentsList.find((doc) => doc.type === DOC_TYPES[docStep.docType])) {
        missingDocs.push(DOC_TYPES[docStep.docType]);
      }
    }
    if (missingDocs.length > 0) {
      return setMissingDocuments(missingDocs);
    }
    return endOnboardingMutation.mutate();
  }, [canSendValidationRequest, handleStep]);

  const getAcceptedDocumentsAddressProofContent = useMemo(() => (
    <div className={styles.acceptedDocuments}>
      <h3 className="m-t-30 m-b-10">
        {t('profile.documents.acceptedDocuments.unlistedPrivateCompany.addressIdTitle')}
      </h3>
      <ul className={styles.acceptedDocumentsList}>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.addressId1')}</li>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.addressId2')}</li>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.addressId3')}</li>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.addressId4')}</li>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.addressId5')}</li>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.addressId6')}</li>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.addressId7')}</li>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.addressId8')}</li>
      </ul>
    </div>
  ), [t]);

  return (
    <>
      <div className={styles.profile3}>

        <h2 className="m-b-10">
          {t('onboarding.steps.company5.title')}
          &nbsp;
          {inputtedData.company?.legalName || ''}
        </h2>

        <CompanyDocumentsList
          documentsData={documentsList}
          docSteps={docSteps}
          refetchDocuments={refetchDocumentsCompany}
          companyUID={companyData?.data?.uid}
        />

        {missingDocuments?.length > 0 && (
          <div className={styles.missingBlock}>
            <Message
              className="m-t-30"
              icon={null}
              variant="alert"
              content={displayMissingDocuments}
            />
          </div>
        )}
      </div>

      {footer && cloneElement(footer, {
        onSubmit: handleSubmit,
        isLoading: enrolmentMutation.isLoading || endOnboardingMutation.isLoading,
        isDisabled: documentsList.length < 1,
      })}

      {/* Send validation request modal */}
      <Modal
        id={VALIDATION_REQUEST_MODAL_ID}
        title={t('profile.documents.confirmSendValidation')}
        size="large"
        onClose={() => hideModal(VALIDATION_REQUEST_MODAL_ID)}
      >
        <Message
          variant="info"
          content={t('company.validationRequestProfileWarn')}
        />
        <Button
          className="m-t-40 center-block"
          size="large"
          loading={enrolmentMutation?.isLoading}
          onClick={enrolmentMutation.mutate}
        >
          {t('global.validate')}
        </Button>
      </Modal>

      {/* Accepted Documents Modal for Address proof */}
      <Modal
        id={ACCEPTED_DOCUMENTS_ADDRESS_PROOF__MODAL_ID}
        title={t('profile.documents.acceptedDocumentsTitle')}
        size="large"
        onClose={() => {
          hideModal(ACCEPTED_DOCUMENTS_ADDRESS_PROOF__MODAL_ID);
        }}
      >
        {getAcceptedDocumentsAddressProofContent}
      </Modal>
    </>
  );
}

OnboardingTunnelCompany5.propTypes = {
  children: PropTypes.node,
  handleStep: PropTypes.func.isRequired,
  endOnboardingMutation: PropTypes.shape({
    mutate: PropTypes.func,
    isLoading: PropTypes.bool,
  }).isRequired,
};

export default OnboardingTunnelCompany5;
