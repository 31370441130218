import { useTranslation, Trans } from 'react-i18next';

// Components
import {
  Message,
  utils,
  Picto,
} from 'ui-library-unlocker';

import styles from './PostAd.module.scss';

function PostAdSuccess() {
  const { t } = useTranslation();

  return (
    <>
      <h2 className="m-t-30">{t('postAd.validateTitle')}</h2>
      <Message
        content={(
          <div className={styles.successMsgContent}>
            <Picto
              width={24}
              height={24}
              color="var(--color-secondary)"
              icon="mail"
            />
            <span>{t('postAd.success')}</span>
          </div>
        )}
        variant="info"
        className={utils.cn([styles.successMsg, 'm-t-30'])}
        icon={false}
      />
      <p className="m-t-25">
        <Trans i18nKey="postAd.successInfo" />
      </p>
    </>
  );
}

export default PostAdSuccess;
