/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, {
  forwardRef,
  useCallback,
  useMemo,
  useState,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate, useMatch } from 'react-router-dom';
import { debounce } from 'lodash';

// Components
import {
  utils,
  TextInput,
  Button,
  UnlockerLoader,
  SelectInput,
  ToggleInput,
  DateInput,
  Message,
} from 'ui-library-unlocker';

// Services
import {
  createHousingBenefit,
  updateHousingBenefit,
} from '../../../../services/housingBenefits';
import { getLeases } from '../../../../services/lease';
import { getTenants } from '../../../../services/tenant';
import { getUsersFromAdmin } from '../../../../services/admin';

// Utils
import {
  housingBenefitsSchema,
  housingBenefitsInitialValues,
} from '../../../../utils/forms/housingBenefitsSchema';
import { displayError, errorFocusSubmit } from '../../../../utils/forms/form';
import { canEditOrDeleteHousingBenefit } from '../../../../utils/validation';

// Hooks
import useScroll from '../../../../hooks/useScroll';
import useRoles from '../../../../hooks/useRoles';

// Constants
import { HOUSING_BENEFITS_TYPES, LEASE_STATUS } from '../../../../utils/constants';

import styles from './HousingBenefitsForm.module.scss';

const HousingBenefitsForm = forwardRef(({
  isEditing,
  itemToEdit = null,
  housingBenefitsQuery = null,
  leaseUID = null,
  leaseTenants = [],
  leaseRentWithCharges = 0,
  onAddSuccess = null,
  onCancel = null,
}, ref) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { scrollContentToTop } = useScroll();
  const { isUserAdmin, isUserRealEstateManager } = useRoles();

  const match = useMatch('/housing-benefits/:id');

  const [leaseQuery, setLeaseQuery] = useState('');
  const [selectedLease, setSelectedLease] = useState(null);

  const {
    data: housingBenefitsData,
    error: housingBenefitsError,
    isFetched: housingBenefitsFetched,
  } = housingBenefitsQuery || {};

  const initialValues = useMemo(() => {
    if (itemToEdit) {
      return {
        ...itemToEdit,
        amount: utils.centsToEuro(itemToEdit.amount),
        tenantUID: itemToEdit.tenant?.uid,
        leaseUID: itemToEdit.lease?.uid,
        leaseRentWithCharges: utils.centsToEuro(itemToEdit.leaseRentWithCharges),
        date: new Date(itemToEdit.year, itemToEdit.month - 1),
      };
    }
    if (housingBenefitsError) return housingBenefitsData?.data;
    if (housingBenefitsData?.data) {
      return {
        ...housingBenefitsData.data,
        amount: utils.centsToEuro(housingBenefitsData.data.amount),
        tenantUID: housingBenefitsData.data.tenant?.uid,
        leaseUID: leaseUID || housingBenefitsData.data.lease?.uid,
        leaseRentWithCharges: utils.centsToEuro(
          leaseUID ? leaseRentWithCharges : housingBenefitsData.data.leaseRentWithCharges,
        ),
        date: new Date(housingBenefitsData.data.year, housingBenefitsData.data.month - 1),
      };
    }
    return {
      ...housingBenefitsInitialValues,
      leaseUID,
      leaseRentWithCharges: utils.centsToEuro(
        leaseUID ? leaseRentWithCharges : housingBenefitsInitialValues.leaseRentWithCharges,
      ),
    };
  }, [housingBenefitsData, housingBenefitsError, leaseUID, itemToEdit, leaseRentWithCharges]);

  const formik = useFormik({
    initialValues,
    validationSchema: housingBenefitsSchema,
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      housingBenefitsMutation.mutate({
        lease: values.leaseUID,
        tenant: values.tenantUID,
        amount: utils.euroToCents(values.amount),
        recurrent: values.recurrent,
        month: values.date ? new Date(values.date).getMonth() + 1 : undefined,
        year: values.date ? new Date(values.date).getFullYear() : undefined,
        type: values.type,
        name: values.name,
      });
    },
  });

  const {
    data: leasesListData,
    isFetching: leasesListFetching,
  } = useQuery({
    queryKey: ['leases-list-select', leaseQuery],
    queryFn: () => getLeases({
      filters: {
        status: [LEASE_STATUS.SIGNED, LEASE_STATUS.CREATED],
        search: [leaseQuery],
      },
    }),
    keepPreviousData: false,
    enabled: !leaseUID,
  });

  const {
    data: tenantsListData,
    isFetching: tenantsListFetching,
  } = useQuery({
    queryKey: ['tenants-list-select', selectedLease, leaseTenants],
    queryFn: () => (isUserAdmin ? getUsersFromAdmin : getTenants)({
      filters: {
        uid: leaseTenants?.length ? leaseTenants : selectedLease?.tenants,
        role: ['ROLE_USER'],
      },
    }),
    keepPreviousData: false,
    enabled: !!selectedLease || !!leaseTenants,
  });

  useImperativeHandle(ref, () => ({
    resetForm: () => {
      formik.resetForm();
    },
  }), [formik]);

  const housingBenefitsMutation = useMutation({
    mutationFn: (data) => {
      if (isEditing) {
        return updateHousingBenefit(itemToEdit?.uid || match?.params?.id, data);
      }
      return createHousingBenefit(data);
    },
    onSuccess: ({ response, status }) => {
      const s = status || response?.status;
      switch (s) {
        case 201:
          utils.toast.success(t('housingBenefits.crud.addSuccess'));
          if (leaseUID) {
            onAddSuccess?.();
            onCancel?.(formik.resetForm);
          } else {
            navigate('/housing-benefits');
          }
          break;
        case 204:
          utils.toast.success(t('housingBenefits.crud.editSuccess'));
          if (leaseUID) {
            onAddSuccess?.();
            onCancel?.(formik.resetForm);
          } else {
            scrollContentToTop();
          }
          break;
        default:
          break;
      }
    },
    onError: (err) => {
      switch (err?.response?.status) {
        case 400: {
          if ([
            'RENT_DISTRIBUTION_AMOUNT_TO_BIG_EXCEPTION',
            'RENT_DISTRIBUTION_DELAY_TOO_SHORT_EXCEPTION',
            'RENT_DISTRIBUTION_EXECUTION_DATE_IS_PAST_EXCEPTION',
            'RENT_DISTRIBUTION_LEASE_EXPIRED_EXCEPTION',
            'RENT_DISTRIBUTION_TENANT_NOT_FROM_LEASE_EXCEPTION',
          ].includes(err.response.data?.message)) {
            utils.toast.error(t(`housingBenefits.crud.formErrors.${err.response.data?.message}`));
          } else {
            utils.toast.error(t('global.form.errors.generic'));
          }
          formik.setErrors(err?.response?.data?.errors);
          break;
        }
        case 500: {
          utils.toast.error(t('global.form.errors.global'));
          break;
        }
        default: {
          utils.toast.error(t('global.form.errors.generic'));
          break;
        }
      }
    },
    onSettled: () => {
      formik.setSubmitting(false);
    },
  });

  const handleSearchLease = useCallback(debounce((value) => {
    setLeaseQuery(value);
  }, 500), [setLeaseQuery]);

  const handleCancel = useCallback(() => {
    formik.resetForm();
    utils.toast.info(t('housingBenefits.crud.cancelMessage'));
    return scrollContentToTop();
  }, [formik, t]);

  const typeOptions = useMemo(
    () => Object.values(HOUSING_BENEFITS_TYPES)
      .filter((value) => {
        if (isUserAdmin || isUserRealEstateManager) return true;
        return value === HOUSING_BENEFITS_TYPES.CAF;
      })
      .map((value) => ({
        value,
        label: t(`housingBenefits.type.${value}`),
      })),
    [],
  );

  const leaseOptions = useMemo(() => {
    if (!leasesListData) return [];
    return leasesListData?.data?.collection
      ?.map((lease) => ({
        value: lease.uid,
        label: lease.name,
      }));
  }, [leasesListData]);

  const tenantOptions = useMemo(() => {
    if (!tenantsListData) return [];
    return tenantsListData?.data?.collection
      ?.map((tenant) => ({
        value: tenant.uid,
        label: `${tenant.firstName} ${tenant.lastName} - ${
          tenant.tenantNumber || t('housingBenefits.crud.form.noTenantNumber')
        }`,
      }));
  }, [tenantsListData]);

  const handleLeaseChange = useCallback((value) => {
    formik.setFieldValue('leaseUID', value.value);
    const leaseData = leasesListData?.data?.collection.find((lease) => lease.uid === value.value);
    setSelectedLease(leaseData);

    // tenants
    formik.setFieldValue('tenantUID', null);

    // leaseRentWithCharges
    if (leaseData) {
      const { charges = 0, rent = 0 } = leaseData;
      formik.setFieldValue('leaseRentWithCharges', utils.centsToEuro(rent + charges));
    }
  }, [formik, leasesListData]);

  const handleTenantChange = useCallback((value) => {
    formik.setFieldValue('tenantUID', value.value);
  }, [formik]);

  const showTenantInput = useMemo(() => !!formik.values.leaseUID, [formik.values.leaseUID]);

  const showForm = useMemo(() => !!formik.values.tenantUID || isEditing, [formik.values.tenantUID, isEditing]);

  const minDate = useMemo(() => (
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  ), []);

  const canEdit = useMemo(() => {
    if (!isEditing) return true;
    return canEditOrDeleteHousingBenefit(
      itemToEdit?.status || housingBenefitsData?.data?.status,
      itemToEdit?.recurrent || housingBenefitsData?.data?.recurrent,
    );
  }, [housingBenefitsData, itemToEdit, isEditing]);

  const displayLease = useCallback(() => {
    if (leaseUID) return null;
    if (isEditing) {
      return (
        <p className="m-t-15">
          <span className={styles.editLabel}>{t('housingBenefits.crud.form.lease')}</span>
          <br />
          {housingBenefitsData?.data?.lease?.name || '--'}
        </p>
      );
    }
    const leaseData = selectedLease ? ({
      value: selectedLease.uid,
      label: selectedLease.name,
    }) : null;
    return (
      <SelectInput
        id="leaseUID"
        name="leaseUID"
        className={styles.fullInput}
        label={t('housingBenefits.crud.form.lease')}
        options={leaseOptions}
        error={displayError(t, formik, 'leaseUID')}
        onChange={handleLeaseChange}
        onBlur={formik.handleBlur}
        value={leaseData}
        onInputChange={(value) => handleSearchLease(value)}
        isLoading={leasesListFetching}
        disabled={!canEdit}
      />
    );
  }, [
    leaseUID,
    isEditing,
    formik,
    leaseOptions,
    handleLeaseChange,
    handleSearchLease,
    leasesListFetching,
    t,
    canEdit,
    selectedLease,
    housingBenefitsData?.data?.lease?.name,
  ]);

  const displayTenant = useCallback(() => {
    const tenantData = formik.values.tenantUID
      ? tenantOptions.find((el) => el.value === formik.values.tenantUID)
      : null;
    if (isEditing) {
      return (
        <p className="m-t-20 m-b-35">
          <span className={styles.editLabel}>{t('housingBenefits.crud.form.tenant')}</span>
          <br />
          {tenantData?.label || '--'}
        </p>
      );
    }
    if (showTenantInput) {
      return (
        <SelectInput
          id="tenantUID"
          name="tenantUID"
          className={utils.cn(['m-t-25', styles.fullInput])}
          label={t('housingBenefits.crud.form.tenant')}
          options={tenantOptions}
          error={displayError(t, formik, 'tenantUID')}
          onChange={handleTenantChange}
          onBlur={formik.handleBlur}
          value={tenantData}
          isLoading={tenantsListFetching}
          disabled={!canEdit}
        />
      );
    }
    return null;
  }, [formik, tenantOptions, handleTenantChange, showTenantInput, t, tenantsListFetching, isEditing, canEdit]);

  if (isEditing && !housingBenefitsFetched && leasesListFetching) return <UnlockerLoader size={200} align="left" />;

  return (
    <div className={styles.wrapper}>
      <form className={styles.form} onSubmit={errorFocusSubmit(formik.handleSubmit)}>
        {!canEdit && (
          <Message
            content={t('housingBenefits.crud.cannotEdit')}
            variant="info"
          />
        )}
        {displayLease()}
        {displayTenant()}
        {showForm && (
        <>
          <TextInput
            type="text"
            id="name"
            name="name"
            className={utils.cn(['m-t-25', styles.fullInput])}
            label={t('housingBenefits.crud.form.name')}
            error={displayError(t, formik, 'name')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            disabled={!canEdit}
          />
          <SelectInput
            className={utils.cn(['m-t-25', styles.fullInput])}
            id="type"
            name="type"
            label={t('housingBenefits.crud.form.type')}
            error={displayError(t, formik, 'type')}
            options={typeOptions}
            onChange={(value) => formik.setFieldValue('type', value.value)}
            onBlur={formik.handleBlur}
            value={typeOptions.find((typeItem) => typeItem.value === formik.values.type)}
            disabled={!canEdit}
          />
          <TextInput
            min="0"
            step="0.01"
            type="number"
            id="amount"
            name="amount"
            className={utils.cn(['m-t-25', styles.mediumInput])}
            label={t('housingBenefits.crud.form.amount')}
            value={formik.values.amount}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={displayError(t, formik, 'amount', null, {
              errorConcatValues: {
                maxValue: formik.values.leaseRentWithCharges,
              },
            })}
            icon="euro"
            iconSize={20}
            iconColor="var(--color-primary-300)"
            disabled={!canEdit}
          />
          <ToggleInput
            id="recurrent"
            name="recurrent"
            className="m-t-25 m-l-10"
            label={t('housingBenefits.crud.form.recurrent')}
            checked={!!formik.values.recurrent}
            onChange={(check) => formik.setFieldValue('recurrent', check)}
            disabled={!canEdit}
          />
          <DateInput
            id="date"
            name="date"
            className={utils.cn(['m-t-25', styles.mediumInput])}
            label={t(`housingBenefits.crud.form.${formik.values.recurrent ? 'dateRecurrent' : 'date'}`)}
            error={displayError(t, formik, 'date')}
            onChange={(date) => formik.setFieldValue('date', date)}
            onBlur={formik.handleBlur}
            value={formik.values.date}
            monthYearPicker
            minDate={minDate}
            disabled={!canEdit}
          />
          <div className={styles.submit}>
            <Button
              type="submit"
              size="large"
              loading={formik.isSubmitting}
              label={t('global.validate')}
              disabled={!canEdit}
            />
            <p
              tabIndex={0}
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
              role="button"
              onKeyDown={null}
              className={styles.cancel}
              onClick={handleCancel}
            >
              {t('global.cancel')}
            </p>
          </div>
        </>
        )}
      </form>
    </div>
  );
});

HousingBenefitsForm.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  itemToEdit: PropTypes.shape({
    amount: PropTypes.number,
    date: PropTypes.string,
    month: PropTypes.number,
    year: PropTypes.number,
    lease: PropTypes.shape({
      uid: PropTypes.string,
    }),
    tenant: PropTypes.shape({
      uid: PropTypes.string,
    }),
    recurrent: PropTypes.bool,
    type: PropTypes.string,
    name: PropTypes,
    leaseRentWithCharges: PropTypes.number,
    uid: PropTypes.string,
    status: PropTypes.string,
  }),
  housingBenefitsQuery: PropTypes.shape(),
  leaseUID: PropTypes.string,
  leaseTenants: PropTypes.arrayOf(PropTypes.string),
  leaseRentWithCharges: PropTypes.number,
  onAddSuccess: PropTypes.func,
  onCancel: PropTypes.func,
};

export default HousingBenefitsForm;
