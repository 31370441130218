/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
import { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  TextInput,
  utils,
  Picto,
  CheckboxInput,
} from 'ui-library-unlocker';
import format from 'date-fns/format';
import fr from 'date-fns/locale/fr';
import { displayError, isFieldValid } from '../../../utils/forms/form';

import Modal from '../Modal/Modal';

import { hideModal } from '../../../utils/modal';
import revisionSchema from '../../../utils/forms/revisionSchema';

import { reviseLease } from '../../../services/lease';

import styles from './LeaseRevision.module.scss';

function LeaseRevisionForm({
  lease = null,
  idModal,
  canBeRevised,
  isAfterConstructionWork,
  reset = () => {},
  refetchLease = () => {},
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const revisionMutation = useMutation({
    mutationFn: (values) => reviseLease({
      leaseUid: lease?.uid,
      data: {
        ...values,
        rentExcludingCharges: utils.euroToCents(values.rentExcludingCharges),
        charges: utils.euroToCents(values.charges),
      },
    }),
    onSuccess: ({ response, status }) => {
      const s = status || response?.status;
      switch (s) {
        case 204:
          utils.toast.success(t('lease.revisions.form.success'));
          refetchLease();
          handleReset();
          hideModal(idModal);
          navigate('#revisions');
          break;
        default:
          break;
      }
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 400: {
            utils.toast.error(t('global.form.errors.localGeneric'));
            formik.setErrors(err?.response?.data?.errors);
            formik.setSubmitting(false);
            break;
          }
          default:
            break;
        }
      }
    },
  });

  const initialValues = useMemo(() => ({
    rentExcludingCharges: utils.centsToEuro(lease?.rent ?? 0),
    charges: utils.centsToEuro(lease?.charges ?? 0),
    heatingChargesIncluded: !!lease?.heatingChargesIncluded,
    coldWaterChargesIncluded: !!lease?.coldWaterChargesIncluded,
    hotWaterChargesIncluded: !!lease?.hotWaterChargesIncluded,
    isAfterConstructionWork,
  }), [lease]);

  const formik = useFormik({
    initialValues,
    validationSchema: revisionSchema,
    onSubmit: (values) => {
      revisionMutation.mutate(values);
    },
  });

  const handleReset = useCallback(() => setTimeout(() => {
    formik.resetForm();
    reset();
  }, 1000), [formik, reset]);

  return (
    <form className={styles.form} onSubmit={formik.handleSubmit}>
      <TextInput
        min="0"
        type="number"
        step="0.01"
        id="rentExcludingCharges"
        name="rentExcludingCharges"
        className={utils.cn([styles.mediumInput])}
        label={t('lease.revisions.form.rentExcludingCharges')}
        value={formik.values.rentExcludingCharges}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={displayError(t, formik, 'rentExcludingCharges')}
        valid={isFieldValid(formik, 'rentExcludingCharges', null, initialValues?.rentExcludingCharges)}
      />
      <TextInput
        min="0"
        type="number"
        step="0.01"
        id="charges"
        name="charges"
        className={utils.cn(['m-t-20', styles.mediumInput])}
        label={t('lease.revisions.form.charges')}
        value={formik.values.charges}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={displayError(t, formik, 'charges')}
        valid={isFieldValid(formik, 'charges', null, initialValues?.charges)}
      />
      <CheckboxInput
        id="heatingChargesIncluded"
        name="heatingChargesIncluded"
        className="m-t-20"
        label={t('lease.revisions.form.heatingChargesIncluded')}
        checked={!!formik.values.heatingChargesIncluded}
        onChange={() => {
          formik.setFieldValue('heatingChargesIncluded', !formik.values.heatingChargesIncluded);
        }}
      />
      <CheckboxInput
        id="coldWaterChargesIncluded"
        name="coldWaterChargesIncluded"
        className="m-t-20"
        label={t('lease.revisions.form.coldWaterChargesIncluded')}
        checked={!!formik.values.coldWaterChargesIncluded}
        onChange={() => {
          formik.setFieldValue('coldWaterChargesIncluded', !formik.values.coldWaterChargesIncluded);
        }}
      />
      <CheckboxInput
        id="hotWaterChargesIncluded"
        name="hotWaterChargesIncluded"
        className="m-t-20"
        label={t('lease.revisions.form.hotWaterChargesIncluded')}
        checked={!!formik.values.hotWaterChargesIncluded}
        onChange={() => {
          formik.setFieldValue('hotWaterChargesIncluded', !formik.values.hotWaterChargesIncluded);
        }}
      />
      <CheckboxInput
        id="isAfterConstructionWork"
        name="isAfterConstructionWork"
        className="m-t-20"
        label={t('lease.revisions.form.isAfterWorks')}
        checked={!!formik.values.isAfterConstructionWork}
        onChange={() => {
          formik.setFieldValue('isAfterConstructionWork', !formik.values.isAfterConstructionWork);
        }}
        disabled={!canBeRevised}
      />
      <div className={utils.cn(['m-t-30', styles.modalActions])}>
        <Button
          variant="secondary"
          size="large"
          className={styles.submitBtn}
          onClick={() => {
            hideModal(idModal);
            handleReset();
          }}
        >
          {t('global.cancel')}
        </Button>
        <Button
          type="submit"
          variant="primary"
          size="large"
          className={styles.submitBtn}
          loading={formik.isSubmitting}
        >
          {t('global.confirm')}
        </Button>
      </div>
    </form>
  );
}

LeaseRevisionForm.propTypes = {
  lease: PropTypes.shape({}),
  idModal: PropTypes.string.isRequired,
  canBeRevised: PropTypes.bool.isRequired,
  isAfterConstructionWork: PropTypes.bool.isRequired,
  reset: PropTypes.func,
  refetchLease: PropTypes.func,
};

function LeaseRevision({
  id,
  lease = null,
  isLoading = false,
  refetchLease = () => {},
}) {
  const { t } = useTranslation();
  const [isAfterConstructionWork, setIsAfterConstructionWork] = useState(false);

  const canBeRevised = useMemo(() => {
    if (!lease?.nextRevisionDate) {
      return false;
    }
    return new Date(lease?.nextRevisionDate) <= new Date();
  }, [lease]);

  const handleReset = useCallback(() => {
    setIsAfterConstructionWork(false);
  }, []);

  return (
    <Modal
      className={styles.modal}
      id={id}
      title={t('lease.revision')}
      onClose={() => {
        hideModal(id);
      }}
    >
      <div className={styles.modalContent}>
        {!canBeRevised && !isAfterConstructionWork ? (
          <>
            <p className="p-1-500">
              {t('lease.revisions.form.cannotBeRevised', {
                date: lease?.nextRevisionDate
                  ? format(new Date(lease?.nextRevisionDate), 'dd MMM yyyy', { locale: fr })
                  : '?',
              })}
            </p>
            <Button
              variant="primary"
              size="large"
              className={styles.submitBtn}
              onClick={() => {
                setIsAfterConstructionWork(true);
              }}
            >
              {t('lease.revisions.form.isAfterWorks')}
            </Button>
          </>
        ) : (
          <>
            {!isLoading ? (
              <LeaseRevisionForm
                lease={lease}
                idModal={id}
                canBeRevised={canBeRevised}
                isAfterConstructionWork={isAfterConstructionWork}
                reset={handleReset}
                refetchLease={refetchLease}
              />
            ) : (
              <Picto width={25} icon="loading" color="var(--color-secondary)" />
            )}
          </>
        )}
      </div>
    </Modal>
  );
}

LeaseRevision.propTypes = {
  id: PropTypes.string.isRequired,
  lease: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  refetchLease: PropTypes.func,
};

export default LeaseRevision;
