export const ROLES = {
  ROLE_ADMIN: 'ROLE_ADMIN', // admin
  ROLE_SUPER_ADMIN: 'ROLE_SUPER_ADMIN', // super admin
  ROLE_LESSOR: 'ROLE_LESSOR', // LESSOR
  ROLE_USER: 'ROLE_USER', // tenant
};

export const ONBOARDING_ROLES = {
  TENANT: 'tenant',
  OWNER: 'owner',
  OWNER_WITH_COMPANY: 'owner_with_company',
};

export const PERSON_STATUS = {
  ENABLED: 'enabled',
  PENDING: 'pending',
  DISABLED: 'disabled',
};

export const ENROLMENT_STATUS = {
  PENDING: 'pending',
  PROFILE_DONE: 'profile_done',
  ENROLMENT_REQUESTED: 'enrolment_requested',
  ENROLMENT_PENDING: 'enrolment_pending',
  ENROLMENT_CHALLENGED: 'enrolment_challenged',
  ENROLMENT_DENIED: 'enrolment_denied',
  ENROLMENT_BYPASSED: 'enrolment_bypassed',
  COMPLETED: 'completed',
  BLOCKED: 'blocked',
  NO_PROFILE: 'no_profile',
};

export const DOC_TYPES = {
  // PMO
  kbis: 'incorporation_certificate',
  decree: 'decree_or_official_extract',
  powerProof: 'additional_measures',
  identityProof: 'photo',
  beneficiariesList: 'lbe_complement_1',
  benefIdentities: 'other2_documents_legal_entity',
  benefIdentities2: 'other3_documents_legal_entity',
  benefIdentities3: 'lbe_complement_2',
  turnover: 'other_documents_legal_identity',
  sirene: 'proof_of_status',
  addressProof: 'other_proof_of_residence',
  iban: 'proof_of_bank_domiciliation_legal_entity',
  others: 'CUSTOM_OTHER_DOCUMENTS',
  logo: 'logo',
  // PPH
  PPHIdentityProof: 'portrait',
  PPHAddressProof: 'proof_of_residence',
  PPHIban: 'proof_of_bank_domiciliation',
  PPHOthers: 'other',
};

export const PAYMENT_AND_ACCOUNT_STATUS_LIST = {
  SCHEDULED: 'scheduled', // payment
  SCHEDULED_MANUAL: 'scheduled_manual', // payment
  NOTIFIED: 'notified', // payment
  PENDING: 'pending', // payment
  EXPORTED: 'exported', // payment
  READY_TO_CHECK: 'ready_to_check', // account
  IN_WIRING: 'in_wiring', // account
  SUCCESS: 'success', // payment
  RECEIVED: 'received', // account
  SENT: 'sent', // account
  READY_TO_WIRE: 'ready_to_wire', // account
  FAILED: 'failed', // payment
  CANCELED: 'canceled', // payment
  NOT_DOABLE: 'not_doable', // account
  SCHEDULING_ERROR: 'scheduling_error', // payment
  REJECTED_SDD: 'rejected_sdd', // payment
};

export const RENT_DISTRIBUTION_STATUS = {
  ONLY_WITH_PERSONAL_ACCOUNT: 'only_with_personal_account',
  ONLY_WITH_COMPANIES_ACCOUNT: 'only_with_companies_account',
  WITH_BOTH_PERSONAL_AND_COMPANIES_ACCOUNT: 'with_both_personal_and_companies_account',
  REAL_ESTATE_AGENCY_WITH_NO_PROPERTIES: 'real_estate_agency_with_no_properties',
};

export const SEARCH_SCOPE_LIST = {
  TENANTS: 'tenants',
  PROPERTIES: 'properties',
  LEASES: 'leases',
  PROPERTY_OWNERS: 'propertyOwners',
  MANAGERS: 'managers',
  COMPANIES: 'companies',
  ACCOUNTS: 'accounts',
};

export const LEASE_STATUS = {
  CREATED: 'created',
  WAITING_DOCUMENT: 'waiting_document',
  PENDING_SIGNATURE: 'pending_signature',
  SIGNED: 'signed',
  TERMINATION_SCHEDULED: 'termination_scheduled',
  TERMINATED: 'terminated',
};

export const PROPERTY_STATUS = {
  AVAILABLE: 'available',
  LEASED: 'leased',
  DRAFT: 'draft',
  DISABLED: 'disabled',
};

export const PROPERTY_VISIBILITY = {
  VISIBLE: 'visible',
  LISTING_VISIBLE: 'listing_visible',
  LISTING_DELETED: 'listing_deleted',
  HIDDEN: 'hidden',
  DEACTIVATED: 'deactivated',
  WAITING_KYC: 'waiting_kyc',
  WAITING_LISTING_VALIDATION: 'waiting_listing_validation',
};

export const ACCOUNT_STATUS = {
  PENDING: 'pending',
  OPENED: 'opened',
  CLOSED: 'closed',
  BLOCKED: 'blocked',
  CREDIT_BLOCKED: 'credit_blocked',
  DEBIT_BLOCKED: 'debit_blocked',
};

export const ACCOUNT_ENROLMENT = {
  TO_REVIEW: 'to_review',
  PENDING: 'pending',
  SUBMITTED: 'submitted',
  ESCALATED: 'escalated',
  VALIDATED: 'validated',
  REFUSED: 'refused',
  BLOCKED: 'blocked',
  WAITING_FOR_MODIFICATION: 'waiting_for_modification',
  NOT_APPLICABLE: 'not_applicable',
};

export const HOUSING_BENEFITS_TYPES = {
  CAF: 'caf',
  GLI: 'gli',
  INVOICE: 'invoice',
};

export const HOUSING_BENEFITS_STATUS_LIST = {
  SCHEDULED: 'scheduled',
  CANCELLED: 'canceled',
  WAITING_VALIDATION: 'waiting_validation',
  PENDING: 'pending',
  USED: 'used',
  VALIDATION_ERROR: 'validation_error',
};

// INVENTORY
export const INVENTORY_STATUS_LIST = {
  DRAFT: 'draft',
  PENDING: 'pending',
  DONE: 'done',
};

export const INVENTORY_DEFAULT_EQUIPMENTS = {
  doors: 'Portes, menuiserie',
  windows: 'Fenêtres (vitres & volets)',
  ceiling: 'Plafond',
  floor: 'Sol',
  plinths: 'Plinthes',
  walls: 'Murs',
  heating: 'Chauffage / tuyauterie',
  electricity: 'Prises & interrupteurs',
  lights: 'Eclairage',
  storing: 'Rangement / placard',
};

export const INVENTORY_ROOMS = [
  {
    label: 'Cuisine',
    type: 'kitchen',
    equipments: {
      sink: 'Evier',
      tap: 'Robinet',
      hotplate: 'Plaque de cuisson',
      oven: 'Four',
      cooker_hood: 'Hotte aspirante',
      fridge: 'Réfrigérateur',
      freezer: 'Congélateur',
      dishwasher: 'Lave-vaisselle',
      cupboard: 'Plan de travail',
      ...INVENTORY_DEFAULT_EQUIPMENTS,
    },
  },
  {
    label: 'Salon',
    type: 'living_room',
    equipments: {
      fireplace: 'Cheminée',
      air_conditioning: 'Climatisation',
      ...INVENTORY_DEFAULT_EQUIPMENTS,
    },
  },
  {
    label: 'Salle de bain',
    type: 'bathroom',
    equipments: {
      basin: 'Lavabo',
      tap: 'Robinet',
      bath: 'Douche ou baignoire',
      mirror: 'Miroir',
      toilet: 'Toilettes',
      towel_rack: 'Porte-serviettes',
      ventilation: 'VMC (ventilation)',
      dryer: 'Sèche-linge',
      washer: 'Lave-linge',
      ...INVENTORY_DEFAULT_EQUIPMENTS,
    },
  },
  {
    label: 'Chambre',
    type: 'bedroom',
    equipments: {
      wardrobe_space: 'Espace de penderie',
      curtain_rods: 'Tringles à rideaux',
      ...INVENTORY_DEFAULT_EQUIPMENTS,
    },
  },
  {
    label: 'WC',
    type: 'toilet',
    equipments: {
      basin: 'Lavabo',
      tap: 'Robinet',
      toilet: 'Toilettes',
      toilet_paper_holder: 'Porte-papier toilette',
      ventilation: 'VMC (ventilation)',
      ...INVENTORY_DEFAULT_EQUIPMENTS,
    },
  },
  {
    label: 'Entrée',
    type: 'entry',
    equipments: {
      shoe_rack_area: 'Zone pour chaussures',
      ...INVENTORY_DEFAULT_EQUIPMENTS,
    },
  },
  {
    label: 'Bureau',
    type: 'office',
    equipments: {
      internet_outlet: 'Prise internet',
      cable_management: 'Gestion des câbles',
      ...INVENTORY_DEFAULT_EQUIPMENTS,
    },
  },
  {
    label: 'Autre pièce',
    type: 'other',
    equipments: {
      ...INVENTORY_DEFAULT_EQUIPMENTS,
    },
  },
];

export const INVENTORY_FURNITURE_TYPES = [
  {
    label: 'Canapé',
    value: 'sofa',
  },
  {
    label: 'Fauteuil',
    value: 'armchair',
  },
  {
    label: 'Table basse',
    value: 'coffee_table',
  },
  {
    label: 'Chaise',
    value: 'chair',
  },
  {
    label: 'Table à manger',
    value: 'dining_table',
  },
  {
    label: 'Bureau',
    value: 'desk',
  },
  {
    label: 'Bibliothèque',
    value: 'bookshelf',
  },
  {
    label: 'Commode',
    value: 'dresser',
  },
  {
    label: 'Amoire',
    value: 'closet',
  },
  {
    label: 'Table de chevet',
    value: 'nightstand',
  },
  {
    label: 'Meuble TV',
    value: 'tv_stand',
  },
  {
    label: 'Télévision',
    value: 'tv',
  },
  {
    label: 'Lampe',
    value: 'lamp',
  },
  {
    label: 'Buffet',
    value: 'sideboard',
  },
  {
    label: 'Lit',
    value: 'bed',
  },
  {
    label: 'Porte-manteau',
    value: 'coat_rack',
  },
  {
    label: 'Autre meuble',
    value: 'other',
  },
];
// ---

// EXPORT
export const EXPORT_TYPES = {
  GENERAL: 'general',
  GLI: 'gli',
};
