import {
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import {
  utils,
} from 'ui-library-unlocker';

// Components
import DocsRequirements from '../../../molecules/DocsRequirements/DocsRequirements';
import DocStep from '../../../molecules/DocStep/DocStep';
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal';
import AddDocumentModal from '../../AddDocumentModal/AddDocumentModal';

// Services
import { deleteCompanyDocument } from '../../../../services/company';

// Utils
import { hideModal, showModal } from '../../../../utils/modal';

import styles from './CompanyDocumentsList.module.scss';

const ADD_DOCUMENT_MODAL_ID = 'add-document-modal';
const DELETE_CONFIRM_MODAL_ID = 'company-document-delete-confirm';

function CompanyDocumentsList({
  docSteps,
  documentsData,
  refetchDocuments,
  companyUID,
}) {
  const { t } = useTranslation();

  const [fileType, setFileType] = useState(null);
  const [docSelected, setDocSelected] = useState(null);

  const documentDeleteMutation = useMutation({
    mutationFn: deleteCompanyDocument,
    onSuccess: () => {
      refetchDocuments();
      hideModal(DELETE_CONFIRM_MODAL_ID);
      utils.toast.success(t('global.documents.delete.success'));
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 400: {
            utils.toast.error(t('global.form.errors.generic'));
            break;
          }
          default: {
            utils.toast.error(t('global.form.errors.global'));
            break;
          }
        }
      }
    },
  });

  const handleDelete = useCallback((uid) => {
    setDocSelected(uid);
    showModal(DELETE_CONFIRM_MODAL_ID);
  }, []);

  const cancelDelete = useCallback(() => {
    setDocSelected(null);
    hideModal(DELETE_CONFIRM_MODAL_ID);
  }, []);

  const handleCloseUploadModal = useCallback(() => {
    setFileType(null);
  }, [setFileType]);

  return (
    <>
      <div className={styles.companyDocumentsList}>
        <DocsRequirements />

        {docSteps.map((item) => (
          <DocStep
            key={Array.isArray(item.docType) ? item.docType.join('-') : item.docType}
            documentListData={documentsData || []}
            addModalId={ADD_DOCUMENT_MODAL_ID}
            setFileType={setFileType}
            handleDelete={handleDelete}
            {...item}
          />
        ))}
      </div>

      {/* Add document modal */}
      <AddDocumentModal
        modalId={ADD_DOCUMENT_MODAL_ID}
        entityType="company"
        entityUID={companyUID}
        refetchDocumentList={refetchDocuments}
        fileType={fileType}
        onClose={handleCloseUploadModal}
      />

      {/* Confirm delete modal */}
      <ConfirmationModal
        id={DELETE_CONFIRM_MODAL_ID}
        onSubmit={() => documentDeleteMutation.mutate(docSelected)}
        onCancel={cancelDelete}
        loading={documentDeleteMutation.isLoading}
      />
    </>
  );
}

CompanyDocumentsList.propTypes = {
  docSteps: PropTypes.arrayOf(PropTypes.shape({
    docType: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
  })).isRequired,
  documentsData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  refetchDocuments: PropTypes.func.isRequired,
  companyUID: PropTypes.string.isRequired,
};

export default CompanyDocumentsList;
