import {
  cloneElement,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

// Components
import {
  utils,
  TextInput,
  SelectInput,
  RadioGroup,
} from 'ui-library-unlocker';
import AddressInput from '../../../components/molecules/AddressInput/AddressInput';

// Services
import { updateOnboardingProgress } from '../../../services/onboarding';

// Hooks
import { useOnboardingContext } from '../../../store/onboardingContext';

// Utils
import { displayError, errorFocusSubmit, isFieldValid } from '../../../utils/forms/form';
import {
  onboardingCompany1InitialValues,
  onboardingCompany1Schema,
} from '../../../utils/forms/onboardingSchema';
import { legalCategories } from '../../../utils/forms/companyDetailsSchema';

import styles from './OnboardingTunnelCompany1.module.scss';

function OnboardingTunnelCompany1({
  children: footer = null,
  handleStep,
}) {
  const { t } = useTranslation();
  const {
    contextOnboarding: {
      inputtedData,
      error,
      companyData,
    },
    dispatchOnboarding,
  } = useOnboardingContext();

  const initialValues = useMemo(() => {
    if (error) {
      return onboardingCompany1InitialValues;
    }
    if (inputtedData.company) {
      return inputtedData.company;
    }
    return onboardingCompany1InitialValues;
  }, [inputtedData.company, error]);

  const formik = useFormik({
    initialValues,
    validationSchema: onboardingCompany1Schema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (JSON.stringify(values) === JSON.stringify(initialValues)) return handleStep(1);
      dispatchOnboarding({
        type: 'SET_INPUTTED_DATA',
        payload: {
          ...inputtedData,
          company: {
            ...values,
            isRealEstateAgency: !!values.isRealEstateAgency,
          },
        },
      });
      return progressMutation.mutate({
        ...values,
        isRealEstateAgency: !!values.isRealEstateAgency,
      });
    },
  });

  const progressMutation = useMutation({
    mutationFn: (data) => updateOnboardingProgress('step3', data),
    onSuccess: () => handleStep(1),
    onError: (err) => {
      if (err?.response?.status === 400) {
        return utils.toast.error(t('global.form.errors.generic'));
      }
      return utils.toast.error(t('global.form.errors.global'));
    },
  });

  const handleSubmit = useCallback(() => {
    formik.handleSubmit();
  }, [formik]);

  const legalCategoryOptions = useMemo(() => legalCategories.map((category) => ({
    value: category.value,
    label: category.label,
  })), [t]);

  const companyTypeOptions = useMemo(() => [
    {
      id: 'normal',
      label: t('company.crud.form.companyType.normal'),
      value: 'normal',
    },
    {
      id: 'realEstate',
      label: t('company.crud.form.companyType.realEstate'),
      value: 'realEstate',
    },
  ], [t]);

  const handleCompanyTypeChange = useCallback((value) => {
    formik.setFieldValue('isRealEstateAgency', value === 'realEstate');
  }, [formik]);

  return (
    <>
      <div className={styles.company1}>
        <h2 className="m-b-10">
          {t('onboarding.steps.company1.titleOwner')}
          &nbsp;
          {inputtedData.company?.legalName || ''}
        </h2>
        <form className={styles.form} onSubmit={errorFocusSubmit(handleSubmit)}>
          {companyData?.data?.uid ? (
            <p className="m-t-25">
              {t(`company.crud.form.companyType.${companyData.data.isRealEstateAgency ? 'realEstate' : 'normal'}`)}
            </p>
          ) : (
            <RadioGroup
              name="companyType"
              className="m-t-25"
              options={companyTypeOptions}
              value={formik.values.isRealEstateAgency ? 'realEstate' : 'normal'}
              onChange={handleCompanyTypeChange}
            />
          )}
          <TextInput
            type="text"
            id="legalName"
            className="m-t-25"
            name="legalName"
            label={t('company.crud.form.legalName')}
            error={displayError(t, formik, 'legalName')}
            valid={isFieldValid(formik, 'legalName', null, initialValues?.legalName)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.legalName}
          />
          <TextInput
            type="text"
            id="brandName"
            name="brandName"
            className="m-t-25"
            label={t('company.crud.form.brandName')}
            error={displayError(t, formik, 'brandName')}
            valid={isFieldValid(formik, 'brandName', null, initialValues?.brandName)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.brandName}
          />
          <AddressInput
            id="address"
            name="address"
            className={!formik.values.address ? 'm-t-25' : null}
            label={t('company.crud.form.address')}
            error={displayError(t, formik, 'address')}
            valid={isFieldValid(formik, 'address', null, initialValues?.address)}
            onAddressSelect={(value) => formik.setFieldValue('address', value)}
            value={formik.values.address || null}
          />
          <SelectInput
            id="legalCategory"
            name="legalCategory"
            className={utils.cn(['m-t-25', styles.legalCategoryInput])}
            label={t('company.crud.form.legalForm')}
            options={legalCategoryOptions}
            error={displayError(t, formik, 'legalCategory')}
            valid={isFieldValid(formik, 'legalCategory', null, initialValues?.legalCategory)}
            onChange={(value) => {
              formik.setFieldValue('legalCategory', value.value);
            }}
            onBlur={formik.handleBlur}
            value={legalCategoryOptions.find((legalCategory) => legalCategory.value === formik.values.legalCategory)}
          />
        </form>
      </div>
      {footer && cloneElement(footer, {
        onSubmit: handleSubmit,
        isLoading: progressMutation.isLoading,
      })}
    </>
  );
}

OnboardingTunnelCompany1.propTypes = {
  children: PropTypes.node,
  handleStep: PropTypes.func.isRequired,
};

export default OnboardingTunnelCompany1;
