/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Table,
  Picto,
  Button,
} from 'ui-library-unlocker';
import { centsToEuro } from 'ui-library-unlocker/src/utils/money';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';

// Components
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
} from 'recharts';

// Utils
import { showModal } from '../../../utils/modal';
import { getDateValue } from '../../../utils/dates';

// Hooks
import useRoles from '../../../hooks/useRoles';

// Styles
import styles from './LeaseRevisionList.module.scss';

function LeaseRevisionList({
  revisionList = [],
  isFetching = false,
  lease = null,
  isFetched = false,
  hasBeenRevoked = false,
  LEASE_REVISION_MODAL_ID,
}) {
  const { t } = useTranslation();
  const { isUserLessor } = useRoles();

  const sortedRevisions = useMemo(() => {
    if (!revisionList) return [];
    return [...revisionList].sort((a, b) => getDateValue(b.applicationDate) - getDateValue(a.applicationDate));
  }, [revisionList]);

  const data = useMemo(() => {
    if (!revisionList || !lease) return [];
    return [
      ...revisionList,
      {
        applicationDate: lease.startDate,
        newRent: lease.rent,
        newCharges: lease.charges,
        chargesIncluded: lease.rent + lease.charges,
      },
    ].sort((a, b) => getDateValue(b.applicationDate) - getDateValue(a.applicationDate));
  }, [revisionList, lease]);

  const applicationDateValue = useCallback((d) => {
    if (!d) return '-';
    const value = getDateValue(d);
    if (value) return format(value, 'dd MMM yyyy', { locale: fr });
    return '-';
  }, []);

  const columns = useMemo(() => ([
    {
      header: t('lease.revisions.table.columns.applicationDate'),
      accessorKey: 'applicationDate',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { applicationDate } } }) => (
        <div className={styles.tableName}>
          <span>{applicationDateValue(applicationDate)}</span>
        </div>
      ),
    },
    {
      header: t('lease.revisions.table.columns.newRent'),
      accessorKey: 'newRent',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { newRent } } }) => (
        <div className={styles.tableName}>
          <span>
            {centsToEuro(newRent)}
            &nbsp;€
          </span>
        </div>
      ),
    },
    {
      header: t('lease.revisions.table.columns.newCharges'),
      accessorKey: 'newCharges',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { newCharges } } }) => (
        <div className={styles.tableName}>
          <span>
            {centsToEuro(newCharges)}
            &nbsp;€
          </span>
        </div>
      ),
    },
    {
      header: t('lease.revisions.table.columns.isAfterWorks'),
      accessorKey: 'isAfterWorks',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { isAfterWorks } } }) => (
        <div>
          <Picto
            icon={isAfterWorks ? 'tick' : 'close'}
            width={isAfterWorks ? 20 : 18}
            color={isAfterWorks ? 'var(--color-secondary-400)' : 'var(--color-primary-400)'}
          />
        </div>
      ),
    },
  ]), [t]);

  const years = useMemo(() => [
    ...new Set(data.map((item) => getDateValue(item.applicationDate).getFullYear())),
  ], [data]);

  const chartData = useMemo(() => (
    years
      .map((year) => {
        const revisionsOfYear = data.filter((item) => getDateValue(item.applicationDate).getFullYear() === year);

        if (revisionsOfYear.length === 0) {
          return {
            year,
            newRent: null,
            newCharges: null,
            chargesIncluded: null,
          };
        }

        if (revisionsOfYear.length === 1) {
          return {
            year,
            newRent: parseFloat(centsToEuro(revisionsOfYear[0].newRent)),
            newCharges: parseFloat(centsToEuro(revisionsOfYear[0].newCharges)),
            chargesIncluded: parseFloat(centsToEuro(revisionsOfYear[0].newRent + revisionsOfYear[0].newCharges)),
          };
        }

        const latestRevisionOfYear = revisionsOfYear.reduce((acc, curr) => (
          getDateValue(curr.applicationDate) > getDateValue(acc.applicationDate) ? curr : acc
        ), revisionsOfYear[0]);

        return {
          year,
          newRent: parseFloat(centsToEuro(latestRevisionOfYear.newRent)),
          newCharges: parseFloat(centsToEuro(latestRevisionOfYear.newCharges)),
          chargesIncluded: parseFloat(centsToEuro(latestRevisionOfYear.newRent + latestRevisionOfYear.newCharges)),
        };
      })
      .sort((a, b) => a.year - b.year)
  ), [data]);

  const formatLegend = useCallback((value) => <span>{t(`lease.revisions.table.columns.${value}`)}</span>, [t]);

  const formatTooltip = useCallback((value, name) => [`${value}€`, t(`lease.revisions.table.columns.${name}`)], [t]);

  const handleRevision = useCallback(() => {
    showModal(LEASE_REVISION_MODAL_ID);
  }, []);

  const showRevision = useMemo(() => !hasBeenRevoked && isUserLessor && lease?.status && lease?.status !== 'waiting_document', [hasBeenRevoked, isUserLessor, lease]);

  return (
    <div className="m-t-50">
      <div className={styles.content}>
        {isFetched && sortedRevisions?.length === 0 ? (
          <div className={styles.noData}>
            <span>{`${t('lease.revisions.table.noData')} ${showRevision ? t('lease.revisions.table.wishToRevise') : ''}`}</span>
            {showRevision && (
              <Button
                label={t('lease.revision')}
                variant="primary"
                size="medium"
                icon="edit"
                className="m-t-20"
                onClick={handleRevision}
              />
            )}
          </div>
        ) : (
          <Table
            fullWidth
            columns={columns}
            data={data}
            isLoading={isFetching}
          />
        )}

        {data?.length > 0 && (
          <ResponsiveContainer width="100%" height={300} className="m-t-50">
            <LineChart
              data={chartData}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="year" />
              <YAxis unit="€" />
              <Tooltip formatter={formatTooltip} />
              <Legend
                formatter={formatLegend}
                verticalAlign="top"
                height={40}
              />
              <Line
                dataKey="newRent"
                stroke="var(--color-accent-yellow)"
                activeDot={{ r: 8 }}
                type="linear"
                connectNulls
              />
              <Line
                dataKey="newCharges"
                stroke="var(--color-tertiary-600)"
                activeDot={{ r: 8 }}
                type="linear"
                connectNulls
              />
              <Line
                dataKey="chargesIncluded"
                stroke="var(--color-secondary)"
                activeDot={{ r: 8 }}
                type="linear"
                connectNulls
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
}

LeaseRevisionList.propTypes = {
  revisionList: PropTypes.arrayOf(PropTypes.shape()),
  isFetching: PropTypes.bool,
  lease: PropTypes.shape({}),
  isFetched: PropTypes.bool,
  hasBeenRevoked: PropTypes.bool,
  LEASE_REVISION_MODAL_ID: PropTypes.string.isRequired,
};

export default LeaseRevisionList;
