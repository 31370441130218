/* eslint-disable no-unused-vars */
import DashboardView from '../views/Dashboard/Dashboard';
import ProfileView from '../views/Profile/Profile';
import CompanyListView from '../views/Company/CompanyList';
import CompanyEditView from '../views/Company/CompanyEdit';
import LoginInfo from '../views/LoginInfo/LoginInfo';
import PropertyListView from '../views/Property/PropertyList';
import PropertyEditView from '../views/Property/PropertyEdit';
import OwnerListView from '../views/Owners/OwnerList';
import TenantListView from '../views/Tenant/TenantList';
import TenantEditView from '../views/Tenant/TenantEdit';
import PaymentAccount from '../views/Admin/PaymentAccount/PaymentAccount';
import Login from '../views/Login/Login';
import Register from '../views/Register/Register';
import ForgotPassword from '../views/ForgotPassword/ForgotPassword';
import OwnerEdit from '../views/Owners/OwnerEdit';
import LeaseListView from '../views/Lease/LeaseList';
import LeaseAddView from '../views/Lease/LeaseAdd';
import LeaseEditView from '../views/Lease/LeaseEdit';
import MessagesView from '../views/Messages/Messages';
import SetPasswordView from '../views/SetPassword/SetPassword';
import InvitationAcceptView from '../views/InvitationAccept/InvitationAccept';
import ComingFeature from '../views/ComingFeature/ComingFeature';
import CashOutList from '../views/CashOut/CashOutList';
import CashOutDetails from '../views/CashOut/CashOutDetails';
import UsersListView from '../views/Admin/Users/UserList';
import UserEditView from '../views/Admin/Users/UserEdit';
import SearchView from '../views/Search/Search';
import SearchPublicView from '../views/Search/SearchPublicView/SearchPublicView';
import PaymentsView from '../views/Payments/Payments';
import NotificationsView from '../views/Notifications/Notifications';
import BankingIdentityView from '../views/BankingIdentity/BankingIdentityList';
import BankingIdentityEdit from '../views/BankingIdentity/BankingIdentityEdit';
import SupportView from '../views/Support/SupportView';
import PostAdView from '../views/PostAd/PostAdView';
import PostAdPublishView from '../views/PostAd/PostAdPublishView';
import PostAdDeleteView from '../views/PostAd/PostAdDeleteView';
import HousingBenefitsView from '../views/HousingBenefits/HousingBenefitsList';
import HousingBenefitsEditView from '../views/HousingBenefits/HousingBenefitsEdit';
import InternalTransferEdit from '../views/InternalTransfer/InternalTransferEdit';
import ToolboxView from '../views/Toolbox/Toolbox';
import InventoryView from '../views/Inventory/InventoryList';
import InventoryEditView from '../views/Inventory/InventoryEdit';
import InventoryGeneral from '../views/Inventory/InventoryGeneral/InventoryGeneral';
import InventoryMeters from '../views/Inventory/InventoryMeters/InventoryMeters';
import InventoryKeys from '../views/Inventory/InventoryKeys/InventoryKeys';
import InventoryRooms from '../views/Inventory/InventoryRooms/InventoryRooms';
import InventorySigners from '../views/Inventory/InventorySigners/InventorySigners';
import IbanStream from '../views/IbanStream/IbanStream';

// routes that are accessible, either the user is logged in or not
export const publicRoutes = [
  {
    label: 'invitation-accept',
    element: <InvitationAcceptView />,
    routeProps: {
      path: '/invitation-accept',
    },
  },
  {
    label: 'support',
    element: <SupportView />,
    routeProps: {
      path: '/support',
    },
  },
  {
    label: 'search-public',
    element: <SearchPublicView />,
    routeProps: {
      path: '/rechercher-des-biens',
    },
    nestedRoutes: [
      {
        label: 'searchMap-property-public',
        routeProps: {
          path: ':id',
        },
      },
    ],
  },
  {
    label: 'post-ad',
    element: <PostAdView />,
    routeProps: {
      path: '/post-ad',
    },
  },
  {
    label: 'post-ad-publish',
    element: <PostAdPublishView />,
    routeProps: {
      path: '/listing/:id/publish/:token',
    },
  },
  {
    label: 'post-ad-delete',
    element: <PostAdDeleteView />,
    routeProps: {
      path: '/listing/:id/delete/:token',
    },
  },
  {
    label: 'iban-stream',
    element: <IbanStream />,
    routeProps: {
      path: '/lease-rib',
    },
  },
];

// routes that are wrapped in the header + menu template
export const withHeaderMenuRoutes = [
  {
    label: 'dashboard',
    element: <DashboardView />,
    routeProps: {
      path: '/',
    },
  },
  {
    label: 'profile',
    element: <ProfileView />,
    routeProps: {
      path: '/profile',
    },
  },
  {
    label: 'company',
    element: <CompanyListView />,
    routeProps: {
      path: '/company',
    },
    metadata: {
      needProfileFilled: true,
      needProfileTitle: 'company.title',
      needProfileHeading: 'completeProfile.views.companies.heading',
    },
    hideForTenant: true,
  },
  {
    label: 'company-add',
    element: <CompanyEditView isEditing={false} />,
    routeProps: {
      path: '/company/add',
    },
    metadata: {
      needProfileFilled: true,
      needProfileTitle: 'company.crud.addTitle',
      needProfileHeading: 'completeProfile.views.companies.heading',
    },
    hideForTenant: true,
  },
  {
    label: 'company-edit',
    element: <CompanyEditView isEditing />,
    routeProps: {
      path: '/company/:id',
    },
    metadata: {
      needProfileFilled: true,
      needProfileTitle: 'company.crud.editTitle',
      needProfileHeading: 'completeProfile.views.companies.heading',
    },
    hideForTenant: true,
  },
  {
    label: 'login-info',
    element: <LoginInfo />,
    routeProps: {
      path: '/login-info',
    },
  },
  {
    label: 'banking-identities',
    element: <BankingIdentityView />,
    routeProps: {
      path: '/banking-identities',
    },
    metadata: {
      needProfileFilled: true,
      needProfileTitle: 'bankingIdentity.title',
      needProfileHeading: 'completeProfile.views.bankingIdentity.heading',
    },
    hideForLessor: true,
  },
  {
    label: 'banking-identities-add',
    element: <BankingIdentityEdit isEditing={false} />,
    routeProps: {
      path: '/banking-identities/add',
    },
    metadata: {
      needProfileFilled: true,
      needProfileTitle: 'bankingIdentity.title',
      needProfileHeading: 'completeProfile.views.bankingIdentity.heading',
    },
    hideForLessor: true,
  },
  {
    label: 'owners-list',
    element: <OwnerListView />,
    routeProps: {
      path: '/owner',
    },
    metadata: {
      needProfileFilled: true,
      needProfileTitle: 'owner.title',
      needProfileHeading: 'completeProfile.views.owners.heading',
    },
    hideForTenant: true,
    hideForLessor: true,
    showForManager: true,
  },
  {
    label: 'owners-add',
    element: <OwnerEdit isEditing={false} />,
    routeProps: {
      path: '/owner/add',
    },
    metadata: {
      needProfileFilled: true,
      needProfileTitle: 'owner.addOwner',
      needProfileHeading: 'completeProfile.views.owners.heading',
    },
    hideForTenant: true,
  },
  {
    label: 'owners-edit',
    element: <OwnerEdit isEditing />,
    routeProps: {
      path: '/owner/:id',
    },
    metadata: {
      needProfileFilled: true,
      needProfileTitle: 'owner.addOwner',
      needProfileHeading: 'completeProfile.views.owners.heading',
    },
    hideForTenant: true,
  },
  {
    label: 'property',
    element: <PropertyListView />,
    routeProps: {
      path: '/property',
    },
    metadata: {
      needProfileFilled: true,
      needProfileTitle: 'property.title',
      needProfileHeading: 'completeProfile.views.properties.heading',
    },
    hideForTenant: true,
  },
  {
    label: 'property-add',
    element: <PropertyEditView isEditing={false} />,
    routeProps: {
      path: '/property/add',
    },
    metadata: {
      needProfileFilled: true,
      needProfileTitle: 'property.crud.addTitle',
      needProfileHeading: 'completeProfile.views.properties.heading',
    },
    hideForTenant: true,
  },
  {
    label: 'property-edit',
    element: <PropertyEditView isEditing />,
    routeProps: {
      path: '/property/:id',
    },
    metadata: {
      needProfileFilled: true,
      needProfileTitle: 'property.crud.editTitle',
      needProfileHeading: 'completeProfile.views.properties.heading',
    },
    hideForTenant: true,
  },
  {
    label: 'tenant',
    element: <TenantListView />,
    routeProps: {
      path: '/tenant',
    },
    metadata: {
      needProfileFilled: true, // Level 1
      needProfileTitle: 'tenant.title',
      needProfileHeading: 'completeProfile.views.tenants.heading',
      // needProfileRequested: false, // Level 2
      // needProfileValidated: false, // Level 3
    },
    hideForTenant: true,
  },
  {
    label: 'tenant-add',
    element: <TenantEditView isEditing={false} />,
    routeProps: {
      path: '/tenant/add',
    },
    metadata: {
      needProfileFilled: true,
      needProfileTitle: 'tenant.crud.addTitle',
      needProfileHeading: 'completeProfile.views.tenants.heading',
    },
    hideForTenant: true,
  },
  {
    label: 'tenant-edit',
    element: <TenantEditView isEditing />,
    routeProps: {
      path: '/tenant/:id',
    },
    metadata: {
      needProfileFilled: true,
      needProfileTitle: 'tenant.crud.editTitle',
      needProfileHeading: 'completeProfile.views.tenants.heading',
    },
    hideForTenant: true,
  },
  {
    label: 'tenant-edit',
    element: <TenantEditView isEditing />,
    routeProps: {
      path: '/tenant/:id/banking-identities',
    },
    metadata: {
      needProfileFilled: true,
      needProfileTitle: 'tenant.crud.editTitle',
      needProfileHeading: 'completeProfile.views.tenants.heading',
    },
    hideForTenant: true,
  },
  {
    label: 'lease',
    element: <LeaseListView />,
    routeProps: {
      path: '/lease',
    },
    metadata: {
      needProfileFilled: true,
      needProfileTitle: 'lease.title',
      needProfileHeading: 'completeProfile.views.leases.heading',
    },
  },
  {
    label: 'lease-add',
    element: <LeaseAddView />,
    routeProps: {
      path: '/lease/add',
    },
    metadata: {
      needProfileFilled: true,
      needProfileTitle: 'lease.add.title',
      needProfileHeading: 'completeProfile.views.leases.heading',
    },
    hideForTenant: true,
  },
  {
    label: 'lease-edit',
    element: <LeaseEditView />,
    routeProps: {
      path: '/lease/:id',
    },
    metadata: {
      needProfileFilled: true,
      needProfileTitle: 'lease.crud.editTitle',
      needProfileHeading: 'completeProfile.views.leases.heading',
    },
  },
  {
    label: 'lease-edit',
    element: <LeaseEditView />,
    routeProps: {
      path: '/lease/:id/lease-housing-benefits',
    },
    metadata: {
      needProfileFilled: true,
      needProfileTitle: 'lease.crud.editTitle',
      needProfileHeading: 'completeProfile.views.leases.heading',
    },
  },
  {
    label: 'lease-edit',
    element: <LeaseEditView />,
    routeProps: {
      path: '/lease/:id/rent-receipt',
    },
    metadata: {
      needProfileFilled: true,
      needProfileTitle: 'lease.crud.editTitle',
      needProfileHeading: 'completeProfile.views.leases.heading',
    },
  },
  {
    label: 'housing-benefits-list',
    element: <HousingBenefitsView />,
    routeProps: {
      path: '/housing-benefits',
    },
    metadata: {
      needProfileFilled: true,
      needProfileTitle: 'housingBenefits.title',
      needProfileHeading: 'completeProfile.views.housingBenefits.heading',
    },
    hideForTenant: true,
    killswitch: 'rentDistribution',
  },
  {
    label: 'housing-benefits-add',
    element: <HousingBenefitsEditView isEditing={false} />,
    routeProps: {
      path: '/housing-benefits/add',
    },
    metadata: {
      needProfileFilled: true,
      needProfileTitle: 'housingBenefits.crud.addTitle',
      needProfileHeading: 'completeProfile.views.housingBenefits.heading',
    },
    hideForTenant: true,
    killswitch: 'rentDistribution',
  },
  {
    label: 'housing-benefits-edit',
    element: <HousingBenefitsEditView isEditing />,
    routeProps: {
      path: '/housing-benefits/:id',
    },
    metadata: {
      needProfileFilled: true,
      needProfileTitle: 'housingBenefits.crud.editTitle',
      needProfileHeading: 'completeProfile.views.housingBenefits.heading',
    },
    hideForTenant: true,
    killswitch: 'rentDistribution',
  },
  {
    label: 'internal-transfer-add',
    element: <InternalTransferEdit isEditing={false} />,
    routeProps: {
      path: '/internal-transfer',
    },
    metadata: {
      needProfileFilled: true,
      needProfileTitle: 'internalTransfer.crud.editTitle',
      needProfileHeading: 'completeProfile.views.internalTransfer.heading',
    },
    hideForTenant: true,
    killswitch: 'internalTransfer',
  },
  {
    label: 'payment',
    element: <PaymentsView />,
    routeProps: {
      path: '/payments',
    },
    metadata: {
      needProfileFilled: true,
      needProfileTitle: 'payment.title',
      needProfileHeading: 'completeProfile.views.payments.heading',
    },
    hideForLessor: true,
  },
  {
    label: 'payment-history',
    element: <PaymentsView />,
    routeProps: {
      path: '/payments/history',
    },
    metadata: {
      needProfileFilled: true,
      needProfileTitle: 'payment.title',
      needProfileHeading: 'completeProfile.views.payments.heading',
    },
    hideForLessor: true,
  },
  {
    label: 'messages',
    element: <MessagesView />,
    routeProps: {
      path: '/messages',
    },
    metadata: {
      needProfileFilled: true,
      needProfileTitle: 'messages.title',
      needProfileHeading: 'completeProfile.views.messages.heading',
    },
    nestedRoutes: [
      {
        label: 'message',
        routeProps: {
          path: ':uid',
        },
      },
    ],
  },
  {
    label: 'users-list',
    element: <UsersListView />,
    routeProps: {
      path: '/user',
    },
    hideForTenant: true,
    hideForLessor: true,
  },
  {
    label: 'user-edit',
    element: <UserEditView />,
    routeProps: {
      path: '/user/:id',
    },
    hideForTenant: true,
    hideForLessor: true,
  },
  {
    label: 'payment-account-list',
    element: <PaymentAccount />,
    routeProps: {
      path: '/payment-account',
    },
    hideForTenant: true,
    hideForLessor: true,
  },
  {
    label: 'search',
    element: <SearchView />,
    routeProps: {
      path: '/rechercher',
    },
    nestedRoutes: [
      {
        label: 'searchMap-property',
        routeProps: {
          path: ':id',
        },
      },
    ],
  },
  {
    label: 'cashout-list',
    element: <CashOutList />,
    routeProps: {
      path: '/cashout',
    },
    hideForTenant: true,
    killswitch: 'paymentpage',
  },
  {
    label: 'cashout-details',
    element: <CashOutDetails />,
    routeProps: {
      path: '/cashout/:id',
    },
    hideForTenant: true,
    killswitch: 'paymentpage',
  },
  {
    label: 'notifications',
    element: <NotificationsView />,
    routeProps: {
      path: '/notifications',
    },
  },
  {
    label: 'toolbox',
    element: <ToolboxView />,
    routeProps: {
      path: '/toolbox',
    },
    hideForTenant: true,
  },
  {
    label: 'inventory',
    element: <InventoryView />,
    routeProps: {
      path: '/inventory',
    },
    metadata: {
      needProfileFilled: true,
      needProfileTitle: 'inventory.crud.title',
      needProfileHeading: 'completeProfile.views.inventory.heading',
    },
    hideForTenant: true,
    killswitch: 'inventoryPage',
  },
  {
    label: 'inventory-add-edit',
    element: <InventoryEditView />,
    routeProps: {
      path: '/inventory/:id',
    },
    metadata: {
      needProfileFilled: true,
      needProfileTitle: 'inventory.crud.addTitle',
      needProfileHeading: 'completeProfile.views.inventory.heading',
    },
    killswitch: 'inventoryPage',
    nestedRoutes: [
      {
        label: 'inventory-general',
        element: <InventoryGeneral />,
        routeProps: {
          path: '',
          exact: true,
        },
      },
      {
        label: 'inventory-meters',
        element: <InventoryMeters />,
        routeProps: {
          path: 'meters',
        },
      },
      {
        label: 'inventory-keys',
        element: <InventoryKeys />,
        routeProps: {
          path: 'keys',
        },
      },
      {
        label: 'inventory-rooms',
        element: <InventoryRooms />,
        routeProps: {
          path: 'rooms',
        },
      },
      {
        label: 'inventory-signers',
        element: <InventorySigners />,
        routeProps: {
          path: 'signers',
        },
      },
    ],
  },
  // COMING SOON
  {
    label: 'bonds',
    element: <ComingFeature />,
    routeProps: {
      path: '/bonds',
    },
  },
  {
    label: 'workloads',
    element: <ComingFeature />,
    routeProps: {
      path: '/workloads',
    },
  },
  {
    label: 'insurance',
    element: <ComingFeature />,
    routeProps: {
      path: '/insurance',
    },
  },
  {
    label: 'visits',
    element: <ComingFeature />,
    routeProps: {
      path: '/visits',
    },
  },
  {
    label: 'candidates',
    element: <ComingFeature />,
    routeProps: {
      path: '/candidates',
    },
  },
  {
    label: 'residencies',
    element: <ComingFeature />,
    routeProps: {
      path: '/residencies',
    },
  },
];

// login routes
export const loginRoutes = [
  {
    label: 'register',
    element: <Register />,
    routeProps: {
      path: '/register',
    },
  },
  {
    label: 'login',
    element: <Login />,
    routeProps: {
      path: '/login',
    },
  },
  {
    label: 'forgot-password',
    element: <ForgotPassword />,
    routeProps: {
      path: '/forgot-password',
    },
  },
  {
    label: 'set-password',
    element: <SetPasswordView />,
    routeProps: {
      path: '/set-password',
    },
  },
];
