import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

// Components
import Picto from '../../atoms/Picto/Picto';

// Utils
import cn from '../../../utils/cn';

// Styles
import styles from './FileDrop.module.scss';

const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '5px',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: 'var(--color-primary-200)',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: 'var(--color-primary-600)',
  outline: 'none',
  transition: 'all .24s ease-in-out',
  cursor: 'pointer',
};

const focusedStyle = {
  borderColor: 'var(--color-secondary-400)',
};

const dragStyle = {
  backgroundColor: 'var(--color-secondary-100)',
};

const rejectStyle = {
  borderColor: 'var(--color-accent-red)',
};

const FileDrop = ({
  className = '',
  label,
  info = '',
  onDrop,
  accept = 'image/jpg,image/png,image/jpeg',
}) => {
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragReject,
    isDragActive,
  } = useDropzone({
    accept: { [accept]: [] },
    onDrop,
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragActive ? dragStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
  }), [
    isFocused,
    isDragActive,
    isDragReject,
  ]);

  return (
    <div
      className={cn([styles.container, className])}
      {...getRootProps({ style })}
    >
      <input {...getInputProps()} />
      <Picto
        icon="import"
        width={50}
      />
      <p className={styles.label}>
        {label}
      </p>
      <small>{info}</small>
    </div>
  );
};

FileDrop.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  info: PropTypes.string,
  onDrop: PropTypes.func.isRequired,
  accept: PropTypes.string,
};

export default FileDrop;
