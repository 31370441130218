import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import { Button } from 'ui-library-unlocker';

import { useTranslation } from 'react-i18next';

import Modal from '../../molecules/Modal/Modal';

import styles from './ConfirmationModal.module.scss';

function ConfirmationModal({
  id = 'confirmation-modal',
  onSubmit,
  onCancel,
  title = '',
  loading = false,
  description = '',
  ...props
}) {
  const { t } = useTranslation();
  return createPortal(
    <Modal
      id={id}
      onClose={onCancel}
      title={title || t('global.confirmationModal.title')}
      {...props}
    >
      {description && (
        <p className="m-b-40">
          {description}
        </p>
      )}
      <div className={styles.buttonContainer}>
        <Button
          onClick={onSubmit}
          size="large"
          label={t('global.confirmationModal.confirm')}
          loading={loading}
        />
        <Button
          onClick={onCancel}
          size="large"
          variant="secondary"
          label={t('global.confirmationModal.cancel')}
        />
      </div>
    </Modal>,
    document.querySelector('body'),
  );
}

ConfirmationModal.propTypes = {
  id: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string,
  loading: PropTypes.bool,
  description: PropTypes.string,
};

export default ConfirmationModal;
