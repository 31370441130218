import {
  useEffect, useMemo, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import TagManager from 'react-gtm-module';

// Components
import {
  ProgressBar,
  Picto,
  Button,
  utils,
  UnlockerLoader,
} from 'ui-library-unlocker';
import BackButton from '../../components/atoms/BackButton/BackButton';
import OnboardingTunnelEnd from './OnboardingTunnelEnd/OnboardingTunnelEnd';
import OnboardingTunnelProfile1 from './OnboardingTunnelProfile1/OnboardingTunnelProfile1';
import OnboardingTunnelProfile2 from './OnboardingTunnelProfile2/OnboardingTunnelProfile2';
import OnboardingTunnelProfile3 from './OnboardingTunnelProfile3/OnboardingTunnelProfile3';
import OnboardingTunnelProfileDocs from './OnboardingTunnelProfileDocs/OnboardingTunnelProfileDocs';
import OnboardingTunnelCompany1 from './OnboardingTunnelCompany1/OnboardingTunnelCompany1';
import OnboardingTunnelCompany2 from './OnboardingTunnelCompany2/OnboardingTunnelCompany2';
import OnboardingTunnelCompany3 from './OnboardingTunnelCompany3/OnboardingTunnelCompany3';
import OnboardingTunnelCompany4 from './OnboardingTunnelCompany4/OnboardingTunnelCompany4';
import OnboardingTunnelCompany5 from './OnboardingTunnelCompany5/OnboardingTunnelCompany5';

// Services
import { completeOnboardingFunnel } from '../../services/onboarding';

// Hooks
import { useAppContext } from '../../store/context';
import { useOnboardingContext } from '../../store/onboardingContext';

import styles from './OnboardingTunnel.module.scss';

function TunnelFooter({
  onSubmit = () => {},
  handleSkipOnboarding = () => {},
  isDisabled = false,
  isLoading = false,
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.footer}>
      <Button
        variant="primary"
        size="large"
        onClick={onSubmit}
        disabled={isDisabled}
        loading={isLoading}
      >
        {t('onboarding.next')}
      </Button>
      <button
        type="button"
        className={styles.skip}
        onClick={handleSkipOnboarding}
      >
        {t('onboarding.skip')}
      </button>
    </div>
  );
}

TunnelFooter.propTypes = {
  onSubmit: PropTypes.func,
  handleSkipOnboarding: PropTypes.func,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

function OnboardingTunnel() {
  const { t } = useTranslation();
  const { dispatch } = useAppContext();
  const {
    contextOnboarding: { step },
    dispatchOnboarding,
    loading,
    onboardingRole: { isTenant, isOwner, isOwnerWithCompany },
  } = useOnboardingContext();

  useEffect(() => {
    document.title = t('onboarding.browserTitle');
  }, [t]);

  const ignoreOnboardingMutation = useMutation({
    mutationFn: completeOnboardingFunnel,
    onSuccess: () => localStorage.setItem('hasCompletedOnboardingFunnel', true),
  });

  const handleStep = useCallback((i) => {
    dispatchOnboarding({
      type: 'SET_STEP',
      payload: (step + i) || step,
    });
  }, [step, dispatchOnboarding]);

  const handleSkipOnboarding = useCallback(() => {
    if (isTenant) ignoreOnboardingMutation.mutate();
    dispatch({
      type: 'SET_SKIP_ONBOARDING',
      payload: true,
    });
    TagManager.dataLayer({
      dataLayer: {
        event: 'onboarding_skipped',
        onboardingStep: step,
      },
    });
  }, [ignoreOnboardingMutation, dispatch, step]);

  const handleNextStep = useCallback(() => {
    handleStep(1);
  }, [handleStep]);

  const handlePrevStep = useCallback(() => {
    handleStep(-1);
  }, [handleStep]);

  const endOnboardingMutation = useMutation({
    mutationFn: completeOnboardingFunnel,
    onSuccess: () => {
      localStorage.setItem('hasCompletedOnboardingFunnel', true);
      TagManager.dataLayer({
        dataLayer: {
          event: 'onboarding_completed',
        },
      });
      return handleNextStep();
    },
    onError: () => utils.toast.error(t('global.form.errors.global')),
  });

  const stepData = useMemo(() => {
    if (isTenant) {
      if (step === 1) {
        return {
          progress: 0,
          component: OnboardingTunnelProfile1,
        };
      }
      if (step === 2) {
        return {
          progress: 100,
          component: OnboardingTunnelEnd,
        };
      }
    }
    if (isOwner) {
      if (step === 1) {
        return {
          progress: 0,
          component: OnboardingTunnelProfile1,
        };
      }
      if (step === 2) {
        return {
          progress: 25,
          component: OnboardingTunnelProfile2,
        };
      }
      if (step === 3) {
        return {
          progress: 50,
          component: OnboardingTunnelProfile3,
        };
      }
      if (step === 4) {
        return {
          progress: 75,
          component: OnboardingTunnelProfileDocs,
        };
      }
      if (step === 5) {
        return {
          progress: 100,
          component: OnboardingTunnelEnd,
        };
      }
    }
    if (isOwnerWithCompany) {
      if (step === 1) {
        return {
          progress: 0,
          component: OnboardingTunnelProfile1,
        };
      }
      if (step === 2) {
        return {
          progress: 20,
          component: OnboardingTunnelProfile2,
        };
      }
      if (step === 3) {
        return {
          progress: 30,
          component: OnboardingTunnelProfile3,
        };
      }
      if (step === 4) {
        return {
          progress: 40,
          component: OnboardingTunnelProfileDocs,
        };
      }
      if (step === 5) {
        return {
          progress: 50,
          component: OnboardingTunnelCompany1,
        };
      }
      if (step === 6) {
        return {
          progress: 60,
          component: OnboardingTunnelCompany2,
        };
      }
      if (step === 7) {
        return {
          progress: 70,
          component: OnboardingTunnelCompany3,
        };
      }
      if (step === 8) {
        return {
          progress: 80,
          component: OnboardingTunnelCompany4,
        };
      }
      if (step === 9) {
        return {
          progress: 90,
          component: OnboardingTunnelCompany5,
        };
      }
      if (step === 10) {
        return {
          progress: 100,
          component: OnboardingTunnelEnd,
        };
      }
    }
    return {
      progress: 0,
    };
  }, [step, isTenant, isOwner, isOwnerWithCompany]);

  if (loading) return <UnlockerLoader size={300} />;

  return (
    <div className={styles.onboarding}>
      <div className={styles.heading}>
        <h1>
          {t('onboarding.title')}
        </h1>
        <ProgressBar value={stepData.progress} size="medium" />
      </div>
      <Picto width={166} icon="trace-5208" color="var(--color-tertiary)" />
      <BackButton
        label={t('onboarding.goBack')}
        onClick={handlePrevStep}
        className={step === 1 || stepData?.progress === 100 ? styles.hiddenBackBtn : null}
      />
      <div className={styles.content}>
        {stepData.component ? (
          <stepData.component
            handleStep={handleStep}
            endOnboardingMutation={endOnboardingMutation}
          >
            <TunnelFooter
              handleNextStep={handleNextStep}
              handleSkipOnboarding={handleSkipOnboarding}
            />
          </stepData.component>
        ) : (
          <>
            <div />
            <TunnelFooter
              handleNextStep={handleNextStep}
              handleSkipOnboarding={handleSkipOnboarding}
              isDisabled
            />
          </>
        )}
      </div>
    </div>
  );
}

export default OnboardingTunnel;
