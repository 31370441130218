/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import Picto from '../Picto/Picto';
import ProgressBar from '../ProgressBar/ProgressBar';

import cn from '../../../utils/cn';

import styles from './CheckedItem.module.scss';

// onClose display a close icon if specified
const CheckedItem = ({
  label,
  checked = false,
  className = null,
  onClick = () => {},
  details = false,
  progression = 0,
  total = 100,
  current = false,
  steps = [],
  progressBarSize = 'medium',
  windowWidth = 1920,
}) => {
  const MOBILE = windowWidth <= 1024;

  const getStepClassname = () => {
    if (MOBILE) return styles.hasSteps;
    if (steps && Array.from(steps).length > 0) return styles.hasSteps;
    return null;
  };

  return (
    <div className={cn([
      className,
      styles.checkedItem,
    ])}
    >
      <div
        className={cn([
          styles.item,
          current ? styles.current : null,
          getStepClassname(),
        ])}
      >
        <div
          className={cn([
            styles.content,
            details ? styles.details : null,
          ])}
        >

          <div className={styles.icon}>
            <Picto
              color="var(--color-secondary)"
              width={14}
              icon={checked ? 'tick-circle-bold' : 'tick-circle'}
            />
          </div>

          <div
            className={cn([
              styles.label,
              !checked ? styles.todo : null,
            ])}
            onClick={() => !checked && onClick()}
          >
            {label}
          </div>

        </div>

        {details && (
          <ProgressBar
            value={progression}
            total={total}
            size={progressBarSize}
          />
        )}
      </div>
    </div>
  );
};

CheckedItem.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  details: PropTypes.bool,
  progression: PropTypes.number,
  total: PropTypes.number,
  current: PropTypes.bool,
  steps: PropTypes.arrayOf(PropTypes.shape()),
  progressBarSize: PropTypes.oneOf(['small', 'medium']),
  windowWidth: PropTypes.number,
};

export default CheckedItem;
