import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Tree, TreeNode } from 'react-organizational-chart';

import { utils } from 'ui-library-unlocker';

import styles from './OrgChart.module.scss';

function OrgChart({
  data,
  className = '',
  lineColor = 'var(--color-primary-300)',
  lineHeight = '20px',
}) {
  const renderTree = useCallback((leaf) => (
    <TreeNode
      key={leaf.id}
      label={React.cloneElement(leaf.label, {
        className: utils.cn([styles.leaf, leaf.label?.props?.className]),
      })}
    >
      {leaf.children?.map((child) => renderTree(child))}
    </TreeNode>
  ), []);

  const treeData = useMemo(() => (data ? (
    <Tree
      label={React.cloneElement(data.label, {
        className: utils.cn([styles.leaf, data.label?.props?.className]),
      })}
      lineColor={lineColor}
      lineHeight={lineHeight}
    >
      {data.children?.map((leaf) => renderTree(leaf))}
    </Tree>
  ) : null), [data]);

  return (
    <div
      className={utils.cn([
        styles.orgChart,
        className,
      ])}
    >
      {treeData}
    </div>
  );
}

OrgChart.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.node.isRequired,
    children: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.node.isRequired,
      children: PropTypes.arrayOf(PropTypes.shape()),
    })),
  }).isRequired,
  className: PropTypes.string,
  lineColor: PropTypes.string,
  lineHeight: PropTypes.string,
};

export default OrgChart;
