/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import {
  utils,
  Picto,
  Button,
  Tag,
  MessageInput,
} from 'ui-library-unlocker';

// Hooks
import { useAppContext } from '../../../store/context';

// Components
import FileInput from '../FileInput/FileInput';

import styles from './MessageSender.module.scss';

function MessageSender({
  value = '',
  onChange,
  onAttachmentsChange,
  attachments = [],
  onMessageSend,
  loading = false,
}) {
  const { t } = useTranslation();
  const { context: { user } } = useAppContext();

  const handleFileSelection = (e) => {
    const f = e?.target?.files[0];

    if (f?.size > 10000000) {
      utils.toast.error(t('global.form.errors.file.tooLarge'));
      return;
    }
    if (f) {
      const setFileFields = (image = null) => {
        const dim = !image
          ? {}
          : {
            width: image.naturalWidth,
            height: image.naturalHeight,
          };

        onAttachmentsChange([...attachments, {
          file: f,
          src: image ? image.src : null,
          id: uuidv4(),
          data: {
            customName: f?.name,
            filename: f?.name,
            type: 'userDocuments',
            userUid: user?.username,
            metadata: {
              documentSize: f?.size,
              extension: f?.type?.split('/')[1],
              ...dim,
            },
          },
        }]);

        if (image) image.removeEventListener('load', setFileFields);
      };

      if (f?.type === 'application/pdf') {
        // PDF
        const reader = new FileReader();
        reader.onload = () => setFileFields(null);
        reader.readAsText(f);
      } else {
        // IMAGE
        const image = new Image();
        image.src = URL.createObjectURL(f);
        image.addEventListener('load', () => setFileFields(image));
      }
    }
  };

  return (
    <div className={styles.messageFeedNewMessage}>
      <FileInput
        id="attachment-file"
        name="attachment-file"
        label={t('messages.newMessageForm.addFilesSubmit')}
        accept="image/jpg,image/png,image/jpeg,application/pdf"
        value={null}
        onChange={handleFileSelection}
        actionElement={(
          <Button
            className={styles.leftIcon}
            icon="plus"
            variant="primary"
          />
        )}
      />
      <div className={utils.cn([styles.messageFeedInput])}>
        <div className={styles.messageSenderAttachments}>
          {attachments?.map((attachment) => (
            <Tag
              key={attachment.id}
              icon="document"
              variant="secondary"
              label={attachment.file.name}
              showCloseIcon
              onClose={() => onAttachmentsChange(attachments.filter((a) => a.id !== attachment.id))}
            />
          ))}
        </div>
        <MessageInput
          label={t('messages.writeMessage')}
          onChange={onChange}
          onEnterKeyDown={onMessageSend}
          value={value}
        />

        {loading
          && (
            <Picto
              className={styles.rightIcon}
              icon="loading"
              width={24}
              height={24}
              color="var(--color-secondary)"
            />
          )}
      </div>
      <Button
        disabled={!value}
        className={styles.rightIcon}
        icon="send"
        onClick={onMessageSend}
        variant="primary"
      />
    </div>
  );
}

MessageSender.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onAttachmentsChange: PropTypes.func.isRequired,
  attachments: PropTypes.arrayOf(PropTypes.string),
  onMessageSend: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default MessageSender;
