import PropTypes from 'prop-types';

// Components
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
} from 'recharts';

function LineGraph({
  className = '',
  width = '100%',
  height = 300,
  children = null,
  formatLegend = null,
  formatTooltip = null,
  lines = [],
  data = [],
  xAxisDataKey = 'year',
  legend = false,
  margin = {
    top: 0,
    right: 0,
    left: 0,
    bottom: 5,
  },
  tooltip = false,
  formatXAxis = null,
  formatYAxis = null,
}) {
  return (
    <ResponsiveContainer width={width} height={height} className={className}>
      <LineChart
        data={data}
        margin={margin}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xAxisDataKey} tickFormatter={formatXAxis} />
        <YAxis unit="€" tickFormatter={formatYAxis} />
        {tooltip && (
          <Tooltip formatter={formatTooltip} />
        )}
        {legend && (
          <Legend
            formatter={formatLegend}
            verticalAlign="top"
            height={40}
          />
        )}
        {lines.map?.((line) => (
          <Line
            key={line.dataKey}
            dataKey={line.dataKey}
            stroke={line.color}
            activeDot={{ r: 8 }}
            type={line.type}
          />
        ))}
        {children}
      </LineChart>
    </ResponsiveContainer>
  );
}

LineGraph.propTypes = {
  className: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.number,
  children: PropTypes.node,
  formatLegend: PropTypes.func,
  formatTooltip: PropTypes.func,
  lines: PropTypes.arrayOf(PropTypes.shape({
    dataKey: PropTypes.string,
    color: PropTypes.string,
  })),
  data: PropTypes.arrayOf(PropTypes.shape()),
  xAxisDataKey: PropTypes.string,
  legend: PropTypes.bool,
  margin: PropTypes.shape({
    top: PropTypes.number,
    right: PropTypes.number,
    left: PropTypes.number,
    bottom: PropTypes.number,
  }),
  tooltip: PropTypes.bool,
  formatXAxis: PropTypes.func,
  formatYAxis: PropTypes.func,
};

export default LineGraph;
