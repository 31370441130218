import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

// Components
import { Button, utils } from 'ui-library-unlocker';
import ConfirmationModal from '../../../components/organisms/ConfirmationModal/ConfirmationModal';

// Services
import { revertPayment } from '../../../services/payment';

// Utils
import { showModal, hideModal } from '../../../utils/modal';

// Constants
import { PAYMENT_AND_ACCOUNT_STATUS_LIST } from '../../../utils/constants';

const REVERT_PAYMENT_MODAL_ID = 'revert-payment-modal';

function RevertPayment({
  refetch = () => {},
  transfers = [],
}) {
  const { t } = useTranslation();

  const revertMutation = useMutation({
    mutationFn: () => Promise.all(
      transfers
        .filter((transfer) => transfer.status === PAYMENT_AND_ACCOUNT_STATUS_LIST.READY_TO_WIRE)
        .map((transfer) => revertPayment(transfer.uid)),
    ),
    onSuccess: () => {
      refetch();
      hideModal(REVERT_PAYMENT_MODAL_ID);
      utils.toast.success(t('cashout.details.revertPayment.success'));
    },
    onError: () => {
      utils.toast.error(t('global.form.errors.global'));
    },
  });

  const cancelRevert = useCallback(() => hideModal(REVERT_PAYMENT_MODAL_ID), []);

  return (
    <>
      <Button
        variant="danger"
        size="medium"
        label={t('cashout.details.revertPayment.btnLabel')}
        onClick={() => showModal(REVERT_PAYMENT_MODAL_ID)}
      />
      <ConfirmationModal
        id={REVERT_PAYMENT_MODAL_ID}
        onSubmit={revertMutation.mutate}
        onCancel={cancelRevert}
        title={t('cashout.details.revertPayment.modalTitle')}
        description={t('cashout.details.revertPayment.modalDescription')}
        size="small"
        loading={revertMutation.isLoading}
      />
    </>
  );
}

RevertPayment.propTypes = {
  refetch: PropTypes.func.isRequired,
  transfers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default RevertPayment;
