import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { debounce } from 'lodash';

// Components
import {
  SelectInput,
} from 'ui-library-unlocker';

// Services
import { searchOmniPredictions } from '../../../services/search';
import { getTenantLeases } from '../../../services/lease';

// Constant
import { SEARCH_SCOPE_LIST } from '../../../utils/constants';

import styles from './HousingBenefitsImportValidation.module.scss';

function SelectTenant({
  id,
  name,
  onChange,
  formikValues = {},
  error = '',
  disabled = false,
  handleChangeLeaseOptions,
}) {
  const { t } = useTranslation();
  const [fetchResults, setFetchResults] = useState();

  const fetchPredictions = useMutation({
    mutationFn: (data) => searchOmniPredictions({
      query: data,
      scope: [SEARCH_SCOPE_LIST.TENANTS],
    }),
    onSuccess: ({ data }) => setFetchResults(data?.[SEARCH_SCOPE_LIST.TENANTS]),
  });

  const filterOption = useCallback(() => true, []); // doesnt filter before api call

  const getLoadingMessage = useCallback(() => t('global.loading'), [t]);

  const getListPlaceholder = useCallback(() => t('housingBenefits.crud.import.searchTenantPlaceholder'), [t]);

  const handleInputChange = useCallback(debounce((val, { action }) => {
    if (!val || val.length < 3) {
      setFetchResults(null);
    } else if (action === 'input-change') {
      fetchPredictions.mutate(val);
    }
  }, 500), [fetchPredictions]);

  const handleChange = useCallback(async (option) => {
    onChange(option);
    const response = await getTenantLeases(option.value, {});
    handleChangeLeaseOptions(response?.data?.collection);
  }, [onChange, handleChangeLeaseOptions]);

  const options = useMemo(() => {
    if (!fetchResults) return [];
    return fetchResults.map((tenant) => ({
      label: `${tenant.firstName} ${tenant.lastName} - ${
        tenant.tenantNumber || t('housingBenefits.crud.import.noTenantNumber')
      }`,
      value: tenant.uid,
    }));
  }, [fetchResults]);

  return (
    <SelectInput
      id={id}
      name={name}
      options={options || []}
      value={formikValues.tenant}
      onInputChange={handleInputChange}
      onChange={(option) => handleChange(option)}
      error={!!error}
      aria-invalid={error != null}
      className={styles.input}
      isSearchable
      disabled={disabled}
      filterOption={filterOption}
      isLoading={fetchPredictions.isLoading}
      loadingMessage={getLoadingMessage}
      noOptionsMessage={getListPlaceholder}
      label={t('housingBenefits.crud.import.searchTenant')}
    />
  );
}

SelectTenant.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  formikValues: PropTypes.shape({
    tenant: PropTypes.shape({}),
  }),
  error: PropTypes.string,
  disabled: PropTypes.bool,
  handleChangeLeaseOptions: PropTypes.func.isRequired,
};

export default SelectTenant;
