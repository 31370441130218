import { useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';

// Components
import {
  utils,
  Picto,
  WithHeader,
  Button,
} from 'ui-library-unlocker';
import Footer from '../../components/templates/Footer/Footer';
import ConfirmationModal from '../../components/organisms/ConfirmationModal/ConfirmationModal';

// Services
import { deletePostAd } from '../../services/property';

// Utils
import { hideModal, showModal } from '../../utils/modal';

import styles from './PostAd.module.scss';

const CONFIRM_DELETE_MODAL_ID = 'confirm-delete-modal';

function PostAdDeleteView() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const navigate = useNavigate();

  const [success, setSuccess] = useState(false);

  const {
    id: propertyUID,
    token,
  } = useParams();

  useEffect(() => {
    document.title = t('postAd.delete.browserTitle');
  }, [t]);

  const deleteAdMutation = useMutation({
    mutationFn: deletePostAd,
    onSuccess: () => {
      hideModal(CONFIRM_DELETE_MODAL_ID);
      setSuccess(true);
    },
    onError: () => {
      utils.toast.error(t('global.form.errors.global'));
    },
  });

  const renderNav = useCallback(() => (
    <nav className={styles.nav}>
      <ul className={styles.list}>
        <li className={styles.item}>
          <Button
            onClick={() => navigate('/post-ad')}
            label={t('postAd.headerBtn')}
            size={isMobile ? 'small' : 'medium'}
            variant="default-outline"
          />
        </li>
        <li className={styles.item}>
          <Button
            onClick={() => navigate('/login')}
            label={t('login.title')}
            size={isMobile ? 'small' : 'medium'}
          />
        </li>
      </ul>
    </nav>
  ), [isMobile, navigate, t]);

  const handleDelete = useCallback(() => {
    deleteAdMutation.mutate({
      propertyUID,
      token,
    });
  }, [deleteAdMutation, propertyUID, token]);

  const handleRedirect = useCallback(() => {
    navigate('/post-ad');
  }, [navigate]);

  const handleSignUp = useCallback(() => {
    navigate('/register');
  }, [navigate]);

  return (
    <WithHeader
      logoLink="/register"
      navContent={renderNav()}
      isContained={false}
      className={utils.cn([
        styles.postAdViewHeader,
        'p-l-20 p-r-20',
      ])}
      displayBurger={false}
    >
      <div className={styles.postAdView}>
        <div>
          <div className={styles.heading}>
            <h1 className={styles.title}>
              <span>{t('postAd.delete.title')}</span>
            </h1>
            <Picto width={166} icon="trace-5208" color="var(--color-tertiary)" />
          </div>
          <div className={styles.content}>
            {success ? (
              <>
                <h2>{t('postAd.delete.success.title')}</h2>
                <p className="m-t-20">
                  <Trans i18nKey="postAd.delete.success.description" />
                </p>
                <Button
                  type="button"
                  className="m-t-30"
                  size="large"
                  label={t('postAd.delete.success.new')}
                  onClick={handleRedirect}
                />
              </>
            ) : (
              <>
                <h2>{t('postAd.delete.subtitle')}</h2>
                <p className="m-t-20">
                  <Trans i18nKey="postAd.delete.description" />
                </p>
                <Button
                  type="button"
                  className="m-t-30"
                  size="large"
                  label={t('postAd.delete.signUp')}
                  onClick={handleSignUp}
                />
                <h2 className="m-t-50">{t('postAd.delete.subtitle2')}</h2>
                <p className="m-t-20">{t('postAd.delete.description2')}</p>
                <Button
                  type="button"
                  className="m-t-30"
                  size="medium"
                  label={t('postAd.delete.submit')}
                  onClick={() => showModal(CONFIRM_DELETE_MODAL_ID)}
                  variant="danger"
                  icon="trash"
                  iconSide="left"
                />
              </>
            )}
          </div>
        </div>
      </div>
      <Footer className={styles.footer} />

      {/* Confirm delete modal */}
      <ConfirmationModal
        id={CONFIRM_DELETE_MODAL_ID}
        onSubmit={handleDelete}
        loading={deleteAdMutation.isLoading}
        onClose={() => hideModal(CONFIRM_DELETE_MODAL_ID)}
      />
    </WithHeader>
  );
}

export default PostAdDeleteView;
