/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import Picto from '../Picto/Picto';

import cn from '../../../utils/cn';

import styles from './TextAreaInput.module.scss';

const TextAreaInput = ({
  id,
  name,
  label = null,
  value = '',
  full = false,
  disabled = false,
  className = null,
  inputClassName = null,
  error = null,
  valid = null,
  info = null,
  loading = false,
  isRequired = false,
  resize = 'vertical',
  ...props
}) => (
  <div className={cn([className])}>
    <div className={cn([
      styles.inputWrapper,
      full ? styles.full : null,
      disabled ? styles.disabled : null,
      loading ? styles.loading : null,
    ])}
    >
      <textarea
        id={id}
        name={name}
        placeholder={label}
        value={value === null ? '' : value}
        disabled={disabled}
        style={{ resize }}
        className={cn([
          value ? styles.hasValue : null,
          error ? styles.error : null,
          valid ? styles.valid : null,
          inputClassName,
        ])}
        {...props}
      />
      {label ? (
        <label htmlFor={id}>
          {label}
          {isRequired ? <span>*</span> : ''}
        </label>
      ) : null}

      {loading
          && (
            <Picto
              className={styles.rightIcon}
              icon="loading"
              width={24}
              height={24}
              color="var(--color-secondary)"
            />
          )}
    </div>
    {error && typeof error === 'string' ? (
      <div className={cn([styles.context, styles.fieldError])}>
        {error}
      </div>
    ) : info && (
      <div className={cn([styles.context, styles.fieldInfo])}>
        {info}
      </div>
    )}
  </div>
);

TextAreaInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
  full: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  valid: PropTypes.bool,
  info: PropTypes.string,
  loading: PropTypes.bool,
  isRequired: PropTypes.bool,
  resize: PropTypes.oneOf(['none', 'both', 'horizontal', 'vertical']),
};

export default TextAreaInput;
