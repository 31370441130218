/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import MicroModal from 'micromodal';
import { utils } from 'ui-library-unlocker';

import styles from './Modal.module.scss';

const ESCAPE_KEY = 'Escape';

const Modal = forwardRef((
  {
    children,
    title = null,
    id,
    onClose,
    isOpen,
    size = 'regular',
    className = '',
    noWrap = false,
  },
  ref,
) => {
  useEffect(() => {
    try {
      if (isOpen === undefined) return () => {};
      if (isOpen) {
        MicroModal.show(id, {
          openClass: styles.isOpen,
          awaitOpenAnimation: true,
          awaitCloseAnimation: true,
        });
        document.onkeydown = (evt) => {
          evt = evt || window.event;
          if (evt.key === ESCAPE_KEY) {
            onClose(evt);
          }
        };
        return () => {
          document.onkeydown = null;
        };
      }
      MicroModal.close(id);
    } catch (error) {
      //
    }
    return () => {};
  }, [isOpen, id]);

  return (
    <div
      className={`${styles.micromodalSlide}`}
      id={id}
      aria-hidden="true"
      ref={ref}
    >
      <div className={styles.modalOverlay} tabIndex="-1" onClick={onClose}>
        <div
          onClick={(e) => e.stopPropagation()}
          className={`${className} ${styles.modalWrapper} ${styles[size]}`}
          role="dialog"
          aria-modal="true"
          aria-labelledby={`${id}-title}`}
        >
          <div className={styles.modalContainer}>
            <div className="d-flex justify-content-end">
              <button
                onClick={onClose}
                type="button"
                className={`${styles.modalClose} fs-18`}
                aria-label="Close modal"
              />
            </div>
            <div className={utils.cn([styles.modalContentWrap, noWrap ? styles.noWrap : null])}>
              <div className={styles.modalHeader}>
                {title ? (
                  <h2 className="t-h2-700" id={`${id}-title`}>
                    {title}
                  </h2>
                ) : null }
              </div>
              <main className={`${styles.modalContent} ${title === null ? styles.noTitle : ''}`} id={`${id}-content}`}>
                {children}
              </main>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  size: PropTypes.oneOf(['small', 'regular', 'large']),
  className: PropTypes.string,
  noWrap: PropTypes.bool,
};

export default Modal;
