import { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { utils, Picto } from 'ui-library-unlocker';

import styles from './MaskedField.module.scss';

function MaskedField({
  value = '',
  firstCharsVisibleLength = 2,
  lastCharsVisibleLength = 4,
  formatValue = (val) => val,
  className = '',
}) {
  const [visible, setVisible] = useState(false);

  const maskedValue = useMemo(() => {
    if (!value) return value;
    const val = visible
      ? value
      : value.replace(
        new RegExp(`(?<=^.{${firstCharsVisibleLength}})(.*)(?=.{${lastCharsVisibleLength}}$)`, 'g'),
        '•'.repeat(value.length - firstCharsVisibleLength - lastCharsVisibleLength),
      );
    return formatValue(val);
  }, [value, visible, firstCharsVisibleLength, lastCharsVisibleLength, formatValue]);

  const handleVisibility = useCallback((e) => {
    e.stopPropagation();
    setVisible((prev) => !prev);
  }, []);

  return (
    <div className={utils.cn([
      styles.maskedField,
      className,
      visible && styles.visible,
    ])}
    >
      {maskedValue ? (
        <>
          <span>
            {maskedValue}
          </span>
          <Picto
            icon={visible ? 'eye-slash' : 'eye'}
            width={20}
            onClick={handleVisibility}
            className={styles.eye}
          />
        </>
      ) : '-'}
    </div>
  );
}

MaskedField.propTypes = {
  value: PropTypes.string,
  firstCharsVisibleLength: PropTypes.number,
  lastCharsVisibleLength: PropTypes.number,
  className: PropTypes.string,
  formatValue: PropTypes.func,
};

export default MaskedField;
