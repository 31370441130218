import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'ui-library-unlocker';

// Hooks
import { useAppContext } from '../../../store/context';

import styles from './OnboardingTunnelEnd.module.scss';

function OnboardingTunnelEnd() {
  const { t } = useTranslation();
  const { dispatch } = useAppContext();

  const handleSubmit = useCallback(() => {
    dispatch({
      type: 'SET_HAS_COMPLETED_ONBOARDING_FUNNEL',
      payload: true,
    });
    localStorage.setItem('hasCompletedOnboardingFunnel', true);
  }, []);

  return (
    <div className={styles.basics}>
      <p className="m-t-10">
        {t('onboarding.steps.end.description')}
      </p>
      <Button
        variant="primary"
        size="large"
        onClick={handleSubmit}
        className="m-t-30"
      >
        {t('onboarding.steps.end.button')}
      </Button>
    </div>
  );
}

export default OnboardingTunnelEnd;
