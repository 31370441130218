/* eslint-disable consistent-return */
import {
  useEffect,
  useRef,
  useMemo,
  useState,
  useCallback,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Pusher from 'pusher-js';
import { useQuery, useMutation } from '@tanstack/react-query';
import {
  Picto,
  utils,
} from 'ui-library-unlocker';

// components
import Tooltip from '../../atoms/Tooltip/Tooltip';
import Notification from '../../molecules/Notification/Notification';
import HoverTag from '../../atoms/HoverTag/HoverTag';
import NotifSkeleton from './NotifSkeleton';

// hooks
import { useAppContext } from '../../../store/context';
import useInactivity from '../../../hooks/useInactivity';

// services
import {
  getNotifications,
  hitUntilNotification,
  getLastUnreadNotification,
} from '../../../services/notifications';

import styles from './NotificationCenter.module.scss';

function NotificationCenter({
  iconWidth = 24,
}) {
  const {
    context: {
      user,
      notifications,
      totalNumberOfNotifications,
      hasUnreadNotifications,
    }, dispatch,
  } = useAppContext();
  const { pathname } = useLocation();
  const pusherRef = useRef(null);
  const wrapperRef = useRef(null);
  const notifLinkRef = useRef(null);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [hideLoader, setHideLoader] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    itemsPerPage: 20, // not too low or won't be able to scroll and fetch more on large screens
  });

  const unreadQuery = useQuery({
    queryKey: ['unread-notifications'],
    queryFn: async () => {
      const response = await getLastUnreadNotification();

      dispatch({
        type: 'SET_HAS_UNREAD_NOTIFICATIONS',
        payload: response?.data?.totalNumberOfItems > 0,
      });

      return response;
    },
    cacheTime: 0,
  });

  useInactivity(
    () => {
      pusherRef.current?.disconnect();
      setTooltipOpen(false);
    }, // no activity
    () => {
      pusherRef.current?.connect();
      unreadQuery.refetch();
    }, // activity resumed
  );

  const notifQuery = useQuery({
    queryKey: ['notifications', tooltipOpen, pagination.page],
    queryFn: async () => {
      const response = await getNotifications(pagination);

      const fetchedNotifications = response?.data?.collection || [];
      const updatedNotifications = pagination.page === 1
        ? fetchedNotifications
        : [...notifications, ...fetchedNotifications];

      dispatch({
        type: 'SET_NOTIFICATIONS',
        payload: updatedNotifications,
      });

      setHideLoader(true);
      dispatch({
        type: 'SET_TOTAL_NUMBER_OF_NOTIFICATIONS',
        payload: response?.data?.totalNumberOfItems || 0,
      });

      return response;
    },
    cacheTime: 0,
    enabled: tooltipOpen,
  });

  const allReadMutation = useMutation({
    mutationFn: (timestamp) => hitUntilNotification(timestamp),
    onError: () => {
      utils.toast.error(t('global.form.errors.global'));
    },
  });

  useEffect(() => () => {
    dispatch({
      type: 'SET_TOTAL_NUMBER_OF_NOTIFICATIONS',
      payload: 0,
    });
    dispatch({
      type: 'SET_HAS_UNREAD_NOTIFICATIONS',
      payload: false,
    });
  }, []);

  useEffect(() => {
    if (!user) return;
    if (!process.env.REACT_APP_PUSHER_APP_KEY) return;

    Pusher.logToConsole = process.env.REACT_APP_ENV === 'local' || process.env.REACT_APP_ENV === 'staging';

    pusherRef.current = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
      cluster: 'eu',
      forceTLS: true,
    });

    const channel = pusherRef.current?.subscribe(user?.username);
    channel.bind('notification', (data) => {
      setPagination((prev) => {
        if (prev?.page === 1) notifQuery.refetch();
        return ({
          ...prev,
          page: 1,
        });
      });
      if (data.category === 'export') utils.toast.success(t('notifications.exportToast'));
      if (data.category === 'LeaseIban') utils.toast.success(t('notifications.LeaseIbanToast'));
    });

    const handleBlur = () => {
      pusherRef.current?.disconnect();
      setTooltipOpen(false);
    };

    const handleFocus = () => {
      pusherRef.current?.connect();
      unreadQuery.refetch();
    };

    window.addEventListener('blur', handleBlur);
    window.addEventListener('focus', handleFocus);

    return () => {
      window.removeEventListener('blur', handleBlur);
      window.removeEventListener('focus', handleFocus);
      pusherRef.current?.unsubscribe(user?.username);
      pusherRef.current?.disconnect();
    };
  }, [user]);

  useEffect(() => {
    if (!tooltipOpen) {
      setHideLoader(false);
      setPagination((prev) => ({
        ...prev,
        page: 1,
      }));
      dispatch({
        type: 'SET_TOTAL_NUMBER_OF_NOTIFICATIONS',
        payload: 0,
      });
      unreadQuery.refetch();
    }
  }, [tooltipOpen]);

  const handleMenu = useCallback(() => {
    if (pathname === '/notifications') return;
    setTooltipOpen((prev) => !prev);
  }, [pathname]);

  const onClickOutsideTooltip = useCallback((e) => {
    if (!wrapperRef.current?.contains(e.target)) return setTooltipOpen(false);
    return null;
  }, [wrapperRef]);

  const handleRedirect = useCallback(() => {
    setTooltipOpen(false);
  }, []);

  const handleMarkAsRead = useCallback((id, hit) => {
    const updatedNotifications = notifications.map((notif) => {
      if (notif.id === id) {
        return {
          ...notif,
          hit,
        };
      }
      return notif;
    });
    dispatch({
      type: 'SET_NOTIFICATIONS',
      payload: updatedNotifications,
    });
    dispatch({
      type: 'SET_HAS_UNREAD_NOTIFICATIONS',
      payload: false,
    });
  }, [notifications, dispatch]);

  const handleMarkAllAsRead = useCallback(() => {
    if (!notifications.length) return;
    const updatedNotifications = notifications.map((notif) => ({
      ...notif,
      hit: true,
    }));
    const newestNotif = notifications.reduce((acc, notif) => {
      const notifTimestamp = new Date(notif.createdAt);
      return notifTimestamp > new Date(acc.createdAt) ? notif : acc;
    });
    allReadMutation.mutate(new Date(newestNotif.createdAt)?.getTime?.()?.toString?.());
    dispatch({
      type: 'SET_NOTIFICATIONS',
      payload: updatedNotifications,
    });
    dispatch({
      type: 'SET_HAS_UNREAD_NOTIFICATIONS',
      payload: false,
    });
  }, [notifications, allReadMutation, dispatch]);

  const hasUnread = useMemo(() => (
    hasUnreadNotifications
    || notifications.some((notif) => !notif.hit)
  ), [notifications, hasUnreadNotifications]);

  const handleInfiniteScroll = useCallback((e) => {
    const { target } = e;
    // if target is scrolled completely, fetch more data
    if (
      notifications.length < totalNumberOfNotifications
      && target.scrollHeight - target.scrollTop === target.clientHeight
    ) {
      setPagination((prev) => ({
        ...prev,
        page: prev.page + 1,
      }));
    }
  }, [notifications, totalNumberOfNotifications]);

  const displayNotifications = useMemo(() => {
    if (!hideLoader && notifQuery.isFetching) {
      return (
        <NotifSkeleton />
      );
    }
    if (!notifications.length) {
      return (
        <div className={styles.noNotif}>
          {t('notifications.noNotifications')}
        </div>
      );
    }
    const sortedNotifications = [...notifications].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    return (
      <>
        {sortedNotifications.map((notif) => (
          <Notification
            key={notif.id}
            item={notif}
            onMarkAsRead={handleMarkAsRead}
          />
        ))}
        {totalNumberOfNotifications > notifications.length && (
          <NotifSkeleton />
        )}
      </>
    );
  }, [notifications, hideLoader, notifQuery, t, handleMarkAsRead]);

  return (
    <div
      id="notifMenuWrapper"
      className={styles.wrapper}
      ref={wrapperRef}
    >
      <Picto
        icon={tooltipOpen ? 'notification-bold' : 'notification'}
        width={iconWidth}
        className={styles.icon}
        color="var(--color-white)"
        role="button"
        tabIndex={0}
        onKeyDown={handleMenu}
        onClick={handleMenu}
      />
      {hasUnread && (
        <div className={styles.unread} />
      )}
      <Tooltip
        className={styles.tooltip}
        isOpen={tooltipOpen}
        anchorId="notifMenuWrapper"
        place="bottom-end"
        effect="solid"
        onClickOutside={onClickOutsideTooltip}
      >
        <div className={utils.cn([styles.tooltipHeader, 'm-b-5'])}>
          <h2 className={styles.notifTitle}>
            {t('notifications.title')}
          </h2>
          <div
            className={styles.notifLinkWrapper}
            ref={notifLinkRef}
          >
            <Link
              to="/notifications"
              role="button"
              tabIndex={0}
              onKeyDown={handleRedirect}
              onClick={handleRedirect}
            >
              <Picto
                icon="export-link"
                width={17}
                color="var(--color-secondary)"
                className={styles.notifLink}
              />
            </Link>
            <HoverTag
              text={t('notifications.seeAll')}
              position="left"
              parentRef={notifLinkRef}
            />
          </div>
        </div>
        <div className={utils.cn([styles.tooltip_subheader, 'm-b-10'])}>
          <h3 className={styles.notifSubtitle}>
            {t('notifications.subtitle')}
          </h3>
          {hasUnread && (
            <span
              className={styles.markAllAsRead}
              role="button"
              tabIndex={0}
              onKeyDown={handleMarkAllAsRead}
              onClick={handleMarkAllAsRead}
            >
              {t('notifications.markAllAsRead')}
            </span>
          )}
        </div>
        <div
          className={styles.tooltipWrapper}
          onScroll={handleInfiniteScroll}
        >
          {displayNotifications}
        </div>
      </Tooltip>
    </div>
  );
}

NotificationCenter.propTypes = {
  iconWidth: PropTypes.number,
};

export default NotificationCenter;
