/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import TextInput from '../TextInput/TextInput';

import styles from './DateInput.module.scss';

registerLocale('fr', fr);

const Input = forwardRef(({
  value, onClick, ...props
}, ref) => (
  <div role="button" ref={ref} onKeyDown={null} tabIndex={0} onClick={onClick}>
    <TextInput
      value={value}
      {...props}
    />
  </div>
));

const DateInput = ({
  id,
  name,
  value = null,
  label = null,
  full = false,
  disabled = false,
  className = null,
  error = null,
  valid = null,
  info = null,
  onChange = () => {},
  onBlur = () => {},
  isRequired = false,
  yearOnly = false,
  isClearable = false,
  minDate = null,
  maxDate = null,
  withPortal = false,
  portalHost = null,
  portalId = '',
  monthYearPicker = false,
  startDate = null,
  endDate = null,
  selectsRange = false,
}) => {
  const dateFormat = useMemo(() => {
    if (yearOnly) return 'yyyy';
    if (monthYearPicker) return 'MM/yyyy';
    return 'dd/MM/yyyy';
  }, [yearOnly, monthYearPicker]);

  return (
    <DatePicker
      selected={value}
      selectsRange={selectsRange}
      startDate={startDate}
      endDate={endDate}
      onChange={onChange}
      onBlur={onBlur}
      locale="fr"
      shouldCloseOnSelect
      disabled={disabled}
      dateFormat={dateFormat}
      showYearPicker={yearOnly}
      showMonthYearPicker={monthYearPicker}
      isClearable={isClearable}
      minDate={minDate}
      maxDate={maxDate}
      withPortal={withPortal}
      portalHost={portalHost}
      portalId={portalId}
      clearButtonClassName={styles.dateInputClearBtnClassName}
      customInput={(
        <Input
          id={id}
          name={name}
          label={label}
          error={error}
          valid={valid}
          info={info}
          full={full}
          disabled={disabled}
          className={className}
          isRequired={isRequired}
          icon={isClearable ? null : 'calendar'}
        />
      )}
    />
  );
};

DateInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  label: PropTypes.string,
  full: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  valid: PropTypes.bool,
  info: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  isRequired: PropTypes.bool,
  yearOnly: PropTypes.bool,
  isClearable: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  withPortal: PropTypes.bool,
  portalHost: PropTypes.node,
  portalId: PropTypes.string,
  monthYearPicker: PropTypes.bool,
  selectsRange: PropTypes.bool,
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};

export default DateInput;
