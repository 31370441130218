import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import LoginInfoForm from '../../components/organisms/forms/LoginInfoForm/LoginInfoForm';

// Hooks
import useDocumentTitle from '../../hooks/useDocumentTitle';

// Styles
import styles from './LoginInfo.module.scss';

function LoginInfo() {
  const { t } = useTranslation();

  useDocumentTitle(t('loginInfo.browserTitle'));

  return (
    <div>
      <div className={styles.heading}>
        <h1>{t('loginInfo.title')}</h1>
      </div>
      <div className={styles.content}>
        <LoginInfoForm />
      </div>
    </div>
  );
}

export default LoginInfo;
