/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React, {
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import { useTranslation } from 'react-i18next';
import {
  Picto, Tag, utils,
} from 'ui-library-unlocker';
import {
  Routes,
  Route,
  useNavigate,
  useParams,
} from 'react-router-dom';

// Hooks
import { useAppContext } from '../../../store/context';
import useResponsive from '../../../hooks/useResponsive';

// Components
import MessagesFeed from './MessagesFeed/MessagesFeed';

import styles from './MessagesInbox.module.scss';

function MessagesInbox({
  conversations = null,
  conversationsFetching,
}) {
  // eslint-disable-next-line no-unused-vars
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobile } = useResponsive();
  const { context: { uiBuilders } } = useAppContext();
  const params = useParams();

  const conversationList = useMemo(() => {
    if (!conversations) {
      return [];
    }
    return conversations.data.collection;
  }, [conversations]);

  const conversationSubjects = useMemo(() => {
    if (!uiBuilders?.['/messaging/ui']) return [];
    return uiBuilders['/messaging/ui'].conversationSubject;
  }, [uiBuilders]);

  const conversationStatuses = useMemo(() => {
    if (!uiBuilders?.['/messaging/ui']) return {};
    return uiBuilders['/messaging/ui'].conversationStatus;
  }, [uiBuilders]);

  const getStatusTheme = (status) => {
    switch (status) {
      case 'new': {
        return {
          variant: 'secondary',
          icon: 'timer',
        };
      }
      default:
        return {
          variant: 'primary',
          icon: 'tick-circle',
        };
    }
  };

  useEffect(() => {
    // there must always be an active conversation
    if (!params?.uid && conversationList.length > 0) {
      navigate(`/messages/${conversationList[0].uid}`);
    }
  }, [conversationList, params?.uid]);

  const renderDesktop = useCallback(() => (
    <div className={styles.wrapper}>
      <div className={utils.cn([styles.leftContentWrapper])}>
        {conversationsFetching && (
        <div className={styles.loadingOverlay}>
          <Picto width={30} icon="loading" />
        </div>
        )}
        <div className={styles.leftContent}>
          {(!!params?.uid && conversationSubjects) && conversationList.map((conversation) => {
            const { status, createdAt: { date: convDate } } = conversation;
            const date = format(new Date(convDate), 'dd MMM. yyyy', { locale: fr });
            const firstName = conversation?.recipient?.firstName || t('messages.admin.firstName');
            const lastName = conversation?.recipient?.lastName || t('messages.admin.lastName');

            const statusInfo = getStatusTheme(status);

            return (
              <div
                key={conversation.uid}
                onClick={() => navigate(`/messages/${conversation.uid}`)}
                className={utils.cn([
                  styles.conversation,
                  params?.uid === conversation.uid ? styles.isActive : null,
                ])}
              >
                <div className="m-b-5">
                  <Tag
                    key={status.label}
                    label={status === 'new' ? t('messages.status.new') : t('messages.status.replied')}
                    variant={statusInfo.variant}
                    icon={statusInfo.icon}
                    size="small"
                    className="m-r-5"
                  />
                </div>
                <div className={utils.cn([
                  'm-b-5 p-2-700',
                  styles.conversationSubject,
                  status === 'new' ? styles.new : null,
                ])}
                >
                  {conversationSubjects?.[conversation.subject]}
                </div>
                <p className="p-2-700">
                  {`${firstName} ${lastName}`}
                </p>
                {conversation.property ? (
                  <>
                    <p className="m-t-5 p-2-500">
                      {conversation.property?.name}
                    </p>
                    <p className="p-2-500">
                      {`
                    ${conversation.property.address.streetNumber || ''}
                    ${conversation.property.address.street},
                    ${conversation.property.address.zipCode}
                    ${conversation.property.address.city}
                  `}
                    </p>
                  </>
                ) : null}
                <p className={utils.cn(['m-t-5 p-3-500', styles.conversationDate])}>{date}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.rightContent}>
        <Routes>
          <Route path=":uid" element={<MessagesFeed />} />
        </Routes>
      </div>
    </div>
  ), [
    conversationsFetching,
    conversationList,
    conversationSubjects,
    params?.uid,
    navigate,
    conversationStatuses,
  ]);

  // const renderMobile = useCallback(() => (
  //   <div className={styles.wrapper}>
  //     <div className={styles.leftContent}>
  //       WIP MENU MOBILE
  //     </div>
  //     <div className={styles.rightContent}>
  //       WIP CONVERSATIONS MOBILE
  //     </div>
  //   </div>
  // ));

  // TODO: implement mobile view
  return isMobile ? renderDesktop() : renderDesktop();
}

MessagesInbox.propTypes = {
  conversations: PropTypes.shape(),
  conversationsFetching: PropTypes.bool.isRequired,
};

export default MessagesInbox;
