import { useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

// Components
import {
  TextInput,
  CheckboxGroup,
  ToggleGroup,
  Message,
  DateInput,
  utils,
} from 'ui-library-unlocker';
import ConsumptionScore from '../../components/atoms/ConsumptionScore/ConsumptionScore';

// Utils
import { displayError, isFieldValid, errorFocusSubmit } from '../../utils/forms/form';
import { sortObjectKeysByValueAsc } from '../../utils/sort';

// Hooks
import useResponsive from '../../hooks/useResponsive';
import useScroll from '../../hooks/useScroll';

import styles from './PostAd.module.scss';

function PropertyCharPostAd({
  formik,
  className = '',
  propertyBuilders,
}) {
  const { t } = useTranslation();
  const { isMobile, isLaptopXs } = useResponsive();
  const { scrollToElement } = useScroll();

  useEffect(() => {
    scrollToElement('postAdTitle', 'instant');
  }, []);

  const equipmentsOptions = useMemo(() => {
    const equipments = propertyBuilders?.propertyEquipments;
    if (!equipments) return [];
    return sortObjectKeysByValueAsc(equipments).map((equipmentKey) => ({
      id: equipmentKey,
      label: equipments[equipmentKey],
      value: equipmentKey,
    }));
  }, [propertyBuilders]);

  const heatingsOptions = useMemo(() => {
    const heatings = propertyBuilders?.propertyHeatings;
    if (!heatings) return [];
    return sortObjectKeysByValueAsc(heatings).map((equipmentKey) => ({
      id: equipmentKey,
      label: heatings[equipmentKey],
      value: equipmentKey,
    }));
  }, [propertyBuilders]);

  const equipmentItemsPerRow = useMemo(() => {
    if (isMobile) return 1;
    if (isLaptopXs) return 3;
    return 4;
  }, [isMobile, isLaptopXs]);

  const handleReferenceYearChange = useCallback((date) => {
    if (date) formik.setFieldValue('referenceYear', date.getFullYear());
    else formik.setFieldValue('referenceYear', date);
  }, [formik]);

  const isFormError = useMemo(() => !isEmpty(formik.errors), [formik.errors]);

  return (
    <form className={className} onSubmit={errorFocusSubmit(formik.handleSubmit)}>
      <h2 className="m-b-25 m-t-30">{t('property.tabs.characteristics.formSections.additionalInfo')}</h2>
      <CheckboxGroup
        name="equipments"
        className="m-t-25"
        options={equipmentsOptions}
        value={formik?.values?.equipments}
        onChange={(value) => formik.setFieldValue('equipments', value)}
        display="horizontal"
        itemsPerRow={equipmentItemsPerRow}
        error={displayError(t, formik, 'equipments')}
      />

      <h2 className="m-t-50 m-b-20">{t('property.tabs.characteristics.formSections.heatingType')}</h2>
      <ToggleGroup
        name="heatings"
        className="m-t-25"
        options={heatingsOptions}
        value={formik?.values?.heatings}
        onChange={(value) => formik.setFieldValue('heatings', value)}
        display="horizontal"
        itemsPerRow={equipmentItemsPerRow}
        error={displayError(t, formik, 'heatings')}
      />

      <h2 className="m-t-50 m-b-20">{t('property.tabs.characteristics.formSections.ges')}</h2>
      <TextInput
        min="0"
        type="number"
        id="gesScore"
        name="gesScore"
        className={utils.cn([styles.smallInputs, 'm-t-25'])}
        label={t('property.crud.form.gesScore')}
        error={displayError(t, formik, 'gesScore')}
        valid={isFieldValid(formik, 'gesScore')}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.gesScore}
      />
      <ConsumptionScore
        className="m-t-25"
        score={formik.values.gesScore}
        type="ges"
      />

      <h2 className="m-t-50 m-b-20">{t('property.tabs.characteristics.formSections.dpe')}</h2>
      <TextInput
        min="0"
        type="number"
        id="dpeScore"
        name="dpeScore"
        className={utils.cn([styles.smallInputs, 'm-t-25'])}
        label={t('property.crud.form.dpeScore')}
        error={displayError(t, formik, 'dpeScore')}
        valid={isFieldValid(formik, 'dpeScore')}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.dpeScore}
      />
      <ConsumptionScore
        className="m-t-25"
        score={formik.values.dpeScore}
        type="dpe"
      />

      <TextInput
        min="0"
        type="number"
        id="minConsumption"
        name="minConsumption"
        className={utils.cn([styles.smallInputs, 'm-t-40'])}
        label={t('property.crud.form.minConsumption')}
        error={displayError(t, formik, 'minConsumption')}
        valid={isFieldValid(formik, 'minConsumption')}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.minConsumption}
      />
      <TextInput
        min="0"
        type="number"
        id="maxConsumption"
        name="maxConsumption"
        className={utils.cn([styles.smallInputs, 'm-t-25'])}
        label={t('property.crud.form.maxConsumption')}
        error={displayError(t, formik, 'maxConsumption')}
        valid={isFieldValid(formik, 'maxConsumption')}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.maxConsumption}
      />
      <DateInput
        id="referenceYear"
        name="referenceYear"
        className={utils.cn([styles.smallInputs, 'm-t-25'])}
        label={t('property.crud.form.referenceYear')}
        error={displayError(t, formik, 'referenceYear')}
        valid={isFieldValid(formik, 'referenceYear')}
        onChange={handleReferenceYearChange}
        onBlur={formik.handleBlur}
        value={formik.values.referenceYear ? new Date(formik.values.referenceYear, 0, 1) : null}
        yearOnly
      />

      {isFormError
        ? <Message variant="error" className="m-t-30" content={t('global.form.errors.localGeneric')} />
        : null}
    </form>
  );
}

PropertyCharPostAd.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      equipments: PropTypes.arrayOf(PropTypes.string),
      heatings: PropTypes.arrayOf(PropTypes.string),
      gesScore: PropTypes.number,
      dpeScore: PropTypes.number,
      minConsumption: PropTypes.number,
      maxConsumption: PropTypes.number,
      referenceYear: PropTypes.number,
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    errors: PropTypes.shape({}),
  }).isRequired,
  className: PropTypes.string,
  propertyBuilders: PropTypes.shape({
    propertyEquipments: PropTypes.shape({}),
    propertyHeatings: PropTypes.shape({}),
  }).isRequired,
};

export default PropertyCharPostAd;
