import React from 'react';
import PropTypes from 'prop-types';

// Components
import FileDrop from '../../molecules/FileDrop/FileDrop';
import Camera from '../../molecules/Camera/Camera';
import Picto from '../../atoms/Picto/Picto';

// Utils
import cn from '../../../utils/cn';

// Styles
import styles from './PhotoDragOrTake.module.scss';

const PhotoDragOrTake = ({
  className = '',
  fileDrop = {
    label: 'Parcourir les fichiers',
    info: 'Glisser-déposer les fichiers ici ou cliquer',
    onDrop: () => {},
  },
  camera = {
    label: 'Prendre des photos',
    constraints: {},
    onClose: null,
    onSubmit: () => {},
    className: '',
  },
  loading = false,
}) => (
  <div className={cn([styles.container, className])}>
    <FileDrop
      className={styles.fileDrop}
      label={fileDrop?.label}
      info={fileDrop?.info}
      onDrop={fileDrop?.onDrop}
    />
    <Camera
      className={styles.camera}
      cameraClassName={camera?.className}
      label={camera?.label}
      constraints={camera?.constraints}
      onClose={camera?.onClose}
      onSubmit={camera?.onSubmit}
    />
    {loading && (
      <div className={styles.loading}>
        <Picto
          icon="loading"
          width={30}
          color="var(--color-primary-600)"
        />
      </div>
    )}
  </div>
);

PhotoDragOrTake.propTypes = {
  className: PropTypes.string,
  fileDrop: PropTypes.shape({
    label: PropTypes.string,
    info: PropTypes.string,
    onDrop: PropTypes.func,
  }),
  camera: PropTypes.shape({
    label: PropTypes.string,
    constraints: PropTypes.shape({}),
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    className: PropTypes.string,
  }),
  loading: PropTypes.bool,
};

export default PhotoDragOrTake;
